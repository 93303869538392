import React from 'react';
import {PurchaseOrder} from 'two-core';
import './PurchaseOrders.scss';

interface ReportItem {
  lineCount: number;
  element: JSX.Element;
}

export class POReportFooter {
  pageNumber: string;
  constructor() {
    this.pageNumber = '0/0';
  }
  setPageNumber(pageNumber: string) {
    this.pageNumber = pageNumber;
  }
  getFooter(selectedOrder: PurchaseOrder): JSX.Element {
    return (
      <React.Fragment>
        <table className="footer">
          <tr className="">
            <td className="label p-l-15 w-10">P.O.Number: </td>
            <td className="text w-20">{selectedOrder.id}</td>
            <td className="label w-30">Reference: </td>
            <td className="text w-40">{selectedOrder.reference}</td>
            <td className="label">Page</td>
            <td className="text">{this.pageNumber}</td>
          </tr>
        </table>
      </React.Fragment>
    );
  }
}

interface ReportItem {
  element: JSX.Element;
}

interface Page {
  items: ReportItem[];
  number: number;
}

class POReport {
  pages: Page[];
  header: JSX.Element | undefined;
  footer: POReportFooter | undefined;
  currentItemCount: number;
  currentPageCount: number;
  orientation: string;
  currentLineCount: number;
  purchaseOrder?: PurchaseOrder;
  selectedOrder?: PurchaseOrder;
  doubleRowCount: number;
  singleRowCount: number;

  constructor() {
    this.header = undefined;
    this.footer = undefined;
    this.pages = [];
    this.currentItemCount = 0;
    this.currentPageCount = 1;
    this.orientation = 'portrait';
    const page: Page = {
      items: [],
      number: 1,
    };
    this.pages[0] = page;
    this.currentLineCount = 0;
    this.selectedOrder = undefined;
    this.doubleRowCount = 0;
    this.singleRowCount = 0;
  }

  setOrientation(orientation: string) {
    this.orientation = orientation;
  }

  addHeader(header: JSX.Element) {
    this.header = header;
  }

  addFooter(size: number, footer: POReportFooter, purchaseOrder: PurchaseOrder) {
    this.currentLineCount += size;
    this.footer = footer;
    this.purchaseOrder = purchaseOrder;
    this.selectedOrder = purchaseOrder;
  }

  addItem(itemDetailLength: number, item: JSX.Element) {
    if (this.newPageRequired(itemDetailLength)) {
      this.currentPageCount++;
      this.currentLineCount = 0;
      this.currentItemCount = 0;
      this.doubleRowCount = 1;
      this.singleRowCount = 1;
      const page: Page = {
        items: [],
        number: this.currentPageCount,
      };
      this.pages.push(page);
    }

    this.pages[this.currentPageCount - 1].items.push({
      lineCount: itemDetailLength,
      element: item,
    });
    this.currentLineCount++;
    this.currentItemCount++;
  }

  newPageRequired(itemDetailLength: number): boolean {
    const doubleLineHeight = 16;
    const singleLineHeight = 8;
    let breakIndex = 0;

    if (itemDetailLength > 40) {
      this.doubleRowCount++;
    } else {
      this.singleRowCount++;
    }
    if (this.currentLineCount > 21 && this.doubleRowCount > 13) {
      breakIndex = 100;
    }
    if (this.currentLineCount > 22 && this.singleRowCount > 22) {
      breakIndex = 100;
    }

    const pageSize = 225;
    return doubleLineHeight * this.doubleRowCount + singleLineHeight * this.singleRowCount + breakIndex > pageSize;
  }

  getReport(): JSX.Element {
    return (
      <>
        {this.pages.map(page => {
          if (this.footer) {
            this.footer.setPageNumber(`${page.number}/${this.pages.length}`);
          }
          return (
            <table key="report-table" className={'p-p-3 po-print-page ' + this.orientation}>
              {this.header ? (
                <tr className="po-print-header">
                  <td className="p-d-flex p-flex-column">{this.header}</td>
                </tr>
              ) : (
                <></>
              )}
              <tr className={'po-print-body'}>
                <td className="p-d-flex p-flex-column">
                  {page.items.map(item => {
                    return item.element;
                  })}
                </td>
              </tr>

              {this.footer ? (
                <tr className={'po-print-footer p-mt-auto'}>
                  <td className="p-d-flex p-jc-end">
                    {this.selectedOrder && this.footer.getFooter(this.selectedOrder)}
                  </td>
                </tr>
              ) : (
                <></>
              )}
            </table>
          );
        })}
      </>
    );
  }
}

export default POReport;
