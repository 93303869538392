import React from 'react';
import {InputText} from 'primereact/inputtext';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {InputSwitch} from 'primereact/inputswitch';
import {supplyItemReorderUnits} from './Constants/constants';
import {InputNumber} from 'primereact/inputnumber';
import {toInputUppercase} from './Constants/Utils';
import {InventoryItem} from 'two-core';
import {Supplier, SupplyItem} from 'two-core';

interface SupplyItemProps {
  item: SupplyItem;
  invItem: InventoryItem;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams) => void;
  suppliers: Supplier[];
}

const SupplyItemForm = ({item, handleInputChange, suppliers}: SupplyItemProps) => {
  return (
    <>
      <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
        <label htmlFor="supplier_id" className="p-col-1">
          supplier
        </label>
        <div className="p-col-3 p-p-0">
          <Dropdown
            className="w-100"
            optionLabel="company_name"
            optionValue="id"
            value={item.supplier_id}
            options={suppliers}
            name="supplier_id"
            id="supplier_id"
            onChange={e => handleInputChange(e)}
          />
        </div>
        <label htmlFor="name" className="p-col-1">
          name
        </label>
        <div className="p-col-3 p-p-0">
          <InputText
            className="w-100"
            id="name"
            name="name"
            value={item.name}
            onChange={e => handleInputChange(e)}
            onInput={toInputUppercase}
          />
        </div>
        <label htmlFor="sku" className="p-col-1">
          sku
        </label>
        <div className="p-col-3 p-p-0">
          <InputText
            className="w-100"
            id="sku"
            name="sku"
            value={item.sku}
            onChange={e => handleInputChange(e)}
            onInput={toInputUppercase}
          />
        </div>
      </div>

      <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
        <label htmlFor="reorder_unit" className="p-col-1">
          order unit
        </label>
        <div className="p-col-3 p-p-0">
          <Dropdown
            className="w-100"
            optionLabel="label"
            optionValue="value"
            value={item.reorder_unit}
            options={supplyItemReorderUnits}
            name="reorder_unit"
            id="reorder_unit"
            onChange={e => handleInputChange(e)}
          />
        </div>
        <label htmlFor="unit_price" className="p-col-1">
          unit price
        </label>
        <div className="p-col-3 p-p-0">
          <InputNumber
            className="w-100"
            mode="currency"
            currency="AUD"
            locale="en-AU"
            id="unit_price"
            name="unit_price"
            value={item.unit_price}
            onChange={e => {
              item.unit_price = e.value as number;
              // if (!isNaN(+e.target.value)) {
              //     item.unit_price = +e.target.value
              // }
            }}
          />
        </div>
        <label htmlFor="default_option" className="p-col-1">
          default
        </label>
        <div className="p-col-3 p-p-0">
          <InputSwitch name="default_option" onChange={e => handleInputChange(e)} checked={item.default_option} />
        </div>
      </div>

      <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
        <label htmlFor="package_size_label" className="p-col-1">
          package
        </label>
        <div className="p-col-3 p-p-0">
          <InputText
            className="w-100"
            id="package_size_label"
            name="package_size_label"
            value={item.package_size_label}
            onChange={e => handleInputChange(e)}
          />
        </div>
        <label htmlFor="package_size" className="p-col-1">
          size in order units
        </label>
        <div className="p-col-3 p-p-0">
          <InputNumber
            className="w-100"
            id="package_size"
            name="package_size"
            value={item.package_size}
            minFractionDigits={2}
            onChange={e => {
              item.package_size = e.value as number;
            }}
          />
        </div>
        <label htmlFor="reorder_qty_in_uom" className="p-col-1">
          size in uom
        </label>
        <div className="p-col-3 p-p-0">
          <InputNumber
            className="w-100"
            id="reorder_qty_in_uom"
            name="reorder_qty_in_uom"
            value={item.reorder_qty_in_uom}
            minFractionDigits={2}
            onChange={e => {
              item.reorder_qty_in_uom = e.value as number;
            }}
          />
        </div>
      </div>
    </>
  );
};

export default SupplyItemForm;
