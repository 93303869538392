import React from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {
  AppContext,
  MessageService,
  TwoAction,
  TwoEntityComponent,
  TwoEntityPanel,
  TwoTimeline,
  TwoTimelineItem,
  ToastService,
} from 'two-app-ui';
import {
  faClipboardList,
  faCalendarAlt,
  faArrowCircleRight,
  faTruck,
  faListAlt,
  faPencil,
  faList,
  faPrint,
} from '@fortawesome/pro-regular-svg-icons';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  PurchaseOrder,
  PurchaseOrderItem,
  PurchaseOrderItemAggregate,
  PurchaseOrderStage,
  QueryParameter,
  TimeLineEvent,
} from 'two-core';
import PurchaseOrdersService from '../../services/PurchaseOrdersService';
import PurchaseOrderDetail from './PurchaseOrderDetail';
import PurchaseOrderItems from './PurchaseOrderItems';
import {Toast} from 'primereact/toast';
import {Subscription} from 'rxjs';
import {messages} from '../../config/messages';
import PurchaseOrderEditDialog from './PurchaseOrderEditDialog';
import PurchaseOrderRecordDeliveryDialog from './PurchaseOrderRecordDeliveryDialog';
import TleService from '../../services/TleService';
import OrdersService from '../../services/OrdersService';
import PurchaseOrderDate from '../PurchaseOrders/PurchaseOrderDate';
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import PurchaseOrderOrders from './PurchaseOrderOrders';
import PurchaseOrderListPrint from '../PurchaseOrders/PurchaseOrderListPrint';
import {ProgressSpinner} from 'primereact/progressspinner';

library.add(faClipboardList, faCalendarAlt, faArrowCircleRight, faTruck, faListAlt, faPencil, faList);

interface RouteProps {
  id: string;
}

interface State {
  purchaseOrder?: PurchaseOrder;
  loading: boolean;
  showEditDialog: boolean;
  showRecordDeliveryDialog: boolean;
  events: TimeLineEvent[];
  items: TwoTimelineItem[];
  showDateDialog: boolean;
  changeDatePurchaseOrder: PurchaseOrder | undefined;
  currentStage: PurchaseOrderStage;
  poItems: PurchaseOrderItem[];
}

class PurchaseOrderComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;
  toast: React.RefObject<Toast>;

  purchaseOrdersService: PurchaseOrdersService | null = null;
  tleService: TleService | null = null;
  ordersService: OrdersService | null = null;
  toastService: ToastService | null = null;

  subscription: Subscription = new Subscription();

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);

    this.state = {
      loading: false,
      showEditDialog: false,
      showRecordDeliveryDialog: false,
      events: [],
      items: [],
      showDateDialog: false,
      changeDatePurchaseOrder: undefined,
      currentStage: 'Draft',
      poItems: [],
    };

    this.showEditDialog = this.showEditDialog.bind(this);
    this.hideEditDialog = this.hideEditDialog.bind(this);
    this.showRecordDeliveryDialog = this.showRecordDeliveryDialog.bind(this);
    this.hideRecordDeliveryDialog = this.hideRecordDeliveryDialog.bind(this);
    this.showDateDialog = this.showDateDialog.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.toast = React.createRef();
  }

  componentDidMount() {
    this.purchaseOrdersService = this.context.purchaseOrdersService;
    this.tleService = this.context.tleService;
    this.ordersService = this.context.ordersService;
    this.toastService = this.context.toastService;

    this.loadData();
    this.subscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.purchaseOrderItemsUpdated) {
        const id = this.props.match.params.id;
        this.loadPurchaseOrderItems(id);
      }
    });
  }

  loadData() {
    this.setState({loading: true});
    const id = this.props.match.params.id;
    const loads = [];
    loads.push(this.loadPurchaseOrder(id));
    loads.push(this.loadPurchaseOrderItems(id));
    loads.push(this.loadEvents(id));
    Promise.all(loads)
      .catch(error => {
        console.error('Failed Loading Data:' + error);
        this.toastService?.showError(this.toast, 'Failed loading data, please refresh and try again.');
      })
      .finally(() => {
        this.setState({loading: false});
      });
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  async loadPurchaseOrder(id: string): Promise<void> {
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: id,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };
    return this.purchaseOrdersService?.getPurchaseOrders(params).then(data => {
      const purchaseOrder = (data.records as PurchaseOrder[])[0];
      this.setState({
        purchaseOrder: purchaseOrder,
      });
    });
  }

  async loadPurchaseOrderItems(id: string): Promise<void> {
    const aggregate: PurchaseOrderItemAggregate[] = ['inventory_item', 'supply_item'];
    const orderBys = [JSON.stringify({field: 'inventory_item.name'})];
    const params: QueryParameter = {
      aggregate: aggregate,
      orderBys: orderBys,
    };
    return this.purchaseOrdersService?.getPurchaseOrderItems(id, params).then(data => {
      const poItems = data.records as PurchaseOrderItem[];
      this.setState({
        poItems: poItems,
      });
    });
  }

  async loadEvents(id: string): Promise<void> {
    const filters: string[] = [
      JSON.stringify({
        field: 'entity_type',
        value: 'purchase_order',
      }),
      JSON.stringify({
        field: 'entity_id',
        value: id,
      }),
    ];
    const orderBys = JSON.stringify({field: 'recorded_at', direction: 'DESC'});
    const params: QueryParameter = {
      filters: filters,
      orderBys: [orderBys],
      aggregate: true,
    };
    return this.tleService?.getTimeLineEvents(params).then(data => {
      const events = data.records as TimeLineEvent[];

      const items = events.map(event => {
        const item: TwoTimelineItem = {event: event};
        return item;
      });

      this.setState({
        events: events,
        items: items,
      });
    });
  }

  showEditDialog() {
    this.setState({showEditDialog: true});
  }

  hideEditDialog() {
    this.setState({showEditDialog: false});
  }

  showRecordDeliveryDialog() {
    this.setState({showRecordDeliveryDialog: true});
  }

  hideRecordDeliveryDialog() {
    this.setState({showRecordDeliveryDialog: false});
  }

  changeStage(newStage: PurchaseOrderStage, changedPurchaseOrder?: PurchaseOrder) {
    this.setState({loading: true});
    const purchaseOrder = changedPurchaseOrder ? changedPurchaseOrder : this.state.purchaseOrder;
    if (purchaseOrder?.id) {
      const updatedPo = {
        ...purchaseOrder,
        stage: newStage,
        related_order_ids: purchaseOrder.related_order_ids?.filter(o => o !== null),
      };
      this.purchaseOrdersService
        ?.updatePurchaseOrder(purchaseOrder.id, updatedPo)
        .then(() => {
          this.toastService?.showSuccess(this.toast, `Purchase Order ${purchaseOrder.id} stage updated successfully.`);
          MessageService.sendMessage(messages.purchaseOrderUpdated);
        })
        .catch(error => {
          this.toastService?.showError(
            this.toast,
            `Sorry, The Purchase Order ${purchaseOrder.id} stage update failed, please try again.`
          );
          console.error(error);
        })
        .then(() => {
          this.setState({loading: false});
        });
    } else {
      this.toastService?.showError(this.toast, 'Sorry, The Purchase Order stage update failed, please try again.');
      this.setState({loading: false});
    }
  }

  getCurrentUserId(): string {
    const unparsedUser: string = localStorage.getItem('user') ?? '';
    const currentUser = JSON.parse(unparsedUser);
    const userId = currentUser?.uuid ?? '';
    return userId;
  }

  getActions(purchaseOrder: PurchaseOrder): TwoAction[] {
    const actions = [];
    const editDetailAction = {
      icon: faPencil,
      label: 'Edit',
      main: true,
      action: () => {
        this.showEditDialog();
      },
    };

    const printItemsAction = {
      icon: faPrint,
      label: 'Print',
      main: false,
      action: () => {
        MessageService.sendMessage(messages.printPurchaseOrders);
      },
    };

    const recordDeliveryAction = {
      icon: faTruck,
      label: 'Record Delivery',
      main: false,
      action: () => {
        this.showRecordDeliveryDialog();
      },
    };
    const orderedStageAction = {
      icon: faArrowCircleRight,
      label: 'Ordered',
      main: false,
      action: () => {
        this.showDateDialog('Ordered');
      },
    };
    const etaConfirmedStageAction = {
      icon: faArrowCircleRight,
      label: 'ETA Confirmed',
      main: false,
      action: () => {
        this.changeStage('Eta Confirmed');
      },
    };
    const delayedStageAction = {
      icon: faArrowCircleRight,
      label: 'Delayed',
      main: false,
      action: () => {
        this.changeStage('Delayed');
      },
    };
    const deliveredStageAction = {
      icon: faArrowCircleRight,
      label: 'Delivered',
      main: false,
      action: () => {
        this.changeStage('Delivered');
      },
    };
    const cancelledStageAction = {
      icon: faArrowCircleRight,
      label: 'Cancel',
      main: false,
      action: () => {
        this.changeStage('Cancelled');
      },
    };

    const separator = {
      separator: true,
    };

    actions.push(editDetailAction);

    actions.push(printItemsAction);
    actions.push(separator);

    if (
      purchaseOrder?.stage === 'Ordered' ||
      purchaseOrder?.stage === 'Eta Confirmed' ||
      purchaseOrder?.stage === 'Delayed' ||
      purchaseOrder?.stage === 'Partially Delivered'
    ) {
      actions.push(recordDeliveryAction);
      actions.push(separator);
    }

    if (purchaseOrder?.stage === 'Draft') {
      actions.push(orderedStageAction);
    }

    if (purchaseOrder?.stage === 'Ordered') {
      actions.push(etaConfirmedStageAction);
      actions.push(delayedStageAction);
      actions.push(deliveredStageAction);
    }

    if (purchaseOrder?.stage === 'Eta Confirmed') {
      actions.push(delayedStageAction);
      actions.push(deliveredStageAction);
    }

    if (purchaseOrder?.stage === 'Delayed') {
      actions.push(deliveredStageAction);
    }

    if (purchaseOrder?.stage !== 'Delivered') {
      actions.push(cancelledStageAction);
    }

    return actions;
  }

  closeDateDialog() {
    this.setState({showDateDialog: false});
  }

  showDateDialog(stage: PurchaseOrderStage) {
    const order = this.state.purchaseOrder;
    if (order) {
      const poOrder = {
        ...order,
        sent_at: order.sent_at
          ? new Date(order.sent_at)
          : stage === 'Ordered' || stage === 'Eta Confirmed' || stage === 'Delivered'
            ? new Date()
            : undefined,

        eta: order.eta
          ? new Date(order.eta)
          : stage === 'Eta Confirmed' || stage === 'Delivered'
            ? new Date()
            : undefined,

        delivered_at: order.delivered_at
          ? new Date(order.delivered_at)
          : stage === 'Delivered'
            ? new Date()
            : undefined,
      };

      this.setState({
        changeDatePurchaseOrder: poOrder,
        showDateDialog: true,
        currentStage: stage,
      });
    }
  }

  async saveStageAndDatePurchaseOrders() {
    this.closeDateDialog();
    const currentStageChange = this.state.currentStage;

    this.changeStage(currentStageChange, this.state.changeDatePurchaseOrder);
  }

  handleChange(purchaseOrder: PurchaseOrder) {
    this.setState({changeDatePurchaseOrder: purchaseOrder});
  }

  renderDateDialogFooter() {
    return (
      <div className={'p-d-flex p-my-4 p-justify-end'}>
        <Button label="Cancel" className={'p-mr-2 p-button-text'} onClick={() => this.closeDateDialog()} />
        <Button
          label="Save"
          className={'p-mr-2'}
          onClick={() => {
            this.saveStageAndDatePurchaseOrders();
          }}
          autoFocus
        />
      </div>
    );
  }
  render() {
    const {purchaseOrder, poItems, loading} = this.state;

    if (loading) {
      return (
        <div className="p-d-flex p-ai-center w-100 h-100">
          <ProgressSpinner />
        </div>
      );
    }

    return purchaseOrder ? (
      <>
        <TwoEntityComponent title={purchaseOrder.id?.toString() ?? ''} actions={this.getActions(purchaseOrder)}>
          <TwoEntityPanel isPrimary={true}>
            <PurchaseOrderDetail purchaseOrder={purchaseOrder} />
          </TwoEntityPanel>
          <TwoEntityPanel label="Items" icon={faClipboardList} tooltip="Items">
            <div style={{height: '99%'}}>
              <PurchaseOrderItems
                purchaseOrder={purchaseOrder}
                poItems={poItems ?? []}
                toast={this.toast}
                itemsTableId="purchase_order_detail_items_id"
              />
            </div>
          </TwoEntityPanel>
          <TwoEntityPanel label="Orders" icon={faList} tooltip="Orders">
            <PurchaseOrderOrders purchaseOrder={purchaseOrder} history={this.props.history} />
          </TwoEntityPanel>
          <TwoEntityPanel label="Timeline" icon={faCalendarAlt} tooltip="Timeline">
            <TwoTimeline key={this.state.purchaseOrder?.id} items={this.state.items} />
          </TwoEntityPanel>
        </TwoEntityComponent>
        <PurchaseOrderEditDialog
          toast={this.toast}
          purchaseOrder={purchaseOrder}
          poItems={poItems}
          showDialog={this.state.showEditDialog}
          onHide={this.hideEditDialog}
        />
        <PurchaseOrderRecordDeliveryDialog
          toast={this.toast}
          purchaseOrder={purchaseOrder}
          showDialog={this.state.showRecordDeliveryDialog}
          onHide={this.hideRecordDeliveryDialog}
        />
        {this.state.changeDatePurchaseOrder && (
          <Dialog
            className="purchaser-order-date-dialog"
            header={'Set Date'}
            visible={this.state.showDateDialog}
            footer={this.renderDateDialogFooter()}
            style={{width: '60%', height: '60%'}}
            modal
            onHide={() => this.closeDateDialog()}
          >
            <PurchaseOrderDate purchaseOrders={[this.state.changeDatePurchaseOrder]} handleChange={this.handleChange} />
          </Dialog>
        )}
        {this.state.purchaseOrder ? (
          <PurchaseOrderListPrint id="print-purchase-orders" purchaserOrders={[this.state.purchaseOrder]} />
        ) : (
          ''
        )}
        <Toast ref={this.toast} />
      </>
    ) : (
      <></>
    );
  }
}

export default withRouter(PurchaseOrderComponent);
