import {Calendar} from 'primereact/calendar';
import {InputSwitch} from 'primereact/inputswitch';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import React from 'react';
import {MessageService, AppContext, ToastService, TwoDialog} from 'two-app-ui';
import PurchaseOrdersService from '../../services/PurchaseOrdersService';
import {
  PurchaseOrder,
  PurchaseOrderItem,
  SupplyItem,
  PurchaseOrderStage,
  Order,
  QueryParameter,
  FactoryOrderPatch,
  PurchaseOrderDelivery,
  PurchaseOrderDeliveryItem,
} from 'two-core';
import {Toast} from 'primereact/toast';
import {messages} from '../../config/messages';
import formats from '../../config/formats';
import InventoryService from '../../services/InventoryService';
import OrdersService from '../../services/OrdersService';
import BomService from '../../services/BomService';
import GroupOrderComponents from './GroupOrderComponents';
import FactoryOrdersService from '../../services/FactoryOrdersService';
import {InputNumber, InputNumberValueChangeParams} from 'primereact/inputnumber';

interface PurchaseOrderItemIndex {
  index: number;
  item: PurchaseOrderItem;
  selectedOrders: Order[];
}

interface DeliveredQuantity {
  index: number;
  value: number;
  remainder: number;
  isFinal?: boolean;
}

export const purchaseOrderStages: PurchaseOrderStage[] = [
  'Draft',
  'Ordered',
  'Eta Confirmed',
  'Delivered',
  'Delayed',
  'Cancelled',
];

interface Props {
  purchaseOrder: PurchaseOrder;
  toast: React.RefObject<Toast>;
  showDialog: boolean;
  onHide: () => void;
}

interface State {
  loading: boolean;
  purchaseOrder: PurchaseOrder;
  purchaseOrderItems: PurchaseOrderItemIndex[];
  quantities: DeliveredQuantity[];
  completeDelivery: boolean;
  supplyItems: SupplyItem[];
  expandedRows: string[];
  orders: Order[];
  showIsFinalSwitch: boolean;
  isFinal: boolean;
}

class PurchaseOrderRecordDeliveryDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  purchaseOrdersService: PurchaseOrdersService | null = null;
  inventoryService: InventoryService | null = null;
  toastService: ToastService | null = null;
  ordersService: OrdersService | null = null;
  bomService: BomService | null;
  factoryOrdersService: FactoryOrdersService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      purchaseOrder: {
        factory_id: '',
        related_order_ids: [],
        stage: 'Draft',
        supplier_id: '',
        updated_at: new Date(),
      },
      completeDelivery: true,
      purchaseOrderItems: [],
      quantities: [],
      supplyItems: [],
      expandedRows: [],
      orders: [],
      showIsFinalSwitch: false,
      isFinal: false,
    };

    this.ordersService = null;
    this.bomService = null;

    this.save = this.save.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.setPurchaseOrder = this.setPurchaseOrder.bind(this);
    this.remainingQtyBodyTemplate = this.remainingQtyBodyTemplate.bind(this);
    this.nowDeliveredQtyBodyTemplate = this.nowDeliveredQtyBodyTemplate.bind(this);
    this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
    this.handleSelectedOrdersChange = this.handleSelectedOrdersChange.bind(this);
  }

  componentDidMount() {
    this.purchaseOrdersService = this.context.purchaseOrdersService;
    this.inventoryService = this.context.inventoryService;
    this.toastService = this.context.toastService;
    this.ordersService = this.context.ordersService;
    this.bomService = this.context.bomService;
    this.factoryOrdersService = this.context.factoryOrdersService;
  }

  setPurchaseOrder() {
    const purchaseOrder = this.props.purchaseOrder;
    const items = purchaseOrder.items?.filter(i => i !== null) ?? [];
    const quantities: DeliveredQuantity[] = [];

    const itemsToUpdate = items.map((i, index) => {
      const poi: PurchaseOrderItemIndex = {
        index: index,
        item: i,
        selectedOrders: [],
      };
      quantities.push({
        index: index,
        value: i.quantity - (i.delivered_qty ?? 0),
        remainder: 0,
        isFinal: false,
      });
      return poi;
    });

    purchaseOrder.delivered_at = new Date(Date.now());

    this.loadSupplyItems(items);
    this.setState({
      purchaseOrder: purchaseOrder,
      purchaseOrderItems: [...itemsToUpdate],
      quantities: quantities,
    });
  }

  loadSupplyItems(purchaseOrderItems: PurchaseOrderItem[]) {
    const supplyItems: SupplyItem[] = [];
    const params: QueryParameter = {
      aggregate: false,
    };
    const promises = purchaseOrderItems.map(item => {
      this.inventoryService?.getSupplyItems(item.inventory_item_id, params).then(data => {
        if (data) {
          supplyItems.push(...data);
        }
      });
    });

    Promise.all(promises).then(() => {
      this.setState({supplyItems: supplyItems});
    });
  }

  async loadOrders() {
    this.setState({loading: true});
    const orderFilters: string[] = [
      JSON.stringify({
        field: 'purchase_order.id',
        value: this.props.purchaseOrder.id,
      }),
    ];

    const params: QueryParameter = {
      filters: orderFilters,
      aggregate: true,
    };

    return this.ordersService
      ?.getOrders(params)
      .then(data => {
        const orders = data.records as Order[];
        this.setState({
          orders: orders,
          loading: false,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.props.toast, 'Sorry, orders load failed, please try again.');
        this.setState({loading: false});
      });
  }

  async save() {
    this.setState({loading: true});
    const purchaseOrder = this.state.purchaseOrder;

    if (!purchaseOrder) {
      this.toastService?.showError(this.props.toast, 'Uups, something went wrong. Please, refresh the page.');
      this.setState({loading: false});
      return;
    }

    const completeDelivery = this.state.completeDelivery;
    const quantities = this.state.quantities;
    const itemsToUpdate = this.state.purchaseOrderItems;

    const poDeliveryRecords: PurchaseOrderDeliveryItem[] = [];

    for (const indexItem of itemsToUpdate) {
      const poi = indexItem.item;
      let qty2record = 0;
      const updatedQuantity = quantities.find(q => q.index === indexItem.index);

      if (completeDelivery) {
        qty2record = poi.quantity - (poi.delivered_qty ?? 0);
      } else {
        qty2record = updatedQuantity?.value ?? 0;
      }
      if (qty2record > 0) {
        poDeliveryRecords.push({
          inventory_item_id: poi.inventory_item_id,
          qty: qty2record,
          is_final: updatedQuantity?.isFinal ?? false,
        } as PurchaseOrderDeliveryItem);
      }
    }
    const poToSave: PurchaseOrderDelivery = {
      purchase_order_id: purchaseOrder.id!,
      content: poDeliveryRecords,
      received_at: this.state.purchaseOrder.delivered_at!,
    };
    if (this.state.showIsFinalSwitch) {
      poToSave.is_final = this.state.isFinal;
    }
    const recordDelivery = await this.purchaseOrdersService?.recordPurchaseOrderDelivery(purchaseOrder.id!, poToSave);
    if (!recordDelivery) {
      this.toastService?.showError(
        this.props.toast,
        'Sorry, something went wrong saving your delivery. Please, refresh the page and try again.'
      );
      this.setState({loading: false});
      console.error('Failed to record delivery for PO.');
    }

    this.toastService?.showSuccess(
      this.props.toast,
      `Purchase Order ${purchaseOrder?.id} delivery recorded successfully.`
    );
    await this.loadOrders();

    await this.checkStageChangeReady(this.state.orders);
    MessageService.sendMessage(messages.purchaseOrderUpdated);
    this.hideDialog();
  }

  async checkStageChangeReady(orders: Order[]) {
    const orderUpdates = orders.map(order => {
      const poFilters: string[] = [];
      poFilters.push(
        JSON.stringify({
          field: 'purchase_for_order.order_id',
          value: order.id,
        }),
        JSON.stringify({
          field: 'purchase_order.stage',
          value: ['Delivered', 'Partially Delivered'],
          condition: 'notIn',
        }),
        JSON.stringify({
          field: 'purchase_for_order.purchase_order_id',
          value: this.props.purchaseOrder.id,
          condition: '<>',
        })
      );
      const params: QueryParameter = {
        filters: poFilters,
        aggregate: true,
      };
      return this.purchaseOrdersService?.getPurchaseOrders(params).then(data => {
        const dataRecords = (data.records as PurchaseOrder[]) ?? [];
        if (dataRecords.length === 0 && order.factory_order?.production_stage === 'Waiting 4 Material') {
          // update orders
          this.updateFactoryOrder(
            {
              production_stage: 'Ready',
            },
            order
          );
        }
      });
    });

    await Promise.all(orderUpdates);
  }

  async updateFactoryOrder(factoryOrderPatch: FactoryOrderPatch, order: Order) {
    return this.factoryOrdersService?.updateFactoryOrder(order.id ?? '', factoryOrderPatch);
  }

  getOrdersFromPOItems(): Order[] {
    let orders: Order[] = [];
    this.state.purchaseOrderItems.forEach(item => {
      orders = [...orders, ...item.selectedOrders];
    });

    return orders;
  }

  hideDialog() {
    this.props.onHide();
    this.setState({
      loading: false,
      purchaseOrderItems: [],
      quantities: [],
      completeDelivery: true,
    });
  }

  handleChange(title: string, value: string | Date) {
    const purchaseOrder = this.state.purchaseOrder;
    if (purchaseOrder) {
      const poupdated = {
        ...purchaseOrder,
        [title]: value,
      };

      this.setState({purchaseOrder: poupdated});
    }
  }

  handleItemChange(poitem: PurchaseOrderItemIndex, value: number | boolean | null) {
    const valueQuantity = this.state.quantities.filter(q => q.index !== poitem.index);
    const updatedQuantity = this.state.quantities.filter(q => q.index === poitem.index);

    const newQuantity: DeliveredQuantity = {
      value: updatedQuantity[0].value,
      index: poitem.index,
      remainder: updatedQuantity[0].remainder,

      isFinal: updatedQuantity[0].isFinal,
    };

    // is it a change to the amount or the is final switch?
    if (typeof value === 'number') {
      newQuantity.value = value;
      newQuantity.remainder = poitem.item.quantity - (poitem.item.delivered_qty ?? 0) - value;
    } else if (typeof value === 'boolean') {
      newQuantity.isFinal = value;
    }

    valueQuantity.push(newQuantity);
    const updatedQuantities = [...valueQuantity];

    this.setState({
      quantities: updatedQuantities,
    });
  }

  remainingQtyBodyTemplate(poi: PurchaseOrderItemIndex): number {
    const value = poi.item.quantity - (poi.item.delivered_qty ?? 0);
    if (poi.item.delivered && poi.item.delivered) {
      return 0;
    }
    return Math.max(0, value);
  }

  nowDeliveredQtyBodyTemplate(poi: PurchaseOrderItemIndex): number {
    const updatedQuantity = this.state.quantities.find(p => p.index === poi.index);
    return updatedQuantity?.value ?? poi.item.quantity - (poi.item.delivered_qty ?? 0);
  }

  handleSelectedOrdersChange(changedPoItemIndex: PurchaseOrderItemIndex) {
    const purchaseOrderItems = [...this.state.purchaseOrderItems];
    const poItemIndex = purchaseOrderItems[changedPoItemIndex.index];
    poItemIndex.selectedOrders = changedPoItemIndex.selectedOrders;
    purchaseOrderItems[changedPoItemIndex.index] = poItemIndex;

    this.setState({purchaseOrderItems});
  }

  rowExpansionTemplate = (purchaseOrderItemIndex: PurchaseOrderItemIndex) => {
    return !this.state.completeDelivery ? (
      <GroupOrderComponents
        purchaseOrderItemIndex={purchaseOrderItemIndex}
        purchaseOrder={this.state.purchaseOrder}
        toast={this.props.toast}
        supplyItems={this.state.supplyItems}
        handleSelectedOrdersChange={this.handleSelectedOrdersChange}
      />
    ) : (
      ''
    );
  };

  getMaxForPOItem(poi: PurchaseOrderItemIndex): number {
    return poi.item.delivered_qty !== undefined ? poi.item.quantity - poi.item.delivered_qty : poi.item.quantity;
  }

  isFinalTemplate = (poi: PurchaseOrderItemIndex) => {
    const quantity = this.state.quantities.find(p => p.index === poi.index);
    if (quantity?.remainder && quantity?.remainder > 0) {
      return (
        <InputSwitch
          checked={quantity.isFinal}
          name="is_final"
          onChange={e => {
            this.handleItemChange(poi, e.value);
          }}
        />
      );
    } else {
      return <></>;
    }
  };

  render() {
    const {purchaseOrder, completeDelivery, purchaseOrderItems} = this.state;

    const dialogBody = (
      <>
        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
          <label htmlFor="vendor" className="p-col-1">
            vendor
          </label>
          <span className="p-col-2 p-p-0">{purchaseOrder.id ?? ''}</span>
          <label htmlFor="stage" className="p-col-1">
            stage
          </label>
          <span className="p-col-2 p-p-0">{purchaseOrder.supplier?.company_name}</span>
          <label htmlFor="delivered_at" className="p-col-1">
            delivered at
          </label>
          <div className="p-col-2 p-p-0">
            <Calendar
              className={'w-100'}
              value={purchaseOrder.delivered_at ? new Date(purchaseOrder.delivered_at) : new Date()}
              dateFormat={formats.calendarInputDate}
              name="delivered_at"
              onChange={e => {
                const date = e.value as Date;
                this.handleChange('delivered_at', date);
              }}
            />
          </div>
          <div className="p-col-3 p-p-0 p-grid">
            <label htmlFor="complete_delivery" className="p-col-8 p-p-0">
              complete delivery
            </label>
            <div className="p-col-4 p-p-0">
              <InputSwitch
                checked={this.state.completeDelivery}
                name="complete_delivery"
                onChange={e => {
                  this.setState({
                    completeDelivery: e.value,
                  });
                }}
              />
            </div>
            {this.state.showIsFinalSwitch && (
              <>
                <label htmlFor="is_final" className="p-col-8 p-p-0">
                  Is Final
                </label>
                <div className="p-col-4 p-p-0">
                  <InputSwitch
                    checked={this.state.isFinal}
                    name="is_final"
                    onChange={e => {
                      this.setState({
                        isFinal: e.value,
                      });
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <DataTable
          className="w-100"
          value={purchaseOrderItems}
          expandedRows={this.state.expandedRows}
          rowExpansionTemplate={this.rowExpansionTemplate}
          onRowToggle={e => {
            this.setState({expandedRows: e.data});
          }}
        >
          {!completeDelivery && <Column expander className={'table-expander'} bodyClassName={'table-expander'} />}
          <Column style={{width: '30%'}} key="detail" field="item.detail" header="Item" />
          <Column style={{width: '10%'}} key="package_size" field="item.package_size" header="Package Size" />
          <Column style={{width: '10%'}} key="quantity" field="item.quantity" header="Qty" />
          <Column style={{width: '10%'}} key="order_unit" field="item.order_unit" header="Order Unit" />
          <Column style={{width: '10%'}} key="unit_price" field="item.unit_price" header="Unit Price" />
          <Column style={{width: '10%'}} key="delivered_qty" field="item.delivered_qty" header="Already Delivered" />
          {!completeDelivery ? (
            <Column
              style={{width: '10%', minWidth: '130px'}}
              key="now_in"
              header="Now In"
              body={(purchaseOrderItem: PurchaseOrderItemIndex) => (
                <InputNumber
                  inputClassName="w-100"
                  name={'now_in'}
                  min={0}
                  max={this.getMaxForPOItem(purchaseOrderItem) * 2}
                  value={this.nowDeliveredQtyBodyTemplate(purchaseOrderItem)}
                  onValueChange={(e: InputNumberValueChangeParams) => {
                    this.handleItemChange(purchaseOrderItem, e.value);
                  }}
                  showButtons
                />
              )}
            />
          ) : (
            <Column
              style={{width: '5%'}}
              key="now_in"
              field="now_in"
              header="Now In"
              body={this.remainingQtyBodyTemplate}
            />
          )}

          {!completeDelivery && <Column style={{width: '5%'}} header="is Final?" body={this.isFinalTemplate} />}
        </DataTable>
      </>
    );

    return (
      <TwoDialog
        headerTitle={`Record Delivery of ${purchaseOrder.id ?? ''}`}
        showDialog={this.props.showDialog}
        visible={this.props.showDialog}
        width={70}
        onHide={this.hideDialog}
        onSave={this.save}
        loading={this.state.loading}
        onShow={this.setPurchaseOrder}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}

export default PurchaseOrderRecordDeliveryDialog;
