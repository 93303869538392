import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {OtherMenuOptions, PrintMenuOptions, StageMenuOptions} from '../Order/Constants/constants';
import OrderListComponent from '../Orders/OrderListComponent';

class StandardOrderListComponent extends React.Component<RouteComponentProps> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <OrderListComponent
        showFilterBox={true}
        orderStages={[
          'Init',
          'Waiting For Return',
          'New',
          'Waiting 4 Material',
          'Ready',
          'Alu Required',
          'Alu Ordered',
          'Alu Needs Cutting',
          'Alu Cutting',
          'Powder Required',
          'Powder Ordered',
          'Sent To Floor',
          'In Production',
          'Done',
          'Between Factories',
          'Post Fix Return',
          'Cancelled',
          'On Hold',
        ]}
        orderTypes={['Standard']}
        printMenuOptions={Object.values(PrintMenuOptions)}
        stageMenuOptions={Object.values(StageMenuOptions).filter(v => v !== StageMenuOptions.PostFixReturn)}
        otherMenuOptions={Object.values(OtherMenuOptions)}
        history={this.props.history}
      />
    );
  }
}

export default withRouter(StandardOrderListComponent);
