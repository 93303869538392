import React from 'react';
import logo from '../../assets/TWO-Logo-2021.png';
import {DateTime} from 'luxon';
import {MapOf, Order, PurchaseOrder, PurchaseOrderItem} from 'two-core';
import './PurchaseOrderPrint.scss';
import formats from '../../config/formats';
import POReport, {POReportFooter} from './POReport';

interface PurchaseOrderProps {
  orders: MapOf<Order>;
  data: PurchaseOrder;
}

const POPrint = (props: PurchaseOrderProps) => {
  const report = new POReport();

  const purchaseOrder = props.data;
  const supplier = purchaseOrder?.supplier;
  const purchaserOrderItems = purchaseOrder?.items?.filter(i => i !== null) ?? [];
  const subtotal = purchaserOrderItems.reduce((a, b) => a + ((b?.unit_price ?? 0) * (b?.quantity ?? 0) ?? 0), 0);

  const ordersIds = purchaseOrder.related_order_ids ?? [];
  const order = ordersIds && ordersIds.length === 1 && ordersIds[0] ? props.orders[ordersIds[0]] : undefined;

  report.addHeader(
    <>
      <div className="p-d-flex p-flex-row">
        <div className="logo w-30">
          <img src={logo} alt="Logo" />
        </div>
        <div className="w-70 p-mt-4 p-d-flex p-flex-row">
          <table className="p-d-flex p-flex-row">
            <tbody className="p-d-flex p-flex-column w-100">
              <tr className="p-d-flex p-flex-row w-100">
                <td className="p-d-flex text w-50">347 Macdonnell Road</td>
                <td className="p-d-flex text">procurement@two-australia.com</td>
              </tr>
              <tr className="p-d-flex p-flex-row w-100">
                <td className="p-d-flex text w-50">Clontaf QLD, 4019</td>
                <td className="p-d-flex text w-50">1300 183 910</td>
              </tr>
              <tr className="p-d-flex p-flex-row w-100">
                <td className="p-d-flex text w-50">ABN 96 125 805 389</td>
                <td className="p-d-flex text w-50"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="p-d-flex p-flex-column p-mb-2">
        <span className="title">Purchase Order</span>
      </div>
      <div className="p-d-flex p-flex-row w-100">
        <table className="p-d-flex p-flex-column with-bottom-border w-50">
          <tr className="bold p-d-flex p-flex-row w-100">
            <td className="biger-text w-50">Date</td>
            <td className="biger-text w-50">P.O. Number</td>
          </tr>
          <tbody className="p-d-flex p-flex-row w-100 p-mb-2">
            <tr className="p-d-flex p-flex-row w-100">
              <td className="text w-50">
                {purchaseOrder.sent_at
                  ? DateTime.fromISO(
                      DateTime.fromJSDate(new Date(purchaseOrder.sent_at)).toISODate() as string
                    ).toFormat(formats.date)
                  : ''}
              </td>
              <td className="text w-50">{purchaseOrder.id}</td>
            </tr>
          </tbody>
        </table>
        {order && (
          <table className="p-d-flex p-flex-column with-bottom-border w-50">
            <tr className="bold p-d-flex p-flex-row w-100">
              <td className="biger-text w-100">Order Code and Reference</td>
            </tr>
            <tbody className="p-d-flex p-flex-column w-100 p-mb-2">
              <tr key={order.id} className="p-d-flex p-flex-row w-100">
                <td className="text w-100">{(order.id ?? '') + ' ' + (order.reference ?? '')}</td>
              </tr>
            </tbody>
          </table>
        )}
      </div>

      <div className="p-d-flex p-flex-column p-mb-2 p-mt-2">
        <span className="title">Vendor</span>
      </div>
      <table className="p-d-flex p-flex-column border-top">
        <tbody className="p-d-flex p-flex-column w-100">
          <tr className="p-d-flex p-flex-row w-100">
            <td className="text bold w-50" colSpan={2}>
              {supplier?.company_name ?? ''}
            </td>
          </tr>
          <tr className="p-d-flex p-flex-row w-100">
            <td className="text w-50">
              {supplier?.address && supplier?.address?.street + ', ' + supplier?.address?.suburb}
            </td>
            <td className="text w-50">{supplier?.email ?? ''}</td>
          </tr>
          <tr className="p-d-flex p-flex-row w-100">
            <td className="text w-50">
              {supplier?.address && supplier?.address?.state + ', ' + supplier?.address?.postCode}
            </td>
            <td className="text w-50">{supplier?.phone ?? ''}</td>
          </tr>
          <tr className="p-d-flex p-flex-row w-100">
            <td className="text bold w-50" colSpan={2}>
              ABN {supplier?.tax_id ?? ''}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="p-d-flex p-flex-column p-mt-2 p-mb-1">
        <table className="p-d-flex p-flex-column with-top-border">
          <tr className="p-d-flex p-flex-row p-mt-2 p-mb-3 w-100">
            <td className="text p-pr-2">#</td>
            <td className="text" style={{width: '45%'}}>
              Item
            </td>
            <td className="text w-10 text-align-right">Qty</td>
            <td className="text w-15 text-align-right">Unit</td>
            <td className="text w-10 text-align-right">Unit Price</td>
            <td className="text w-10 text-align-right">Total Price</td>
          </tr>
        </table>
      </div>
    </>
  );

  purchaserOrderItems &&
    purchaserOrderItems.map((item: PurchaseOrderItem, i: number) => {
      const index = i + 1;
      const totalPrice = ((item?.unit_price ?? 0) * (item?.quantity ?? 0)).toFixed(2);
      const reportItem = (
        <table className="p-d-flex p-flex-column">
          <tbody className="p-d-flex p-flex-column">
            <tr key={index} className="p-d-flex p-flex-row p-mb-2 w-100">
              <td className="item-number p-as-center p-pr-2">{index}</td>
              <td className="text p-as-center" style={{width: '45%'}}>
                {item.detail ?? ''}
              </td>
              <td className="text w-10 text-align-right p-as-center">{item.quantity ?? ''}</td>
              <td className="text w-15 text-align-right p-as-center">{item.order_unit ?? ''}</td>
              <td className="text w-10 text-align-right p-as-center">{item.unit_price ?? ''}</td>
              <td className="text w-10 text-align-right p-as-center">{totalPrice}</td>
            </tr>
          </tbody>
        </table>
      );

      report.addItem(item.detail.length, reportItem);
    });

  report.addItem(
    1,
    <>
      <table className="p-d-flex p-flex-column p-mt-2 with-top-border">
        <tbody className="p-d-flex p-flex-column">
          <tr className="p-d-flex p-flex-row w-100">
            <td className="w-50"></td>
            <td className="text w-30">Subtotal</td>
            <td className="text w-20 bold text-align-right">{subtotal.toFixed(2)}</td>
          </tr>
          <tr className="p-d-flex p-flex-row w-100">
            <td className="w-50"></td>
            <td className="text w-30">Tax rate</td>
            <td className="text w-20 bold text-align-right">10.00%</td>
          </tr>
          <tr className="p-d-flex p-flex-row w-100">
            <td className="w-50"></td>
            <td className="text w-30">Sales tax</td>
            <td className="text w-20 bold text-align-right">{(subtotal * 0.1).toFixed(2)}</td>
          </tr>
          <tr className="p-d-flex p-flex-row w-100 p-mt-2">
            <td className="w-50"></td>
            <td className="text w-30">
              <strong>Total</strong>
            </td>
            <td colSpan={2} className="total w-20 text-align-right">
              ${(subtotal + subtotal * 0.1).toFixed(2)}
            </td>
          </tr>
        </tbody>

        {report.addFooter(1, new POReportFooter(), purchaseOrder)}
      </table>
    </>
  );

  return (
    <div className="po-print">
      <div className="po-print-report p-d-flex p-flex-column">{report.getReport()}</div>
    </div>
  );
};

export default POPrint;
