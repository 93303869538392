import React from 'react';
import {Calendar, CalendarChangeParams} from 'primereact/calendar';
import {
  AppContext,
  MessageService,
  TwoDataTable,
  ToastService,
  UsersService,
  TwoDialog,
  TleReferenceComponent,
} from 'two-app-ui';
import {FactoryOrderPatch, Order, QueryParameter, User} from 'two-core';
import {Toast} from 'primereact/toast';
import formats from '../../../config/formats';
import {messages} from '../../../config/messages';
import OrdersService from '../../../services/OrdersService';
import {DateTime} from 'luxon';
import FactoryOrdersService from '../../../services/FactoryOrdersService';
import {Column} from 'primereact/column';
import {DataTablePageParams} from 'primereact/datatable';
import PoReferenceComponent from '../../Reference/PoReferenceComponent';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  ordersRecords: Order[];
  toast: React.RefObject<Toast>;
  dateName: string;
}

interface State {
  loading: boolean;
  items: Order[];
  totalItems: number;
  dateValue: Date;
  pagination: {
    pageSize: number;
    offset: number;
  };
  users: User[];
}

class EditDateDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  ordersService: OrdersService | null = null;
  factoryOrdersService: FactoryOrdersService | null = null;
  usersService: UsersService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      items: [],
      totalItems: 0,
      dateValue: new Date(),
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      users: [],
    };

    this.save = this.save.bind(this);
    this.loadData = this.loadData.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.setDateValue = this.setDateValue.bind(this);
    this.ecdBodyTemplate = this.ecdBodyTemplate.bind(this);
    this.prioBodyTemplate = this.prioBodyTemplate.bind(this);
    this.lastTleBodyTemplate = this.lastTleBodyTemplate.bind(this);
    this.poReferenceTemplate = this.poReferenceTemplate.bind(this);
    this.refsBodyTemplate = this.refsBodyTemplate.bind(this);
  }

  componentDidMount() {
    this.ordersService = this.context.ordersService;
    this.factoryOrdersService = this.context.factoryOrdersService;
    this.usersService = this.context.usersService;
    this.toastService = this.context.toastService;
  }

  loadData() {
    this.loadOrders();
    this.loadUsers();
  }

  async loadOrders() {
    this.setState({loading: true});
    const filters: string[] = [];
    const sortBy: string[] = [];

    const ids = this.props.ordersRecords.map(order => order.id ?? '');

    filters.push(
      JSON.stringify({
        field: 'id',
        value: ids,
        condition: 'in',
      })
    );

    sortBy.push(JSON.stringify({field: 'id', direction: 'ASC'}));

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: sortBy,
      aggregate: true,
    };

    this.ordersService
      ?.getOrders(params)
      .then((extendedData: unknown) => {
        const data = extendedData as {
          records: Order[];
          total_records: number;
          total_size: number;
        };
        const dataRecords = data.records ?? [];
        this.setState({
          items: dataRecords,
          totalItems: data.total_records ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, records load failed, please try again.');
        this.setState({loading: false});
        console.log(error);
      });
  }

  loadUsers() {
    const params: QueryParameter = {
      filters: [],
      aggregate: false,
    };

    this.usersService
      ?.getUsers(params)
      .then(data => {
        const users: User[] = (data?.records as User[]) ?? [];

        this.setState({
          users: users,
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadOrders();
  }

  hideDialog() {
    this.setState({loading: false, items: [], dateValue: new Date()});
    this.props.onHide();
  }

  async save() {
    const orders = this.state.items;
    this.updateOrders(orders);
  }

  async updateOrders(updateOrders: Order[]) {
    this.setState({loading: true});

    const dateValue = this.state.dateValue;
    Promise.all(
      updateOrders.map((currentOrder: Order) => {
        const factoryOrder = currentOrder.factory_order;
        const dateName = this.props.dateName;

        const updatedFactoryOrder: FactoryOrderPatch =
          dateName === 'ecd' ? {ecd: dateValue} : {scheduled_for: dateValue};

        return this.factoryOrdersService?.updateFactoryOrder(factoryOrder?.id ?? '', updatedFactoryOrder);
      })
    )
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Orders updated successfully.');

        this.hideDialog();
        MessageService.sendMessage(messages.ordersUpdated);
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, Orders updated failed, please try again.');
        this.setState({loading: false});
        console.error('error: ' + error);
      });
  }

  getCurrentUserId(): string {
    const unparsedUser: string = localStorage.getItem('user') ?? '';
    const currentUser = JSON.parse(unparsedUser);
    const userId = currentUser?.uuid ?? '';
    return userId;
  }

  setDateValue(e: CalendarChangeParams) {
    if (e && e.target && e.target.value) {
      const changeDate = Array.isArray(e.target.value) ? e.target.value[0] : e.target.value;
      const date = new Date(Date.UTC(changeDate.getFullYear(), changeDate.getMonth(), changeDate.getDate()));
      this.setState({
        dateValue: date,
      });
    }
  }

  ecdBodyTemplate(rowData: Order) {
    const formated_ecd = rowData.factory_order?.ecd
      ? DateTime.fromISO(rowData.factory_order?.ecd.toString()).toFormat(formats.date)
      : '';
    return <span>{formated_ecd}</span>;
  }

  scheduledForBodyTemplate(rowData: Order) {
    const formated_scheduled_for = rowData.factory_order?.scheduled_for
      ? DateTime.fromISO(rowData.factory_order?.scheduled_for.toString()).toFormat(formats.date)
      : '';
    return <span>{formated_scheduled_for}</span>;
  }

  prioBodyTemplate(rowData: Order) {
    let priorityText = '';
    switch (Number(rowData.priority)) {
      case 1:
        priorityText = '!';
        break;
      case 2:
        priorityText = '!!';
        break;
      case 3:
        priorityText = '!!!';
        break;
    }
    return <span>{priorityText}</span>;
  }

  lastTleBodyTemplate(rowData: Order) {
    const lastTle = rowData.last_tle;
    if (lastTle) {
      const user = this.state.users.find(u => u.id === lastTle.recorded_by);
      return <TleReferenceComponent identifier={rowData.id ?? ''} value={lastTle} user={user} />;
    } else {
      return <></>;
    }
  }

  refsBodyTemplate(rowData: Order) {
    return <div className="p-d-flex p-flex-row p-flex-wrap">{this.poReferenceTemplate(rowData)}</div>;
  }

  poReferenceTemplate(rowData: Order) {
    return (
      <PoReferenceComponent
        key={'PO-' + rowData.id}
        identifier={rowData.id ?? ''}
        purchaseOrders={rowData.purchase_orders ?? []}
        suppliers={[]}
        handleChangePoSupplier={() => {}}
      />
    );
  }

  render() {
    const {dateName} = this.props;
    const title = `${dateName === 'ecd' ? 'Change ECD' : 'Change Release Date'}`;

    const dialogBody = (
      <div id="orders_page_container" className="page-container">
        <div id={'date-change'} className="p-d-flex p-pb-2">
          <span className="p-d-flex p-mr-2 p-as-center">{`${title} of the orders below to: `}</span>

          <div className="p-d-flex">
            <span className="p-fluid">
              <Calendar
                value={this.state.dateValue}
                onChange={e => {
                  this.setDateValue(e);
                }}
                dateFormat={formats.calendarInputDate}
              />
            </span>
          </div>
        </div>
        <TwoDataTable
          style={{height: '100%'}}
          pageSizeIdentifier={'orders_page_container'}
          sizeIdentifiers={['date-change']}
          scrollable
          selectionMode={undefined}
          selectedItems={[]}
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          onPage={e => this.onPageChange(e as DataTablePageParams)}
          loading={this.state.loading}
          value={this.state.items}
          totalRecords={this.state.totalItems}
          activeFilters={{}}
        >
          <Column header="Order Code" field="id" style={{width: '180px'}} />
          <Column header="Reference" field="reference" style={{width: '360px'}} />

          {this.props.dateName === 'ecd' && (
            <Column header="ECD" field="ecd" body={this.ecdBodyTemplate} style={{width: '80px'}} />
          )}

          {this.props.dateName === 'scheduled_for' && (
            <Column
              header="Release"
              field="scheduled_for"
              body={this.scheduledForBodyTemplate}
              style={{width: '120px'}}
            />
          )}

          <Column header="Prio" field="priority" body={this.prioBodyTemplate} style={{width: '80px'}} />
          <Column header="Size" field="factory_order.size" style={{width: '80px'}} />
          <Column header="Customer" field="owner_company.account_number" style={{width: '150px'}} />

          <Column header="Summary" field="factory_order.summary" style={{width: '280px'}} />
          <Column header="Last Action" field="last_activity" body={this.lastTleBodyTemplate} style={{width: '280px'}} />
          <Column header="PO(s)" body={this.refsBodyTemplate} style={{width: '320px'}} />
        </TwoDataTable>
      </div>
    );
    return (
      <TwoDialog
        headerTitle={title}
        showDialog={this.props.showDialog}
        width={90}
        onHide={this.hideDialog}
        onShow={this.loadData}
        onSave={this.save}
        loading={this.state.loading}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
export default EditDateDialog;
