import {Column, ColumnBodyOptions} from 'primereact/column';
import {DataTablePageParams, DataTableSortOrderType} from 'primereact/datatable';
import {Toast} from 'primereact/toast';
import React from 'react';
import {AppContext, AppMenuItem, AppMenuItemTemplate, TwoDataTable, ToastService} from 'two-app-ui';
import PurchaseOrdersService from '../../services/PurchaseOrdersService';
import '../../scss/CustomTable.scss';
import {Subscription} from 'rxjs';
import {Bom, Order, PurchaseOrderItem, QueryParameter, Supplier, SupplyItem} from 'two-core';
import SupplierService from '../../services/SuppliersService';

import {MenuItemOptions} from 'primereact/menuitem';
import {faPlusCircle, faTimes} from '@fortawesome/pro-regular-svg-icons';
import {library} from '@fortawesome/fontawesome-svg-core';
import InventoryService from '../../services/InventoryService';
import PurchaseOrderItemAddDialog from './PurchaseOrderItemAddDialog';
import {InputText} from 'primereact/inputtext';
import BomService from '../../services/BomService';

library.add(faPlusCircle, faTimes);

interface Props {
  vendor?: Supplier | undefined;
  selectVendor?: (supplierId: string) => void;
  setPurchaseOrderItems: (purchaseOrderItems: PurchaseOrderItem[]) => void;
  order?: Order;
  purchaseOrderItems: PurchaseOrderItem[];
}

interface State {
  loading: boolean;
  prefilling: boolean;
  showPurchaseOrderItemAddDialog: boolean;
  selectedPurchaseOrderItems: PurchaseOrderItem[];
  activeFilters: {};
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
}

class PurchaseOrderItemListComponent extends React.Component<Props, State> {
  static contextType = AppContext;
  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;
  purchaseOrdersService: PurchaseOrdersService | null = null;
  supplierService: SupplierService | null = null;
  inventoryService: InventoryService | null = null;
  toastService: ToastService | null = null;
  bomService: BomService | null = null;

  subscription: Subscription = new Subscription();

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      prefilling: false,
      showPurchaseOrderItemAddDialog: false,
      selectedPurchaseOrderItems: [],
      activeFilters: {},
      pagination: {pageSize: 25, offset: 0},
      sortBy: null,
    };
    this.toast = React.createRef();

    this.setChangeSelectedItems = this.setChangeSelectedItems.bind(this);
    this.initMenuItems = this.initMenuItems.bind(this);
    this.addPurchaseOrderItems = this.addPurchaseOrderItems.bind(this);
  }

  componentDidMount() {
    this.purchaseOrdersService = this.context.purchaseOrdersService;
    this.supplierService = this.context.supplierService;
    this.inventoryService = this.context.inventoryService;
    this.toastService = this.context.toastService;
    this.bomService = this.context.bomService;

    if (this.props.order) {
      this.populatePurchaseOrderItem();
    }
  }

  async populatePurchaseOrderItem() {
    const order: Order = this.props.order as Order;
    if (!order) {
      return;
    }
    const bomFilters: string[] = [];
    if (
      order.factory_order?.production_stage === 'Alu Required' ||
      order.factory_order?.production_stage === 'Alu Ordered'
    ) {
      bomFilters.push(
        JSON.stringify({
          field: 'inventory_item.category',
          value: ['Extras', 'Aluminium'],
          condition: 'in',
        })
      );
    } else if (
      order.factory_order?.production_stage === 'Powder Required' ||
      order.factory_order?.production_stage === 'Powder Ordered'
    ) {
      bomFilters.push(
        JSON.stringify({
          field: 'inventory_item.category',
          value: ['Powder Coating'],
          condition: 'in',
        })
      );
    } else {
      return;
    }
    bomFilters.push(
      JSON.stringify({
        field: 'order_id',
        value: order.id,
      })
    );

    this.setState({prefilling: true});
    const bomParams: QueryParameter = {
      filters: bomFilters,
      aggregate: true,
    };
    this.bomService
      ?.getBoms(bomParams)
      .then((boms: Bom[]) => {
        const poItems: PurchaseOrderItem[] = [];
        let lastTemporaryId = 0;
        let supplierId = '';
        boms.forEach((bom: Bom) => {
          let defaultSupplyItem: SupplyItem | undefined = undefined;
          if (!bom.supply_items || bom.supply_items.length === 0) {
            console.error(
              `No supply items on BOM with inventory item ${bom.inventory_item_id}: ${
                bom.inventory_item?.name ?? 'no name'
              }!`
            );
            return;
          } else if (bom.supply_items.length > 1) {
            defaultSupplyItem = bom.supply_items.find(supItem => supItem.default_option);
          }
          if (!defaultSupplyItem) {
            defaultSupplyItem = bom.supply_items[0];
          }
          supplierId = defaultSupplyItem.supplier_id;
          const detail = `${defaultSupplyItem.sku ?? ''}: ${defaultSupplyItem.name ?? ''}`;
          const poItem: PurchaseOrderItem = {
            id: --lastTemporaryId,
            linked_bom_sum: 0,
            purchase_order_id: '',
            supply_item_id: defaultSupplyItem.id ?? 0,
            quantity: 1,
            detail: detail,
            inventory_item_id: bom.inventory_item?.id ?? '',
            order_unit: defaultSupplyItem.reorder_unit ?? '',
            unit_price: defaultSupplyItem.unit_price ?? 0,
            package_size: defaultSupplyItem.package_size_label ?? '',
            delivered_qty: 0,
          };
          poItems.push(poItem);
        });
        if (this.props.setPurchaseOrderItems) {
          this.props.setPurchaseOrderItems(poItems);
        }
        if (this.props.selectVendor) {
          this.props.selectVendor(supplierId);
        }
        this.setState({prefilling: false});
      })
      .catch(e => {
        console.log(e);
        this.toastService?.showError(this.toast, 'Bom records load failed');
        this.setState({prefilling: false});
      });
  }

  initMenuItems(): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];

    const selectedItems = this.state.selectedPurchaseOrderItems;
    const selectedItemsCount = selectedItems.length;

    menuItems.push({
      label: 'Add',
      faIcon: faPlusCircle,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.setState({showPurchaseOrderItemAddDialog: true});
      },
    });

    if (selectedItemsCount > 0) {
      menuItems.push({
        label: 'Remove',
        faIcon: faTimes,
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => this.removeItems(),
      });
    }

    return menuItems;
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  setPurchaseOrderItems(purchaseOrderItems: PurchaseOrderItem[]) {
    this.props.setPurchaseOrderItems(purchaseOrderItems);
  }

  addPurchaseOrderItems(newPurchaseOrderItems: PurchaseOrderItem[]) {
    const purchaseOrderItems = this.props.purchaseOrderItems.concat(newPurchaseOrderItems);
    this.setPurchaseOrderItems(purchaseOrderItems);
  }

  removeItems() {
    const reducedPurchaseOrderItems: PurchaseOrderItem[] = this.props.purchaseOrderItems.filter(
      currentPurchaseOrderItem => {
        return !this.state.selectedPurchaseOrderItems.some(selectedPurchaseOrderItem => {
          return (
            currentPurchaseOrderItem.supply_item_id === selectedPurchaseOrderItem.supply_item_id &&
            currentPurchaseOrderItem.inventory_item_id === selectedPurchaseOrderItem.inventory_item_id
          );
        });
      }
    );
    this.setPurchaseOrderItems(reducedPurchaseOrderItems);
  }

  onPageChange(e: DataTablePageParams) {
    this.setState({pagination: {offset: e.first, pageSize: e.rows}});
  }

  bodyEdit(
    poitem: PurchaseOrderItem,
    options: ColumnBodyOptions,
    name: 'detail' | 'unit_price' | 'quantity',
    type: string
  ) {
    return (
      <InputText
        className="w-100"
        name={name}
        type={type}
        value={poitem[name]}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const updated = {
            ...poitem,
            [name]: type === 'number' ? +e.target.value : e.target.value,
          };
          this.handleItemChange(updated, options.rowIndex);
        }}
      />
    );
  }

  handleItemChange(itemupdated: PurchaseOrderItem, index: number) {
    const currentPurchaseOrderItems = [...this.props.purchaseOrderItems];
    currentPurchaseOrderItems[index] = {...itemupdated};
    this.setPurchaseOrderItems(currentPurchaseOrderItems);
  }

  setChangeSelectedItems(items: PurchaseOrderItem[]) {
    this.setState({selectedPurchaseOrderItems: items});
  }

  private totalBodyTemplate(item: PurchaseOrderItem) {
    return <span>{item.quantity * item.unit_price}</span>;
  }

  private detailBodyTemplate(item: PurchaseOrderItem) {
    return <span title={item.detail}>{item.detail}</span>;
  }

  render() {
    const {loading, prefilling, showPurchaseOrderItemAddDialog, selectedPurchaseOrderItems} = this.state;
    const {purchaseOrderItems} = this.props;
    return (
      <div id="purchase_order_items_page_container" className="page-container">
        <TwoDataTable
          initMenuItems={this.initMenuItems}
          loading={loading || prefilling}
          value={purchaseOrderItems}
          selectionMode="multiple"
          selectedItems={selectedPurchaseOrderItems}
          handleChangeSelectedItems={items => this.setChangeSelectedItems(items as PurchaseOrderItem[])}
          activeFilters={[]}
          editMode={'cell'}
          className="editable-cells-table"
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          onPage={e => this.onPageChange(e as DataTablePageParams)}
          showPaging={false}
          dataKey={'supply_item_id'}
        >
          <Column style={{width: '50%'}} key="detail" field="detail" header="Detail" body={this.detailBodyTemplate} />
          <Column style={{width: '10%'}} key="package_size" field="package_size" header="Package Size" />
          <Column
            style={{width: '10%'}}
            key="quantity"
            field="quantity"
            header="Qty"
            body={(poItem, options) => this.bodyEdit(poItem as PurchaseOrderItem, options, 'quantity', 'number')}
          />
          <Column style={{width: '10%'}} key="order_unit" field="order_unit" header="Order Unit" />
          <Column
            style={{width: '10%'}}
            key="unit_price"
            field="unit_price"
            header="Unit Price"
            body={(poItem, options) => this.bodyEdit(poItem as PurchaseOrderItem, options, 'unit_price', 'number')}
          />
          <Column style={{width: '10%'}} key="total" field="total" header="Total" body={this.totalBodyTemplate} />
        </TwoDataTable>
        <PurchaseOrderItemAddDialog
          supplier={this.props.vendor}
          showDialog={showPurchaseOrderItemAddDialog}
          onHide={() => {
            this.setState({showPurchaseOrderItemAddDialog: false});
          }}
          addPurchaseOrderItems={this.addPurchaseOrderItems}
          assignedPurchaseOrderItems={this.props.purchaseOrderItems}
          toast={this.toast}
        />
      </div>
    );
  }
}

export default PurchaseOrderItemListComponent;
