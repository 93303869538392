import {CalendarChangeParams} from 'primereact/calendar';
import {DropdownChangeParams} from 'primereact/dropdown';
import React from 'react';
import {MessageService} from 'two-app-ui';
import {messages} from '../../config/messages';
import ScheduleContentComponent from './ScheduleContentComponent';
import ScheduleTopComponent from './ScheduleTopComponent';

interface State {
  productLine: string;
  filterByDate: string;
  fromDate: Date;
  toDate: Date;
  showDoneOrders: boolean;
}

class ScheduleComponent extends React.Component<{}, State> {
  constructor(props = {}) {
    super(props);
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 7);
    this.state = {
      productLine: '',
      filterByDate: '',
      fromDate: fromDate,
      toDate: new Date(),
      showDoneOrders: false,
    };

    this.onValueChange = this.onValueChange.bind(this);
  }

  async componentDidMount() {
    const productLine = localStorage.getItem('schedule product line') ?? 'Colourvue';
    const filterByDate = localStorage.getItem('filter by date') ?? 'ecd';
    const fromDateValue = localStorage.getItem('from date');
    const toDateValue = localStorage.getItem('to date');

    let fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 7);
    if (fromDateValue && fromDateValue !== '') {
      // by default, on mount only update the fromDate value if its closer than 1 month
      if (new Date(fromDateValue) > fromDate) {
        fromDate = new Date(fromDateValue);
      }
    } else {
      localStorage.setItem('from date', fromDate.toString());
    }

    const defaultToDay = new Date();
    defaultToDay.setDate(defaultToDay.getDate() + 1);

    let toDate = defaultToDay;
    if (toDateValue && toDateValue !== '' && new Date(toDateValue) > fromDate) {
      toDate = new Date(toDateValue);
    } else {
      localStorage.setItem('to date', toDate.toString());
    }

    const stringValue = localStorage.getItem('show done') ?? '';
    const showDoneOrders = stringValue ? JSON.parse(stringValue) : false;

    // @FIXME: is it really worth to save this state here? and not pass it as a prop to schedule content?
    this.setState({
      productLine: productLine,
      filterByDate: filterByDate,
      fromDate: fromDate,
      toDate: toDate,
      showDoneOrders: showDoneOrders,
    });
  }

  async onValueChange(e: DropdownChangeParams | CalendarChangeParams, name: string) {
    const value = e.target.value;
    if (name === 'productLine') {
      this.setState({productLine: value});
      localStorage.setItem('schedule product line', value);
    } else if (name === 'filterByDate') {
      this.setState({filterByDate: value});
      localStorage.setItem('filter by date', value);
    } else if (name === 'from') {
      this.setState({fromDate: value});
      localStorage.setItem('from date', value?.toString() ?? '');
    } else if (name === 'to') {
      this.setState({toDate: value});
      localStorage.setItem('to date', value?.toString() ?? '');
    } else if (name === 'showDoneOrders') {
      this.setState({showDoneOrders: value});
      localStorage.setItem('show done', value?.toString() ?? '');
    }
    MessageService.sendMessage(messages.scheduleFilterChanged);
  }

  render() {
    const {productLine, filterByDate, fromDate, toDate, showDoneOrders} = this.state;

    return (
      <div id="schedule_component" className="page-container">
        <div className={'p-d-flex'}>
          <ScheduleTopComponent
            selectedProductLine={productLine}
            selectedDate={filterByDate}
            from={fromDate}
            to={toDate}
            showDoneOrders={showDoneOrders}
            handleChange={(e, name) => this.onValueChange(e, name)}
          />
        </div>
        <div id="schedule_content_component" className={'p-d-flex schedule_content'}>
          <ScheduleContentComponent />
        </div>
      </div>
    );
  }
}

export default ScheduleComponent;
