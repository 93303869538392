export const messages = {
  orderNoteCreated: 'order-note-created',
  alarmUpdated: 'alarm-updated',
  alarmEdit: 'alarm-edit',
  orderStockCheck: 'order-stock-check',
  orderStockCheckAndContinue: 'order-stock-check-and-continue',
  orderStageChangeReady: 'order-stage-change-ready',
  ordersUpdated: 'orders-updated',
  scheduleFilterChanged: 'schedule-filter-changed',
  factoryUpdated: 'factory-updated',
  heightChanged: 'height-changed',
  schedulerExportFile: 'scheduler-export-file',
  printPurchaseOrders: 'print-purchase-orders',
  purchaseOrderUpdated: 'purchase-order-updated',
  inventoryItemUpdated: 'inventory-item-changed',
  supplyItemUpdated: 'supply-item-changed',
  oosUpdated: 'oos-changed',
  oosOrdersReassigned: 'oos-orders-reassigned',
  initTableMenuItems: 'init-table-menu-items',
  reloadInventoryItemList: 'reload-inventory-item-list',
  purchaseOrderItemsUpdated: 'purchase-order-items-updated',
};
