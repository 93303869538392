import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import React from 'react';
import {MessageService, AppContext, ToastService} from 'two-app-ui';
import PurchaseOrdersService from '../../services/PurchaseOrdersService';
import './PurchaseOrders.scss';
import {DropdownItemOption} from './Model/PurchaseOrderModel';
import InventoryService from '../../services/InventoryService';
import SupplierService from '../../services/SuppliersService';

import {
  InventoryItem,
  Order,
  PurchaseOrder,
  PurchaseOrderItem,
  QueryParameter,
  Supplier,
  PurchaseOrderStage,
} from 'two-core';
import {Toast} from 'primereact/toast';
import formats from '../../config/formats';
import {messages} from '../../config/messages';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {Calendar, CalendarChangeParams} from 'primereact/calendar';
import {Divider} from 'primereact/divider';
import {purchaseOrderStagesNewOptions} from './Constants/constants';
import PurchaseOrderItemListComponent from '../PurchaseOrderItems/PurchaseOrderItemListComponent';
import PurchaseOrderListPrint from './PurchaseOrderListPrint';
import {ProgressSpinner} from 'primereact/progressspinner';

interface Props {
  toast: React.RefObject<Toast>;
  showPurchaseOrderDialog: boolean;
  closeDialog: () => void;
  order?: Order;
  inventoryItems?: InventoryItem[];
}

interface State {
  loading: boolean;
  suppliers: Supplier[];
  selectedSupplier?: Supplier;
  purchaseOrderStageSelected: PurchaseOrderStage | null;
  orderedOn: Date | undefined;
  eta: Date | undefined;
  supplyItems: DropdownItemOption[];
  onlyPrint: boolean;
  factory: string | null;
  purchaseOrderItems: PurchaseOrderItem[];
  savedPurchaseOrder?: PurchaseOrder;
}

class PurchaseOrderAddDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  purchaseOrdersService: PurchaseOrdersService | null;
  inventoryService: InventoryService | null;
  supplierService: SupplierService | null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      suppliers: [],
      purchaseOrderStageSelected: 'Draft',
      orderedOn: undefined,
      eta: undefined,
      supplyItems: [],
      onlyPrint: false,
      factory: '',
      purchaseOrderItems: [],
    };

    this.purchaseOrdersService = null;
    this.inventoryService = null;
    this.supplierService = null;

    this.savePurchaseOrder = this.savePurchaseOrder.bind(this);
    this.setPurchaseOrderItems = this.setPurchaseOrderItems.bind(this);
    this.setDefaultPurchaseOrderItems = this.setDefaultPurchaseOrderItems.bind(this);
    this.selectVendor = this.selectVendor.bind(this);
    this.onShow = this.onShow.bind(this);
    this.onSupplierInputChange = this.onSupplierInputChange.bind(this);
    this.loadSuppliers = this.loadSuppliers.bind(this);
  }

  componentDidMount() {
    this.purchaseOrdersService = this.context.purchaseOrdersService;
    this.inventoryService = this.context.inventoryService;
    this.supplierService = this.context.supplierService;
    this.toastService = this.context.toastService;

    this.setState({factory: localStorage.getItem('current factory')});
  }

  async loadSuppliers() {
    this.setState({loading: true});
    const filters = [];

    filters.push(
      JSON.stringify({
        field: 'factory_id',
        value: localStorage.getItem('current factory'),
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: false,
    };
    return this.supplierService
      ?.getSuppliers(params)
      .then(data => {
        const suppliers: Supplier[] = (data?.records as Supplier[]) ?? [];
        let selectedSupplier = this.state.selectedSupplier;
        if (!selectedSupplier) {
          selectedSupplier = suppliers[0];
        }

        this.setState({
          suppliers: suppliers,
          selectedSupplier: selectedSupplier,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
      })
      .then(() => {
        this.setState({loading: false});
      });
  }

  print() {
    MessageService.sendMessage('print-purchase-orders-dialog');
  }

  printAll() {
    this.print();
  }

  async savePurchaseOrder(print?: boolean): Promise<void> {
    this.setState({loading: true});

    const supplier = this.state.selectedSupplier;
    const purchaseOrderItems = this.state.purchaseOrderItems;

    const newPo: PurchaseOrder = {
      supplier_id: supplier?.id as string,
      supplier: supplier,
      items: purchaseOrderItems,
      related_order_ids: this.props.order ? [this.props.order.id as string] : undefined,
      factory_id: this.state.factory as string,
      stage: this.state.purchaseOrderStageSelected as PurchaseOrderStage,
      sent_at: this.state.orderedOn,
      eta: this.state.eta,
      delivered_at: undefined,
      updated_at: new Date(),
    };

    return this.purchaseOrdersService
      ?.createPurchaseOrder(newPo)
      .then(data => {
        this.toastService?.showSuccess(this.props.toast, `Purchase Order ${data?.id} created successfully.`);
        // get new purchase order aggregate
        const filters: string[] = [
          JSON.stringify({
            field: 'id',
            value: data.id!,
          }),
        ];
        const params: QueryParameter = {
          aggregate: true,
          filters: filters,
        };
        this.purchaseOrdersService?.getPurchaseOrders(params).then(data => {
          const records = data.records as PurchaseOrder[];
          this.setState({
            loading: false,
            savedPurchaseOrder: records[0],
          });
          MessageService.sendMessage(messages.purchaseOrderUpdated);
          if (print) {
            this.print();
          }
          this.closeDialog();
        });
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, Purchase Order  created failed, please try again.');
        console.error('error: ' + error);
      });
  }

  renderPurchasingOrderDialogFooter() {
    return (
      <div className={'p-d-flex p-my-4 p-justify-end'}>
        <Button label="Cancel" className={'p-mr-2 p-button-text'} onClick={() => this.closeDialog()} />
        {!this.state.onlyPrint && (
          <Button
            label="Save"
            className={'p-mr-2'}
            onClick={() => {
              this.savePurchaseOrder();
            }}
            autoFocus
          />
        )}

        <Button
          label={'Save & Print'}
          className={'p-mr-2'}
          onClick={() => {
            this.state.onlyPrint ? this.printAll() : this.savePurchaseOrder(true);
          }}
        />
      </div>
    );
  }

  onSupplierInputChange(e: DropdownChangeParams) {
    this.setState({
      selectedSupplier: e.value,
    });
  }
  stageInputChange(e: DropdownChangeParams) {
    this.setState({purchaseOrderStageSelected: e.target.value});
  }
  handleOrderedOnDateChange(e: CalendarChangeParams) {
    this.setState({orderedOn: e.target.value as Date});
  }
  handleEtaDateChange(e: CalendarChangeParams) {
    this.setState({eta: e.target.value as Date});
  }

  setPurchaseOrderItems(purchaseOrderItems: PurchaseOrderItem[]) {
    this.setState({purchaseOrderItems: purchaseOrderItems});
  }

  /**
   * Set default purchase order items from selected inventory items in parent component.
   */
  setDefaultPurchaseOrderItems() {
    if (this.props.inventoryItems?.length) {
      const poItems: PurchaseOrderItem[] = [];
      let lastTemporaryId = 0;
      for (const inventoryItem of this.props.inventoryItems) {
        for (const supplyItem of inventoryItem.supply_items!) {
          const detail = `${supplyItem.sku ?? ''}: ${supplyItem.name ?? ''}`;
          const poItem: PurchaseOrderItem = {
            id: --lastTemporaryId,
            linked_bom_sum: 0,
            purchase_order_id: '',
            supply_item_id: supplyItem.id ?? 0,
            quantity: 1,
            detail: detail,
            inventory_item_id: inventoryItem.id!,
            order_unit: supplyItem.reorder_unit,
            unit_price: supplyItem.unit_price ?? 0,
            package_size: supplyItem.package_size_label ?? '',
            delivered_qty: 0,
          };
          poItems.push(poItem);
        }
      }
      const selectedSupplier = this.state.suppliers.find(
        supplier => supplier.id === this.props.inventoryItems?.[0].supply_items![0].supplier_id
      );
      this.setState({
        purchaseOrderItems: poItems,
        selectedSupplier: selectedSupplier,
      });
    }
  }

  private selectVendor(supplierId: string) {
    if (supplierId) {
      const supplier = this.state.suppliers.find(supplier => supplier.id === supplierId);
      this.setState({selectedSupplier: supplier});
    }
  }

  closeDialog() {
    this.setState({
      selectedSupplier: undefined,
      purchaseOrderStageSelected: 'Draft',
      orderedOn: undefined,
      eta: undefined,
      purchaseOrderItems: [],
    });
    this.props.closeDialog();
  }

  onShow() {
    this.loadSuppliers();
    this.setDefaultPurchaseOrderItems();
  }

  render() {
    const {purchaseOrderStageSelected, purchaseOrderItems} = {
      ...this.state,
    };
    const {order} = this.props;

    return (
      <>
        <Dialog
          header={order ? `New Purchase Order for ${order?.id} ${order?.reference}` : 'New Purchase Order'}
          footer={!this.state.loading ? this.renderPurchasingOrderDialogFooter() : <></>}
          visible={this.props.showPurchaseOrderDialog}
          style={{width: '95%', height: '95%'}}
          modal
          onHide={this.closeDialog}
          onShow={this.onShow}
          closable={false}
        >
          {this.state.loading ? (
            <div className="p-d-flex p-ai-center w-100 h-100">
              <ProgressSpinner />
            </div>
          ) : (
            <>
              <div id="purchase-order-add-new">
                <div className="p-fluid p-grid p-flex-row">
                  <div className="p-d-flex p-ai-center p-col-12 p-md-4 p-lg-4">
                    <label className="p-mr-5 p-d-flex p-md-2 p-lg-2">vendor</label>
                    <div className="p-d-flex p-col-12 p-md-8 p-lg-8">
                      <Dropdown
                        placeholder="Select vendor"
                        filterBy="company_name"
                        filter
                        className={'w-100'}
                        optionLabel="company_name"
                        value={this.state.selectedSupplier}
                        options={this.state.suppliers}
                        name="vendor"
                        id="vendor"
                        disabled={purchaseOrderItems.length > 0}
                        onChange={this.onSupplierInputChange}
                      />
                    </div>
                  </div>
                  <div className="p-d-flex p-ai-center p-col-12 p-md-4 p-lg-4">
                    <label className="p-mr-5 p-d-flex p-md-2 p-lg-2">stage</label>
                    <div className="p-d-flex p-col-12 p-md-8 p-lg-8">
                      <Dropdown
                        placeholder="Select stage"
                        className={'w-100'}
                        optionLabel="label"
                        optionValue="value"
                        value={this.state.purchaseOrderStageSelected}
                        options={purchaseOrderStagesNewOptions}
                        name="stage"
                        id="stage"
                        onChange={e => this.stageInputChange(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-fluid p-grid p-flex-row">
                  <div className="p-d-flex p-ai-center p-col-12 p-md-4 p-lg-4">
                    <label className="p-mr-5 p-d-flex p-md-2 p-lg-2">ordered on</label>
                    <div className="p-d-flex p-col-12 p-md-8 p-lg-8">
                      <Calendar
                        value={this.state.orderedOn}
                        dateFormat={formats.calendarInputDate}
                        name="orderedOn"
                        className="w-100"
                        onChange={e => this.handleOrderedOnDateChange(e)}
                        disabled={
                          !(purchaseOrderStageSelected === 'Ordered' || purchaseOrderStageSelected === 'Eta Confirmed')
                        }
                      />
                    </div>
                  </div>
                  <div className="p-d-flex p-ai-center p-col-12 p-md-4 p-lg-4">
                    <label htmlFor="eta" className="p-mr-5 p-d-flex p-md-2 p-lg-2">
                      eta
                    </label>
                    <div className="p-d-flex p-col-12 p-md-8 p-lg-8">
                      <Calendar
                        value={this.state.eta}
                        dateFormat={formats.calendarInputDate}
                        name="eta"
                        className="w-100"
                        onChange={e => this.handleEtaDateChange(e)}
                        disabled={
                          !(
                            purchaseOrderStageSelected === 'Eta Confirmed' ||
                            purchaseOrderStageSelected === 'Delayed' ||
                            purchaseOrderStageSelected === 'Delivered'
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-fluid p-grid p-flex-row">
                <Divider className="p-mt-2 p-mb-4"></Divider>
              </div>
              <div className="p-fluid p-grid p-flex-row">
                <div className="w-100">
                  <PurchaseOrderItemListComponent
                    vendor={this.state.selectedSupplier}
                    selectVendor={this.selectVendor}
                    setPurchaseOrderItems={this.setPurchaseOrderItems}
                    order={this.props.order}
                    purchaseOrderItems={this.state.purchaseOrderItems}
                  />
                </div>
              </div>
            </>
          )}
        </Dialog>
        <PurchaseOrderListPrint
          id="print-purchase-orders-dialog"
          purchaserOrders={this.state.savedPurchaseOrder ? [this.state.savedPurchaseOrder] : []}
        />
      </>
    );
  }
}

export default PurchaseOrderAddDialog;
