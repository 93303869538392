import React from 'react';
import {Order} from 'two-core';
import {Tooltip} from 'primereact/tooltip';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faIndustryWindows} from '@fortawesome/pro-regular-svg-icons';

interface Props {
  order: Order;
  currentFactoryId: string;
}
export const OrderStageComponent = ({order, currentFactoryId}: Props) => {
  const getStageClass = (stage?: string) => {
    return `stage-badge stage-${stage?.toLowerCase().replaceAll(' ', '-')}`;
  };
  const productionStage = order.factory_order?.production_stage;
  const factoryChain = order.factory_order?.factory_chain;
  if (!factoryChain || !Object.keys(factoryChain).length) {
    return <span className={getStageClass(productionStage)}>{productionStage}</span>;
  }

  const factoryChainNodes = [];
  for (const [factoryId, chainItem] of Object.entries(factoryChain)) {
    factoryChainNodes.push(
      <div className="p-mb-1">
        {chainItem.factory_name}: <span className={getStageClass(chainItem.stage)}>{chainItem.stage}</span>
      </div>
    );
  }

  const elementId = order.id + '_stage_label';
  const currentFactoryProductionStage = factoryChain[currentFactoryId]?.stage;
  return (
    <>
      <span className={getStageClass(currentFactoryProductionStage)} id={elementId}>
        <span>{currentFactoryProductionStage}</span>
        <FontAwesomeIcon className="p-ml-1" icon={faIndustryWindows} />
      </span>
      <Tooltip target={`#${elementId}`}>{factoryChainNodes}</Tooltip>
    </>
  );
};
