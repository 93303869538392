import React from 'react';

import {Subscription} from 'rxjs';
import {AppContext, MessageService} from 'two-app-ui';
import {MapOf, Order, PurchaseOrder, QueryParameter} from 'two-core';
import OrdersService from '../../services/OrdersService';
import POPrint from './POPrint';

interface Props {
  id: string;
  purchaserOrders: PurchaseOrder[];
}

interface State {
  orders: MapOf<Order>;
}

class PurchaseOrderListPrint extends React.Component<Props, State> {
  static contextType = AppContext;

  subscription: Subscription = new Subscription();
  printFrame: React.RefObject<HTMLIFrameElement>;
  ordersService: OrdersService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      orders: {},
    };
    this.printFrame = React.createRef();
  }

  async componentDidMount() {
    this.ordersService = this.context.ordersService;

    this.subscription = MessageService.getMessage().subscribe(async message => {
      if (message === this.props.id) {
        await this.loadOrders();
        this.print();
      }
    });
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  async loadOrders() {
    const filters: string[] = [];
    const orderIds: string[] = [];
    this.props.purchaserOrders.map(po => {
      const relatedOrderIds = po.related_order_ids?.filter(o => o !== null);
      if (relatedOrderIds) {
        orderIds.push(...relatedOrderIds);
      }
    });

    filters.push(
      JSON.stringify({
        field: 'factory_order.factory_id',
        value: localStorage.getItem('current factory'),
      })
    );

    filters.push(
      JSON.stringify({
        field: 'id',
        value: orderIds,
        condition: 'in',
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };

    await this.ordersService?.getOrders(params).then(data => {
      const orders: Order[] = (data.records as Order[]) ?? [];
      const mappedOrders: MapOf<Order> = {};
      for (const order of orders) {
        if (order.id) {
          mappedOrders[order.id] = order;
        }
      }

      this.setState({
        orders: mappedOrders,
      });
    });
  }

  print() {
    const content = document.getElementById('print-area-' + this.props.id);
    const pri = this.printFrame.current;
    if (!pri || !pri.contentDocument || !pri.contentWindow) {
      throw new Error('Dom error');
    }

    pri.contentDocument.open();
    pri.contentDocument.write(content?.innerHTML ?? '');
    pri.contentDocument.close();

    const headElements = document.querySelectorAll("style, link[rel='stylesheet']");

    for (let i = 0, headElementsLen = headElements.length; i < headElementsLen; ++i) {
      const node = headElements[i];
      if (node.tagName === 'STYLE' || node.tagName === 'LINK') {
        const newHeadEl = pri.contentDocument.createElement('style');
        const sheet = (node as HTMLStyleElement).sheet as CSSStyleSheet;

        if (sheet) {
          let styleCSS = '';

          for (let j = 0, cssLen = sheet.cssRules.length; j < cssLen; ++j) {
            if (typeof sheet.cssRules[j].cssText === 'string') {
              styleCSS += `${sheet.cssRules[j].cssText} \r\n`;
            }
          }
          newHeadEl.setAttribute('id', `react - to - print - ${i} `);
          newHeadEl.appendChild(pri.contentDocument.createTextNode(styleCSS));
          if (styleCSS.indexOf('html, body {') < 0) {
            pri.contentDocument.head.appendChild(newHeadEl);
          }
        }
      }
    }

    pri.focus();
    pri.contentWindow.print();
  }

  render() {
    const sheets: JSX.Element[] = [];
    const puchaserOrdersToPrint = this.props.purchaserOrders ?? [];

    puchaserOrdersToPrint.map((data: PurchaseOrder) => {
      sheets.push(<POPrint data={data} orders={this.state.orders} />);
    });

    return (
      <React.Fragment key={this.props.id}>
        <div id={'print-area-' + this.props.id} className="print-area">
          {sheets?.map((sheet: JSX.Element) => {
            return sheet;
          })}
        </div>
        <iframe
          title="print"
          id={'ifmcontentstoprint-' + this.props.id}
          className="ifmcontentstoprint"
          ref={this.printFrame}
        ></iframe>
      </React.Fragment>
    );
  }
}
export default PurchaseOrderListPrint;
