import {
  FactoryChainItem,
  FactoryOrder,
  FactoryOrderPatch,
  MapOf,
  ProductionStageM2cAuShades,
  TleContentStageTransition,
} from 'two-core';
import {getCurrentUserId} from './UserUtil';

/**
 * Get patch for factory_order to update production_stage and factory_chain.stage
 * @param factoryOrder
 * @param newStage
 * @returns FactoryOrderPatch
 */
export const getNewProductionStagesPatch = (
  factoryOrder: FactoryOrder,
  newStage?: ProductionStageM2cAuShades,
  newSecondaryFactoriesStage?: ProductionStageM2cAuShades
): Pick<FactoryOrderPatch, 'factory_chain' | 'production_stage' | 'tles_success'> => {
  const factoryChain = factoryOrder.factory_chain;
  const productionStage = factoryOrder.production_stage;
  const factoryOrderPatch: FactoryOrderPatch = {};
  if (factoryChain) {
    const currentFactoryId = localStorage.getItem('current factory') ?? '';
    //set factory chain stages
    if (newStage) {
      const updatedFactoryChainItem = {...factoryChain[currentFactoryId]!, stage: newStage};
      factoryOrderPatch.factory_chain = {
        ...factoryChain,
        [currentFactoryId]: updatedFactoryChainItem,
      };
    }
    if (newSecondaryFactoriesStage) {
      const newFactoryChain: MapOf<FactoryChainItem> = {};
      for (const [factoryId, chainItem] of Object.entries(factoryOrderPatch.factory_chain ?? factoryChain)) {
        if (factoryId === currentFactoryId) {
          newFactoryChain[factoryId] = {
            ...chainItem,
          };
        } else {
          newFactoryChain[factoryId] = {...chainItem, stage: newSecondaryFactoriesStage};
        }
      }
      factoryOrderPatch.factory_chain = newFactoryChain;
    }

    //set production_stage based on factory_chain stages
    const factoryChainPatchStages = Object.values(factoryOrderPatch.factory_chain!).map(chainItem => chainItem.stage);
    if (productionStage !== newStage) {
      if (newStage === 'Done') {
        //change production_stage on factory_order only if all factories are in stage 'Done'
        if (factoryChainPatchStages.every(chainStage => chainStage === 'Done')) {
          factoryOrderPatch.production_stage = 'Done';
        }
      } else if (newStage === 'Post Fix Return') {
        //change production_stage on factory_order only if all factories are in stage 'Done' or 'Post Fix Return'
        if (factoryChainPatchStages.every(chainStage => chainStage === 'Done' || chainStage === 'Post Fix Return')) {
          factoryOrderPatch.production_stage = 'Post Fix Return';
        }
      } else if (newStage === 'Between Factories') {
        //change production_stage on factory_order only if all factories are in stage 'Done', 'Post Fix Return' or 'Between Factories'
        if (
          factoryChainPatchStages.every(
            chainStage =>
              chainStage === 'Done' || chainStage === 'Post Fix Return' || chainStage === 'Between Factories'
          )
        ) {
          factoryOrderPatch.production_stage = 'Between Factories';
        }
      } else {
        //change production_stage to most advanced factory_chain.stage
        let mostAdvancedStage: ProductionStageM2cAuShades | undefined;
        if (factoryChainPatchStages.includes('In Production')) {
          mostAdvancedStage = 'In Production';
        } else if (factoryChainPatchStages.includes('Sent To Floor')) {
          mostAdvancedStage = 'Sent To Floor';
        } else if (factoryChainPatchStages.includes('Ready')) {
          mostAdvancedStage = 'Ready';
        } else if (factoryChainPatchStages.includes('Waiting 4 Material')) {
          mostAdvancedStage = 'Waiting 4 Material';
        } else if (factoryChainPatchStages.includes('Waiting For Return')) {
          mostAdvancedStage = 'Waiting For Return';
        }
        if (mostAdvancedStage && mostAdvancedStage !== productionStage) {
          factoryOrderPatch.production_stage = mostAdvancedStage;
        }
      }
    }
  } else {
    factoryOrderPatch.production_stage = newStage;
  }

  //create tles
  const userId = getCurrentUserId();
  factoryOrderPatch.tles_success = [];
  if (factoryOrderPatch.factory_chain) {
    for (const [factoryId, chainItem] of Object.entries(factoryOrderPatch.factory_chain)) {
      const oldStage = factoryOrder.factory_chain![factoryId]!.stage;
      if (oldStage !== chainItem.stage) {
        factoryOrderPatch.tles_success.push({
          event_type: 'production_stage_transition',
          entity_id: factoryOrder.id!,
          entity_type: 'order',
          recorded_at: new Date(),
          recorded_by: userId,
          content: {
            old_stage: oldStage,
            new_stage: chainItem.stage,
            message: `Changed in ${chainItem.factory_name}.`,
          } as TleContentStageTransition,
        });
      }
    }
    if (factoryOrderPatch.production_stage && factoryOrder.production_stage !== factoryOrderPatch.production_stage) {
      factoryOrderPatch.tles_success.push({
        event_type: 'production_stage_transition',
        entity_id: factoryOrder.id!,
        entity_type: 'order',
        recorded_at: new Date(),
        recorded_by: userId,
        content: {
          old_stage: factoryOrder.production_stage,
          new_stage: factoryOrderPatch.production_stage,
          message: 'Changed overall stage.',
        } as TleContentStageTransition,
      });
    }
  } else if (
    factoryOrderPatch.production_stage &&
    factoryOrder.production_stage !== factoryOrderPatch.production_stage
  ) {
    factoryOrderPatch.tles_success.push({
      event_type: 'production_stage_transition',
      entity_id: factoryOrder.id!,
      entity_type: 'order',
      recorded_at: new Date(),
      recorded_by: userId,
      content: {
        old_stage: factoryOrder.production_stage,
        new_stage: factoryOrderPatch.production_stage,
      } as TleContentStageTransition,
    });
  }
  return factoryOrderPatch;
};
