import React from 'react';
import {AppContext, ToastService, TwoDialog} from 'two-app-ui';
import {MapOf, PurchaseOrderItem, Supplier, SupplyItem} from 'two-core';
import InventoryService from '../../services/InventoryService';
import SupplyItemListComponent, {DraggableIdData} from '../SupplyItems/SupplyItemListComponent';
import {DragDropContext, DropResult} from 'react-beautiful-dnd';
import {Divider} from 'primereact/divider';
import {Toast} from 'primereact/toast';

interface Props {
  supplier?: Supplier;
  showDialog: boolean;
  onHide: () => void;
  addPurchaseOrderItems: (purchaseOrderItems: PurchaseOrderItem[]) => void;
  assignedPurchaseOrderItems?: PurchaseOrderItem[];
  toast: React.RefObject<Toast>;
}

interface State {
  loading: boolean;
  factoryId: string;
  newlyAssignedSupplyItems: SupplyItem[];
  excludedSupplyItemsIds: number[];
  editableAssignedValuesMap: MapOf<{
    unit_price: number;
    quantity: number;
  }>;
}

class PurchaseOrderItemAddDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  inventoryService: InventoryService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      factoryId: localStorage.getItem('current factory') ?? '',
      newlyAssignedSupplyItems: [],
      excludedSupplyItemsIds: [],
      editableAssignedValuesMap: {},
    };

    this.onHide = this.onHide.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.updateAssignedSupplyItem = this.updateAssignedSupplyItem.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onShow = this.onShow.bind(this);
    this.setEditableAssignedValuesMap = this.setEditableAssignedValuesMap.bind(this);
  }

  componentDidMount() {
    this.inventoryService = this.context.inventoryService;
    this.toastService = this.context.toastService;
  }

  onHide() {
    this.props.onHide();
  }

  onShow() {
    const excludedSupplyItemsIds: number[] =
      this.props.assignedPurchaseOrderItems?.map(poItem => poItem.supply_item_id) ?? [];
    this.setState({
      loading: false,
      factoryId: localStorage.getItem('current factory') ?? '',
      newlyAssignedSupplyItems: [],
      editableAssignedValuesMap: {},
      excludedSupplyItemsIds: excludedSupplyItemsIds,
    });
  }

  onAdd() {
    const {newlyAssignedSupplyItems, editableAssignedValuesMap} = this.state;
    const {addPurchaseOrderItems, assignedPurchaseOrderItems} = this.props;
    let lastTemporaryId = Math.min(...(assignedPurchaseOrderItems?.map(poItem => poItem.id!) ?? []), 0);
    const currentPurchaseOrderItems: PurchaseOrderItem[] = [];
    for (const supplyItem of newlyAssignedSupplyItems) {
      const detail = `${supplyItem.inventory_item!.sku}: ${supplyItem.inventory_item!.name}`;
      const poItem: PurchaseOrderItem = {
        id: --lastTemporaryId, //temporary id
        linked_bom_sum: 0,
        purchase_order_id: '',
        supply_item_id: supplyItem.id!,
        detail: detail,
        inventory_item_id: supplyItem.inventory_item_id,
        order_unit: supplyItem.reorder_unit,
        unit_price: editableAssignedValuesMap[supplyItem.id!].unit_price,
        package_size: supplyItem.package_size_label ?? '',
        quantity: editableAssignedValuesMap[supplyItem.id!].quantity,
        delivered_qty: 0,
        supply_item: supplyItem,
        inventory_item: supplyItem.inventory_item,
      };
      currentPurchaseOrderItems.push(poItem);
    }
    addPurchaseOrderItems(currentPurchaseOrderItems);
    this.onHide();
  }

  onDragEnd(result: DropResult) {
    const {source, destination} = result;
    if (source?.droppableId === destination?.droppableId) {
      return;
    }
    const sourceId = source?.droppableId;
    const draggableIdData = JSON.parse(result.draggableId) as DraggableIdData;
    const supplyItem = draggableIdData.data as SupplyItem;
    if (sourceId === 'purchase-order-items') {
      const excludedSupplyItemsIds = this.state.excludedSupplyItemsIds;
      excludedSupplyItemsIds.push(supplyItem.id!);
      this.setState({
        excludedSupplyItemsIds: excludedSupplyItemsIds,
        newlyAssignedSupplyItems: [...this.state.newlyAssignedSupplyItems, supplyItem],
        editableAssignedValuesMap: {
          ...this.state.editableAssignedValuesMap,
          [supplyItem.id!]: {
            quantity: 1,
            unit_price: supplyItem.unit_price,
          },
        },
      });
    } else {
      const reducedAssignedSupplyItems: SupplyItem[] = this.state.newlyAssignedSupplyItems.filter(
        assignedSupplyItem => assignedSupplyItem.id !== supplyItem.id
      );
      const excludedSupplyItemsIds = this.state.excludedSupplyItemsIds.filter(id => id !== supplyItem.id);
      this.setState({
        excludedSupplyItemsIds: excludedSupplyItemsIds,
        newlyAssignedSupplyItems: reducedAssignedSupplyItems,
      });
    }
  }

  updateAssignedSupplyItem(supplyItem: SupplyItem) {
    const assignedSupplyItems = this.state.newlyAssignedSupplyItems.map(assignedSupplyItem => {
      return assignedSupplyItem.id === supplyItem.id ? supplyItem : assignedSupplyItem;
    });
    this.setState({
      newlyAssignedSupplyItems: assignedSupplyItems,
    });
  }
  setEditableAssignedValuesMap(
    editableAssignedValuesMap: MapOf<{
      unit_price: number;
      quantity: number;
    }>
  ) {
    this.setState({editableAssignedValuesMap: editableAssignedValuesMap});
  }

  render() {
    const {toast} = this.props;
    const {newlyAssignedSupplyItems, excludedSupplyItemsIds, editableAssignedValuesMap} = this.state;
    return (
      <TwoDialog
        headerTitle={'Add Purchase Order Item'}
        showDialog={this.props.showDialog}
        visible={this.props.showDialog}
        width={90}
        onHide={this.onHide}
        onSave={this.onAdd}
        onShow={this.onShow}
        loading={this.state.loading}
        saveButtonTitle="Add"
      >
        <div className="purchase-order-item-add w-100">
          <div className="p-grid">
            <div className="p-col-12">
              <span>{'Search for the item(s) you want to add to your Purchase order here.'}</span>
            </div>
          </div>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div className="p-grid p-mb-4 p-mt-2">
              <div className="p-col-12">
                <SupplyItemListComponent
                  supplier={this.props.supplier}
                  key="key-purchase-order-items"
                  id="purchase-order-items"
                  droppableId={'purchase-order-items'}
                  excludedSupplyItemsIds={excludedSupplyItemsIds}
                  quantityHidden={true}
                  toast={toast}
                />
              </div>
            </div>
            <Divider />

            <div className="p-grid">
              <div className="p-col-12">
                <span>{'Drag and drop the chosen items into the table below.'}</span>
              </div>
              <div className="p-col-12">
                <SupplyItemListComponent
                  supplier={this.props.supplier}
                  key="purchase-order-items-assigned"
                  id="purchase-order-items-assigned"
                  editableTable={true}
                  updateSupplyItem={this.updateAssignedSupplyItem}
                  editableAssignedValuesMap={editableAssignedValuesMap}
                  setEditableAssignedValuesMap={this.setEditableAssignedValuesMap}
                  droppableId={'purchase-order-items-assigned'}
                  assignedSupplyItems={newlyAssignedSupplyItems}
                  paginator={false}
                  filtersHidden={true}
                  toast={toast}
                  customEmptyMessage="Drag & Drop the items you want to add here."
                />
              </div>
            </div>
          </DragDropContext>
        </div>
      </TwoDialog>
    );
  }
}

export default PurchaseOrderItemAddDialog;
