import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {OtherMenuOptions, PrintMenuOptions, StageMenuOptions} from '../Order/Constants/constants';
import OrderListComponent from '../Orders/OrderListComponent';

class Waiting4MaterialOrderListComponent extends React.Component<RouteComponentProps> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <OrderListComponent
        showFilterBox={true}
        orderStages={['Waiting 4 Material']}
        orderTypes={['Standard']}
        printMenuOptions={Object.values(PrintMenuOptions)}
        stageMenuOptions={Object.values(StageMenuOptions).filter(v => v !== StageMenuOptions.PostFixReturn)}
        otherMenuOptions={Object.values(OtherMenuOptions)}
        history={this.props.history}
      />
    );
  }
}

export default withRouter(Waiting4MaterialOrderListComponent);
