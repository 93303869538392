import React from 'react';
import {
  AppColumnMenuBodyTemplate,
  AppContext,
  AppMenuItem,
  AppMenuItemTemplate,
  MessageService,
  ToastService,
  TwoDataTable,
  TwoDialog,
} from 'two-app-ui';
import {
  DropdownOption,
  MapOf,
  PurchaseOrder,
  PurchaseOrderItem,
  QueryParameter,
  SupplyItem,
  SupplyItemAggregate,
} from 'two-core';
import {Column} from 'primereact/column';
import {MenuItemOptions} from 'primereact/menuitem';
import {Toast} from 'primereact/toast';
import PurchaseOrdersService from '../../services/PurchaseOrdersService';
import PurchaseOrderItemAddDialog from '../PurchaseOrderItems/PurchaseOrderItemAddDialog';
import {NavLink} from 'react-router-dom';
import {DataTableSelectionModeType} from 'primereact/datatable';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {InputNumber} from 'primereact/inputnumber';
import SupplyItemsService from '../../services/SupplyItemsService';
import {faFile, faPlusCircle, faTimes} from '@fortawesome/pro-regular-svg-icons';
import {messages} from '../../config/messages';
import {Button} from 'primereact/button';

interface Props {
  purchaseOrder: PurchaseOrder;
  editable?: boolean;
  toast: React.RefObject<Toast>;
  poItems: PurchaseOrderItem[];
  itemsTableId: string;
  setFormPoItems?: (formPoItems: PurchaseOrderItem[]) => void;
  onNewPoCreated?: () => void;
}

interface State {
  loading: boolean;
  selectedPoItems: PurchaseOrderItem[];
  supplyItemsMap: MapOf<SupplyItem[]>;
  showAddDialog: boolean;
  showEditDialog: boolean;
  showCreateNewPoDialog: boolean;
  poOfTheSameVendor: DropdownOption[];
  newPoSelectedId: string;
}

class PurchaseOrderItems extends React.Component<Props, State> {
  static contextType = AppContext;
  toastService?: ToastService;
  purchaseOrdersService?: PurchaseOrdersService;
  supplyItemsService?: SupplyItemsService;
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      selectedPoItems: [],
      supplyItemsMap: {},
      showAddDialog: false,
      showEditDialog: false,
      showCreateNewPoDialog: false,
      poOfTheSameVendor: [],
      newPoSelectedId: 'new',
    };

    this.initMenuItems = this.initMenuItems.bind(this);
    this.hideEditDialog = this.hideEditDialog.bind(this);
    this.onHideAddDialog = this.onHideAddDialog.bind(this);
    this.itemNameTemplate = this.itemNameTemplate.bind(this);
    this.descriptionTemplate = this.descriptionTemplate.bind(this);
    this.quantityTemplate = this.quantityTemplate.bind(this);
    this.onSupplyItemChanged = this.onSupplyItemChanged.bind(this);
    this.onQuantityChanged = this.onQuantityChanged.bind(this);
    this.addPoItems = this.addPoItems.bind(this);
    this.createNewPo = this.createNewPo.bind(this);
    this.onPoSelection = this.onPoSelection.bind(this);
    this.onNewPoSelectionChange = this.onNewPoSelectionChange.bind(this);
  }

  componentDidMount() {
    this.toastService = this.context.toastService;
    this.purchaseOrdersService = this.context.purchaseOrdersService;
    this.supplyItemsService = this.context.supplyItemsService;

    this.loadSupplyItems();
  }

  loadSupplyItems(): Promise<void> | undefined {
    const {purchaseOrder, toast, editable} = this.props;
    if (!editable) {
      return;
    }
    this.setState({loading: true});
    const filters: string[] = [
      JSON.stringify({
        field: 'supplier_id',
        value: purchaseOrder.supplier_id,
      }),
    ];
    const aggregate: SupplyItemAggregate[] = ['inventory_items'];
    const params: QueryParameter = {
      filters: filters,
      aggregate: aggregate,
    };
    return this.supplyItemsService
      ?.getSupplyItems(params)
      .then(data => {
        const supplyItems = (data.records as SupplyItem[]) ?? [];
        const supplyItemsMap: MapOf<SupplyItem[]> = {};
        for (const supplyItem of supplyItems) {
          if (supplyItemsMap[supplyItem.inventory_item_id]) {
            supplyItemsMap[supplyItem.inventory_item_id].push(supplyItem);
          } else {
            supplyItemsMap[supplyItem.inventory_item_id] = [supplyItem];
          }
        }
        this.setState({
          supplyItemsMap: supplyItemsMap,
        });
      })
      .catch(() => {
        this.toastService?.showError(toast, 'Sorry, supply items load failed, please try again.');
      })
      .finally(() => {
        this.setState({loading: false});
      });
  }

  setChangeSelectedItems(items: PurchaseOrderItem[]) {
    this.setState({selectedPoItems: items});
  }

  initMenuItems(isHeaderMenu: boolean): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];

    if (this.props.purchaseOrder.stage === 'Draft') {
      const selectedItems = this.state.selectedPoItems;
      const selectedItemsCount = selectedItems.length;

      if (isHeaderMenu) {
        menuItems.push({
          label: 'Add Items',
          faIcon: faPlusCircle,
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => this.showAddDialog(),
        });
      }

      if (selectedItemsCount > 0) {
        menuItems.push({
          label: 'New PO',
          faIcon: faFile,
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.createNewPo();
          },
        });
        menuItems.push({
          label: 'Remove',
          faIcon: faTimes,
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.removePoItems();
          },
        });
      }
    }

    return menuItems;
  }

  createNewPo() {
    const {purchaseOrder} = this.props;
    const params: QueryParameter = {
      filters: [
        JSON.stringify({
          field: 'supplier_id',
          value: purchaseOrder.supplier_id,
        }),
        JSON.stringify({
          field: 'stage',
          value: 'Draft',
        }),
        JSON.stringify({
          field: 'id',
          value: purchaseOrder.id,
          condition: '<>',
        }),
      ],
    };

    this.purchaseOrdersService?.getPurchaseOrders(params).then(openPos => {
      if (openPos && openPos.records && (openPos.records as PurchaseOrder[]).length > 0) {
        const poOfTheSameVendor = openPos.records as PurchaseOrder[];

        const options = [{label: 'Create New', value: 'new'}];
        poOfTheSameVendor.map(po => {
          options.push({label: po.id!, value: po.id!});
        });

        this.setState({
          poOfTheSameVendor: options,
          showCreateNewPoDialog: true,
        });
      } else {
        // if there are no draft pos, just create one without opening dialog
        this.setState({newPoSelectedId: 'new'}, () => this.onPoSelection());
      }
    });
  }

  showAddDialog() {
    this.setState({showAddDialog: true});
  }

  onHideAddDialog() {
    this.setState({showAddDialog: false});
  }

  showEditDialog() {
    this.setState({showEditDialog: true});
  }

  hideEditDialog() {
    this.setState({showEditDialog: false});
  }

  removePoItems() {
    const {poItems, setFormPoItems} = this.props;
    const {selectedPoItems} = this.state;
    const selectedPoItemsIds = selectedPoItems.map(poItem => poItem.id!);

    const formPoItems = poItems.filter(poItem => !selectedPoItemsIds.includes(poItem.id!));
    setFormPoItems?.(formPoItems);
    this.setState({selectedPoItems: []});
  }

  addPoItems(newPurchaseOrderItems: PurchaseOrderItem[]) {
    const {setFormPoItems, poItems} = this.props;
    setFormPoItems?.([...poItems, ...newPurchaseOrderItems]);
    this.setState({selectedPoItems: []});
  }

  onSupplyItemChanged(supplyItem: SupplyItem, poItem: PurchaseOrderItem) {
    const {poItems, setFormPoItems} = this.props;
    const formPoItems: PurchaseOrderItem[] = poItems.map(poItemRecord => {
      if (poItem.id === poItemRecord.id) {
        return {
          ...poItemRecord,
          supply_item_id: supplyItem.id!,
          supply_item: supplyItem,
          quantity: (poItemRecord.linked_bom_sum ?? 0) / supplyItem.reorder_qty_in_uom,
        };
      } else {
        return {...poItemRecord};
      }
    });
    setFormPoItems?.(formPoItems);
  }

  onQuantityChanged(quantity: number, poItem: PurchaseOrderItem) {
    const {poItems, setFormPoItems} = this.props;
    const formPoItems: PurchaseOrderItem[] = poItems.map(poItemRecord => {
      if (poItem.id === poItemRecord.id) {
        return {
          ...poItemRecord,
          quantity: quantity,
        };
      } else {
        return {...poItemRecord};
      }
    });
    setFormPoItems?.(formPoItems);
  }

  itemNameTemplate(poItem: PurchaseOrderItem) {
    const {editable} = this.props;
    const {selectedPoItems} = this.state;
    const rowBody = <NavLink to={'/inventory-item/' + poItem.inventory_item_id}>{poItem.inventory_item!.name}</NavLink>;
    if (!editable || !selectedPoItems.length) {
      return rowBody;
    }
    return (
      <AppColumnMenuBodyTemplate
        isDynamicMenuItems={true}
        initMenuItems={() => this.initMenuItems(false)}
        rowItemIdentifier={poItem.id?.toString() ?? ''}
        selectedItems={this.state.selectedPoItems}
      >
        {rowBody}
      </AppColumnMenuBodyTemplate>
    );
  }

  descriptionTemplate(poItem: PurchaseOrderItem) {
    const {editable, purchaseOrder} = this.props;
    const {supplyItemsMap} = this.state;
    if (editable && purchaseOrder.stage !== 'Delivered') {
      const options = supplyItemsMap?.[poItem.inventory_item_id] ?? [];
      const toolTip = this.supplyItemDescriptionTemplate(poItem.supply_item);
      return (
        <Dropdown
          className="w-100"
          optionLabel="id"
          dataKey="id"
          name="description"
          value={poItem.supply_item}
          options={options}
          itemTemplate={this.supplyItemDescriptionTemplate}
          valueTemplate={this.supplyItemDescriptionTemplate}
          onChange={e => this.onSupplyItemChanged(e.value, poItem)}
          placeholder="Select a Priority"
          tooltip={toolTip}
        />
      );
    }
    return this.supplyItemDescriptionTemplate(poItem.supply_item!);
  }

  supplyItemDescriptionTemplate(supplyItem?: SupplyItem) {
    if (supplyItem?.name) {
      return `${supplyItem.name} | ${supplyItem.package_size_label}`;
    }
    return 'empty';
  }

  quantityTemplate(poItem: PurchaseOrderItem) {
    const {editable, purchaseOrder} = this.props;
    if (editable && purchaseOrder.stage !== 'Delivered') {
      return (
        <InputNumber
          inputClassName="w-100"
          value={poItem.quantity}
          onChange={e => this.onQuantityChanged(e.value ?? 0, poItem)}
          min={0}
          maxFractionDigits={4}
          minFractionDigits={0}
          mode="decimal"
        />
      );
    }
    return poItem.quantity;
  }

  async onPoSelection() {
    const {purchaseOrder, toast, onNewPoCreated} = this.props;
    const {selectedPoItems, newPoSelectedId} = this.state;
    let poId = newPoSelectedId;
    this.setState({loading: true});

    try {
      if (poId === 'new') {
        const newPo: PurchaseOrder = {
          supplier_id: purchaseOrder.supplier_id,
          related_order_ids: [],
          factory_id: purchaseOrder.factory_id,
          stage: 'Draft',
          updated_at: new Date(),
        };

        const createdPo = await this.purchaseOrdersService?.createPurchaseOrder(newPo).catch(() => {
          this.toastService?.showError(toast, 'Sorry, Purchase Order create failed, please try again.');
          throw new Error('Purchase Order Creation failed');
        });
        poId = createdPo!.id!;
        onNewPoCreated?.();
      }
      const promises: Promise<PurchaseOrderItem>[] = [];
      selectedPoItems.map(poItem =>
        promises.push(
          this.purchaseOrdersService?.updatePurchaseOrderItem(poId, poItem.id, {
            purchase_order_id: poId,
          }) as Promise<PurchaseOrderItem>
        )
      );
      Promise.all(promises).then(() => {
        this.removePoItems();
        this.setState({
          loading: false,
          showCreateNewPoDialog: false,
        });
        this.toastService?.showSuccess(toast, `Purchase Order ${poId} created/updated successfully.`);
        MessageService.sendMessage(messages.purchaseOrderItemsUpdated);
      });
    } catch (e) {
      console.log(e);
    }
  }

  onNewPoSelectionChange(e: DropdownChangeParams) {
    this.setState({newPoSelectedId: e.value});
  }

  render() {
    const {selectedPoItems, loading, showAddDialog} = this.state;
    const {purchaseOrder, editable, poItems, toast, itemsTableId} = this.props;

    let selectionMode: DataTableSelectionModeType | undefined = undefined;
    let showSelectAll = false;
    let initMenuItemsFunction = undefined;
    if (purchaseOrder.stage === 'Draft' && editable) {
      selectionMode = 'multiple';
      showSelectAll = true;
      initMenuItemsFunction = () => this.initMenuItems(true);
    }

    const poSelectordialogBody = (
      <div className="p-fluid w-100 p-mx-2">
        <div className="p-field p-grid p-mb-0">
          <p>There is another PO in Draft for this vendor. Do you want to create a new PO for use an existing?</p>
          <div className="p-sm-12 p-md-7 p-lg-9">
            <Dropdown
              value={this.state.newPoSelectedId}
              options={this.state.poOfTheSameVendor}
              onChange={this.onNewPoSelectionChange}
              dataKey="value"
            />
          </div>
        </div>
      </div>
    );

    const poSelectorDialogFooter = (
      <>
        <Button
          label="Close"
          className={'p-mr-2 p-button-text'}
          onClick={() => {
            this.setState({showCreateNewPoDialog: false});
          }}
        />
        <Button label="Select Purchase Order" className={'p-mr-2'} onClick={this.onPoSelection} />
      </>
    );

    return (
      <div id={itemsTableId} className="page-container">
        <TwoDataTable
          pageSizeIdentifier={itemsTableId}
          selectionMode={selectionMode}
          selectedItems={selectedPoItems}
          handleChangeSelectedItems={items => this.setChangeSelectedItems(items as PurchaseOrderItem[])}
          loading={loading}
          value={poItems}
          activeFilters={{}}
          initMenuItems={initMenuItemsFunction}
          showPaging={false}
          showSelectAll={showSelectAll}
          dataKey="supply_item_id"
        >
          <Column field="item" header="Item" body={this.itemNameTemplate} style={{width: '200px'}} />
          <Column field="inventory_item.colour" header="Colour" style={{width: '120px'}} />
          <Column field="description" header="Supply Item" body={this.descriptionTemplate} style={{width: '400px'}} />
          <Column field="supply_item.sku" header="SKU" style={{width: '80px'}} />
          <Column field="supply_item.package_size" header="Package Size" style={{width: '120px', maxWidth: '120px'}} />
          <Column field="supply_item.reorder_unit" header="Order Unit" style={{width: '80px'}} />
          <Column
            field="quantity"
            header="Qty"
            body={this.quantityTemplate}
            style={{width: '80px', maxWidth: '80px'}}
          />
          <Column field="delivered_qty" header="Delivered Qty" style={{width: '120px', maxWidth: '120px'}} />
        </TwoDataTable>
        <PurchaseOrderItemAddDialog
          key={purchaseOrder.supplier?.id}
          supplier={purchaseOrder.supplier}
          showDialog={showAddDialog}
          onHide={this.onHideAddDialog}
          assignedPurchaseOrderItems={poItems}
          addPurchaseOrderItems={this.addPoItems}
          toast={toast}
        />

        <TwoDialog
          headerTitle="Please Select to which PO should this item(s) be added"
          loading={false}
          onHide={() => {
            this.setState({showCreateNewPoDialog: false});
          }}
          showDialog={this.state.showCreateNewPoDialog}
          footer={poSelectorDialogFooter}
          style={{width: '50vw'}}
          breakpoints={{'768px': '75vw', '576px': '90vw'}}
        >
          {poSelectordialogBody}
        </TwoDialog>
      </div>
    );
  }
}

export default PurchaseOrderItems;
