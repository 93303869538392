import './InventoryItem.scss';
import React from 'react';
import {DataTablePageParams, DataTableSortParams, DataTableSortOrderType} from 'primereact/datatable';
import {InputText} from 'primereact/inputtext';
import {MultiSelect, MultiSelectChangeParams} from 'primereact/multiselect';
import {Column} from 'primereact/column';
import {Subscription} from 'rxjs';
import {MenuItem, MenuItemOptions} from 'primereact/menuitem';
import {
  AppContext,
  AppMenuItem,
  AppMenuItemTemplate,
  TwoDataTable,
  AppColumnMenuBodyTemplate,
  MessageService,
  TwoMessage,
  ToastService,
  TwoDialog,
} from 'two-app-ui';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faCopy,
  faTrashAlt,
  faFileInvoiceDollar,
  faCalendarExclamation,
  faCalculatorAlt,
  faPlusCircle,
  faFileArrowDown,
  faHandHoldingDollar,
} from '@fortawesome/pro-regular-svg-icons';
import {Toast} from 'primereact/toast';
import update from 'immutability-helper';
import InventoryService from '../../services/InventoryService';

import {
  inventoryItemCategories,
  inventoryItemProductLines,
  inventoryItemTypes,
  inventoryItemUoms,
} from './Constants/constants';
import InventoryItemStock from './InventoryItemStock';
import {StocKeyModel} from './Models/StockModel';
import values from '../../config/values';
import {toInputUppercase} from './Constants/Utils';
import OosDialog from '../Oos/OosDialog';
import {
  ApiListResponse,
  Factory,
  InventoryItem,
  Oos,
  OosStage,
  PurchaseOrderStage,
  QueryParameter,
  Stock,
} from 'two-core';
import OosService from '../../services/OosService';
import OosExistsDialog from './OosExistsDialog';
import {Supplier} from 'two-core';
import PoReferenceComponent from '../Reference/PoReferenceComponent';
import OosReferenceComponent from '../Reference/OosReferenceComponent';
import TleService from '../../services/TleService';
import {messages} from '../../config/messages';
import {NavLink, RouteComponentProps, withRouter} from 'react-router-dom';
import {CsvReport} from '../Reports/CsvReport';
import FactoriesService from '../../services/FactoriesService';
import InventoryPriceChangeDialog from '../InventoryItem/InventoryPriceChangeDialog';
import PurchaseOrderAddDialog from '../PurchaseOrders/PurchaseOrderAddDialog';
import InventoryItemEdit from '../InventoryItem/InventoryItemEdit';
import SuppliersService from '../../services/SuppliersService';

library.add(faPlusCircle, faCopy, faTrashAlt, faFileInvoiceDollar, faCalendarExclamation, faCalculatorAlt);

interface State {
  loading: boolean;
  inventoryItems: InventoryItem[];
  totalInventoryItems: number;
  selectedInventoryItems: InventoryItem[];
  selectedSupplierInventoryItems: InventoryItem[];
  showInventoryItemDialog: boolean;
  showPriceChangeDialog: boolean;
  showReisePoDialog: boolean;
  showPoAddDialog: boolean;
  showUpdateStockDialog: boolean;
  currentInventoryItem: InventoryItem;
  updateStockItems: StocKeyModel;
  showNewOosDialog: boolean;
  showEditOosDialog: boolean;
  showOosExistsDialog: boolean;
  editOos: Oos | undefined;
  inventoryItemOos: Oos[];
  poSuppliers: Supplier[];
  oosInventoryItems: InventoryItem[];
  pagination: {
    pageSize: number;
    offset: number;
  };
  filters: {
    name: string;
    colour: string;
    sku: string;
    product_lines: string[];
    category: string;
    type: string;
    uom: string;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
}

class InventoryItemListComponent extends React.Component<RouteComponentProps, State> {
  static contextType = AppContext;

  inventoryService: InventoryService | null;
  oosService: OosService | null;
  tleService: TleService | null = null;
  toastService: ToastService | null = null;
  factoryService: FactoriesService | null = null;
  supplierService: SuppliersService | null = null;

  subscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      loading: false,
      inventoryItems: [],
      selectedInventoryItems: [],
      selectedSupplierInventoryItems: [],
      totalInventoryItems: 0,
      showInventoryItemDialog: false,
      showPriceChangeDialog: false,
      showReisePoDialog: false,
      showPoAddDialog: false,
      showUpdateStockDialog: false,
      currentInventoryItem: {
        factory_id: localStorage.getItem('current factory') ?? '',
        category: '',
        name: '',
        type: '',
        uom: '',
        updated_at: new Date(),
      },
      updateStockItems: {},
      inventoryItemOos: [],
      showNewOosDialog: false,
      showEditOosDialog: false,
      showOosExistsDialog: false,
      editOos: undefined,
      poSuppliers: [],
      oosInventoryItems: [],
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      filters: {
        name: '',
        colour: '',
        sku: '',
        product_lines: [],
        category: '',
        type: '',
        uom: '',
      },
      sortBy: null,
    };

    this.inventoryService = null;
    this.oosService = null;
    this.processShowUpdateStockDialog = this.processShowUpdateStockDialog.bind(this);
    this.detailBodyTemplate = this.detailBodyTemplate.bind(this);
    this.refsBodyTemplate = this.refsBodyTemplate.bind(this);
    this.poReferenceTemplate = this.poReferenceTemplate.bind(this);
    this.oosReferenceTemplate = this.oosReferenceTemplate.bind(this);
    this.handleInventoryItemStockInputChange = this.handleInventoryItemStockInputChange.bind(this);
    this.closeInventoryItem = this.closeInventoryItem.bind(this);
    this.showPoAddDialog = this.showPoAddDialog.bind(this);
    this.saveStockItems = this.saveStockItems.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.setChangeSelectedItems = this.setChangeSelectedItems.bind(this);
    this.initMenuItems = this.initMenuItems.bind(this);
    this.setOos = this.setOos.bind(this);
    this.showNewOosDialogAction = this.showNewOosDialogAction.bind(this);
    this.showEditOosDialogAction = this.showEditOosDialogAction.bind(this);
    this.setChangeOosInventoryItems = this.setChangeOosInventoryItems.bind(this);
    this.setChangePoSuppliers = this.setChangePoSuppliers.bind(this);

    this.toast = React.createRef();
  }

  componentDidMount() {
    this.inventoryService = this.context.inventoryService;
    this.oosService = this.context.oosService;
    this.tleService = this.context.tleService;
    this.toastService = this.context.toastService;
    this.factoryService = this.context.factoriesService;
    this.supplierService = this.context.supplierService;

    this.subscription = MessageService.getMessage().subscribe(async message => {
      const castedMessage = message as TwoMessage;
      if (castedMessage.name && castedMessage.name === 'top-selection-changed') {
        await localStorage.setItem('current factory', castedMessage.value as string);
        this.setState({
          currentInventoryItem: {
            ...this.state.currentInventoryItem,
            factory_id: localStorage.getItem('current factory') ?? '',
          },
        });
        this.loadData();
      }
      if (message === messages.purchaseOrderUpdated || message === messages.reloadInventoryItemList) {
        this.loadData();
      }
    });

    this.loadData();
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  loadData() {
    this.setState({loading: true});
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'factory_id',
        value: localStorage.getItem('current factory'),
      })
    );

    if (this.state.filters.name) {
      filters.push(
        JSON.stringify({
          field: 'name',
          value: this.state.filters.name,
          condition: 'like',
        })
      );
    }
    if (this.state.filters.colour) {
      filters.push(
        JSON.stringify({
          field: 'colour',
          value: this.state.filters.colour,
          condition: 'like',
        })
      );
    }
    if (this.state.filters.sku) {
      filters.push(
        JSON.stringify({
          field: 'sku',
          value: this.state.filters.sku,
          condition: 'like',
        })
      );
    }
    if (this.state.filters.product_lines && this.state.filters.product_lines.length > 0) {
      filters.push(
        JSON.stringify({
          field: 'product_lines',
          value: this.state.filters.product_lines,
          condition: 'anyOfAny',
        })
      );
    }
    if (this.state.filters.category && this.state.filters.category.length > 0) {
      filters.push(
        JSON.stringify({
          field: 'category',
          value: this.state.filters.category,
          condition: 'in',
        })
      );
    }
    if (this.state.filters.type && this.state.filters.type.length > 0) {
      filters.push(
        JSON.stringify({
          field: 'type',
          value: this.state.filters.type,
          condition: 'in',
        })
      );
    }
    if (this.state.filters.uom && this.state.filters.uom.length > 0) {
      filters.push(
        JSON.stringify({
          field: 'uom',
          value: this.state.filters.uom,
          condition: 'in',
        })
      );
    }

    //some columns have different name in database and map for server
    // @TODO: find why are there columns with different name in database and fix.
    const sortBy = {
      field: this.state.sortBy?.field,
      direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
    };

    const sortByStringyfied = JSON.stringify(sortBy);

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: this.state.sortBy ? [sortByStringyfied] : [],
      aggregate: true,
    };

    this.inventoryService
      ?.getInventoryItems(params)
      .then(data => {
        const dataRecords = (data.records as InventoryItem[]) ?? [];

        this.handleSelectedItems(dataRecords);

        this.setState({
          inventoryItems: dataRecords,
          totalInventoryItems: data.total_records ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
      });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement> | MultiSelectChangeParams) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  initMenuItems(): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];

    const selectedItems = this.state.selectedInventoryItems;
    const selectedItemsCount = selectedItems.length;

    this.initNewMenuItems(menuItems);

    this.initSeparatorMenuItems(menuItems);
    if (selectedItemsCount > 0) {
      this.initOtherMenuItems(menuItems);
      this.initSeparatorMenuItems(menuItems);
    }
    this.initBottomMenuItems(menuItems);

    this.initSeparatorMenuItems(menuItems);
    this.initExportMenuItem(menuItems);

    return menuItems;
  }

  initSeparatorMenuItems(menuItems: MenuItem[]) {
    menuItems.push({
      separator: true,
    });
  }

  initNewMenuItems(menuItems: AppMenuItem[]) {
    menuItems.push({
      label: 'New',
      faIcon: faPlusCircle,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.setState({showInventoryItemDialog: true});
      },
    });
  }

  initExportMenuItem(menuItems: AppMenuItem[]) {
    menuItems.push({
      label: 'Export',
      faIcon: faFileArrowDown,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => this.exportInventory(),
    });
  }

  initBottomMenuItems(menuItems: AppMenuItem[]) {
    menuItems.push({
      label: 'Price Change',
      faIcon: faHandHoldingDollar,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.setState({showPriceChangeDialog: true});
      },
    });
  }

  initOtherMenuItems(menuItems: AppMenuItem[]) {
    menuItems.push({
      label: 'Copy',
      faIcon: faCopy,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      disabled: this.state?.selectedInventoryItems?.length > 1 ?? false,
      command: () => {
        this.copyItem();
      },
    });

    menuItems.push({
      label: 'Delete',
      faIcon: faTrashAlt,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.deleteItems();
      },
    });

    menuItems.push({
      label: 'Create PO',
      faIcon: faFileInvoiceDollar,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.showPoAddDialog();
      },
    });

    this.state.selectedInventoryItems.length === 1 &&
      menuItems.push({
        label: 'Set OOS',
        faIcon: faCalendarExclamation,
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: this.setOos,
      });

    menuItems.push({
      label: 'Update Stock',
      faIcon: faCalculatorAlt,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.processShowUpdateStockDialog();
      },
    });
  }

  setOos() {
    this.setState({loading: true});
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'factory_id',
        value: localStorage.getItem('current factory') ?? '',
      })
    );
    filters.push(
      JSON.stringify({
        field: 'inventory_item_id',
        value: this.state.selectedInventoryItems[0]?.id,
      })
    );
    filters.push(
      JSON.stringify({
        field: 'stage',
        value: ['Available'],
        condition: 'notIn',
      })
    );

    const params: QueryParameter = {
      filters,
      aggregate: true,
    };
    this.oosService?.getOos(params).then(data => {
      const oosData = (data.records as Oos[]) ?? [];
      if (oosData.length > 0) {
        this.setState({
          showOosExistsDialog: true,
          inventoryItemOos: oosData,
        });
      } else {
        this.showNewOosDialogAction();
      }

      this.setState({loading: false});
    });
  }

  showNewOosDialogAction() {
    // show dialog for one selected item only
    if (this.state.selectedInventoryItems.length === 1) {
      this.setState({
        showOosExistsDialog: false,
        showNewOosDialog: true,
        editOos: {
          factory_id: localStorage.getItem('current factory') as string,
          inventory_item_id: this.state.selectedInventoryItems[0].id as string,
          inventory_item: this.state.selectedInventoryItems[0],
          stage: 'Eta Confirmed',
          updated_at: new Date(),
        },
      });
    }
  }

  showEditOosDialogAction() {
    const oosOfInventoryItem = this.state.inventoryItemOos;
    this.setState({
      showOosExistsDialog: false,
      showEditOosDialog: true,
      editOos: oosOfInventoryItem[0],
    });
  }

  processShowUpdateStockDialog() {
    const updateStockItems: StocKeyModel = {};
    this.state.selectedInventoryItems.forEach((e: InventoryItem) => {
      updateStockItems[e.id ?? ''] = {
        quantity: e.current_stock_level,
        item: e,
      };
    });
    this.setState({
      updateStockItems: updateStockItems,
      showUpdateStockDialog: true,
    });
  }

  processShowInventoryItemEditDialog(item: InventoryItem) {
    this.setState({
      currentInventoryItem: item,
      showInventoryItemDialog: true,
    });
  }

  async copyItem() {
    if (this.state.selectedInventoryItems[0]) {
      const selectedItem: InventoryItem = this.state.selectedInventoryItems[0];
      const newItem: InventoryItem = {
        factory_id: selectedItem.factory_id,
        name: selectedItem.name,
        category: selectedItem.category,
        colour: selectedItem.colour,
        type: selectedItem.type,
        sku: selectedItem.sku,
        uom: selectedItem.uom,
        reorder_limit: selectedItem.reorder_limit,
        current_stock_level: selectedItem.current_stock_level,
        product_lines: selectedItem.product_lines,
        updated_at: selectedItem.updated_at,
      };
      this.setState({
        currentInventoryItem: newItem,
        showInventoryItemDialog: true,
      });
    } else {
      this.toastService?.showError(this.toast, 'Sorry, inventory item copy failed, please try again.');
    }
  }

  async deleteItems() {
    for (const selectedItem of this.state.selectedInventoryItems) {
      if (selectedItem.id) {
        try {
          await this.inventoryService?.deleteInventoryItem(selectedItem.id);
          this.toastService?.showSuccess(this.toast, `${selectedItem.sku} removed successfully.`);
        } catch (e) {
          this.toastService?.showError(this.toast, `Sorry, ${selectedItem.sku} delete failed, please try again.`);
        }
      }
    }
    this.loadData();
  }

  refresh() {
    this.setState({
      showInventoryItemDialog: false,
      currentInventoryItem: this.emptyInventoryItem(),
    });
    this.loadData();
  }

  handleInputChange(e: React.ChangeEvent<HTMLInputElement> | MultiSelectChangeParams) {
    const newState = update(this.state, {
      currentInventoryItem: {
        $merge: {
          [e.target.name]: e.target.value,
        },
      },
    });
    this.setState(newState);
  }

  handleInventoryItemStockInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newState = update(this.state, {
      updateStockItems: {
        $merge: {
          ...this.state.updateStockItems,
          [e.target.name]: {
            ...this.state.updateStockItems[e.target.name],
            quantity: e.target.value,
          },
        },
      },
    });
    this.setState(newState);
  }

  closeInventoryItem() {
    this.setState({
      showInventoryItemDialog: false,
      currentInventoryItem: this.emptyInventoryItem(),
    });
  }

  saveInventoryItem() {
    const inventoryItem = this.state.currentInventoryItem;

    if (inventoryItem.id) {
      this.inventoryService
        ?.updateInventoryItem(inventoryItem.id, inventoryItem)
        .then(() => {
          this.refresh();
          this.toastService?.showSuccess(this.toast, 'Inventory item updated successfully.');
          this.closeInventoryItem();
        })
        .catch(() => {
          this.setState({
            showInventoryItemDialog: false,
            currentInventoryItem: this.emptyInventoryItem(),
          });
          this.toastService?.showError(this.toast, 'Sorry, Inventory item update failed, please try again.');
        });
    } else {
      this.inventoryService
        ?.createInventoryItem(this.state.currentInventoryItem)
        .then(data => {
          const newItem: InventoryItem = data;
          this.setState({
            currentInventoryItem: newItem,
          });

          this.toast.current?.show({
            contentClassName: '',
            severity: 'success',
            summary: 'Success',
            detail: 'Inventory item created successfully.',
            life: 3000,
          });
          this.closeInventoryItem();
        })
        .catch(() => {
          this.setState({
            showInventoryItemDialog: false,
            currentInventoryItem: this.emptyInventoryItem(),
          });

          this.toastService?.showError(this.toast, 'Sorry, Inventory item create failed, please try again.');
        });
    }
  }

  emptyInventoryItem() {
    const inventoryItem: InventoryItem = {
      factory_id: localStorage.getItem('current factory') ?? '',
      category: '',
      name: '',
      type: '',
      uom: '',
      updated_at: new Date(),
    };
    return inventoryItem;
  }

  setChangePoSuppliers(suppliers: Supplier[]) {
    this.setState({poSuppliers: suppliers});
  }

  setChangeOosInventoryItems(inventoryItems: InventoryItem[]) {
    this.setState({oosInventoryItems: inventoryItems});
  }

  showPoAddDialog() {
    const items = this.state.selectedInventoryItems;
    let firstSelectedSupplierId: string;
    //firstly find the first supplier of selected items
    for (const item of items) {
      if (item.supply_items?.[0]?.supplier_id) {
        firstSelectedSupplierId = item.supply_items[0].supplier_id;
        break;
      }
    }
    //after that we need remove (supply) items from other suppliers.
    const supplierInventoryItems: InventoryItem[] = [];
    const otherSuppliersInventoryItems: InventoryItem[] = [];
    for (const item of items) {
      const supplierSupplyItems = item.supply_items?.filter(
        supplyItem => supplyItem?.supplier_id === firstSelectedSupplierId
      );
      if (supplierSupplyItems?.length) {
        supplierInventoryItems.push({
          ...item,
          supply_items: supplierSupplyItems,
        });
      } else {
        otherSuppliersInventoryItems.push({...item});
      }
    }

    this.setState({
      showPoAddDialog: true,
      selectedSupplierInventoryItems: supplierInventoryItems,
    });

    if (otherSuppliersInventoryItems.length) {
      const toastMessage = `These items don't show in dialog: ${otherSuppliersInventoryItems
        .map(item => item.name)
        .join(', ')}`;
      this.toastService?.showError(this.toast, toastMessage);
    }
  }

  saveStockItems() {
    if (this.state.updateStockItems !== null) {
      const factory_id: string = localStorage.getItem('current factory') ?? '';
      const items: Stock[] = Object.keys(this.state.updateStockItems).map((key: string) => {
        const item = this.state.updateStockItems[key];
        const stock: Stock = {
          quantity: item.quantity ?? 0,
          item: item.item,
        };
        return stock;
      });

      this.inventoryService
        ?.updateStocks(factory_id, items)
        .then(() => {
          this.toastService?.showSuccess(this.toast, 'Stock items updated successfully.');
          this.setState({showUpdateStockDialog: false});
          this.loadData();
        })
        .catch(() => {
          this.toastService?.showError(this.toast, 'Sorry, Stock items update failed, please try again.');
          this.setState({showUpdateStockDialog: false});
        });
    }
  }

  getCurrentUserId() {
    const unparsedUser: string = localStorage.getItem('user') ?? '';
    const currentUser = JSON.parse(unparsedUser);
    const userId = currentUser?.uuid ?? '';
    return userId;
  }

  async exportInventory() {
    this.setState({loading: true});
    const factoryId = localStorage.getItem('current factory');
    const headers =
      'SKU NAME,SKU COLOUR,SKU,PRODUCT LINE,CATEGORY,TYPE,UOM,CURRENT STOCK,REORDER LIMIT,SUPPLIER,SUPPLIER NAME FOR THE ITEM,SUPPLIER SKU,ORDER UNIT,QTY IN UOM,UNIT PRICE,PACKAGE SIZE,PACKAGE LABEL';
    const csvReport = new CsvReport();
    const supplierNames = new Map<string, string>();

    const factory: Factory | undefined = await this.factoryService?.getFactory(factoryId!);
    const factoryName = factory?.name_short ?? factoryId;

    //suppliers
    await this.supplierService?.getSuppliers({}).then((response: ApiListResponse) => {
      for (const supplier of response.records as Supplier[]) {
        supplierNames.set(supplier.id, supplier.company_name);
      }
    });

    //automation
    let response = await this.inventoryService?.getInventoryItems({
      aggregate: true,
      filters: [
        JSON.stringify({
          field: 'factory_id',
          value: factoryId,
        }),
        JSON.stringify({
          field: 'category',
          value: 'Automation',
        }),
      ],
      orderBys: [
        JSON.stringify({
          field: 'name',
        }),
      ],
    });
    if (response && response.records) {
      const invItems = response.records as InventoryItem[];
      const automationContent = [headers];
      automationContent.push(...this.createCSVContent(invItems, supplierNames));
      csvReport.saveCsv(`${factoryName}_automation`, automationContent);
    }

    //fabrics_CV
    response = await this.inventoryService?.getInventoryItems({
      aggregate: true,
      filters: [
        JSON.stringify({
          field: 'factory_id',
          value: factoryId,
        }),
        JSON.stringify({
          field: 'category',
          value: 'Fabrics',
        }),
        JSON.stringify({
          field: 'product_lines',
          value: ['COLOURVUE'],
          condition: 'anyOfAny',
        }),
      ],
      orderBys: [
        JSON.stringify({
          field: 'name',
        }),
      ],
    });
    if (response && response.records) {
      const invItems = response.records as InventoryItem[];
      const cvFabricsContent = [headers];
      cvFabricsContent.push(...this.createCSVContent(invItems, supplierNames));
      csvReport.saveCsv(`${factoryName}_fabrics_cv`, cvFabricsContent);
    }

    //fabrics_SS
    response = await this.inventoryService?.getInventoryItems({
      aggregate: true,
      filters: [
        JSON.stringify({
          field: 'factory_id',
          value: factoryId,
        }),
        JSON.stringify({
          field: 'category',
          value: 'Fabrics',
        }),
        JSON.stringify({
          field: 'product_lines',
          value: ['SHADESOL'],
          condition: 'anyOfAny',
        }),
      ],
      orderBys: [
        JSON.stringify({
          field: 'name',
        }),
      ],
    });
    if (response && response.records) {
      const invItems = response.records as InventoryItem[];
      const ssFabricsContent = [headers];
      ssFabricsContent.push(...this.createCSVContent(invItems, supplierNames));
      csvReport.saveCsv(`${factoryName}_fabrics_ss`, ssFabricsContent);
    }

    //hardware_CV
    response = await this.inventoryService?.getInventoryItems({
      aggregate: true,
      filters: [
        JSON.stringify({
          field: 'factory_id',
          value: factoryId,
        }),
        JSON.stringify({
          field: 'category',
          value: ['Hardware', 'Aluminium'],
          condition: 'in',
        }),
        JSON.stringify({
          field: 'product_lines',
          value: ['COLOURVUE'],
          condition: 'anyOfAny',
        }),
      ],
      orderBys: [
        JSON.stringify({
          field: 'name',
        }),
      ],
    });
    if (response && response.records) {
      const invItems = response.records as InventoryItem[];
      const cvHardwareContent = [headers];
      cvHardwareContent.push(...this.createCSVContent(invItems, supplierNames));
      csvReport.saveCsv(`${factoryName}_hardware_cv`, cvHardwareContent);
    }

    //hardware_SS
    response = await this.inventoryService?.getInventoryItems({
      aggregate: true,
      filters: [
        JSON.stringify({
          field: 'factory_id',
          value: factoryId,
        }),
        JSON.stringify({
          field: 'category',
          value: ['Hardware', 'Aluminium'],
          condition: 'in',
        }),
        JSON.stringify({
          field: 'product_lines',
          value: ['SHADESOL'],
          condition: 'anyOfAny',
        }),
      ],
      orderBys: [
        JSON.stringify({
          field: 'name',
        }),
      ],
    });
    if (response && response.records) {
      const invItems = response.records as InventoryItem[];
      const ssHardwareContent = [headers];
      ssHardwareContent.push(...this.createCSVContent(invItems, supplierNames));
      csvReport.saveCsv(`${factoryName}_hardware_ss`, ssHardwareContent);
    }

    this.setState({loading: false});
  }

  createCSVContent(inventoryItems: InventoryItem[], suppliers: Map<string, string>): string[] {
    const result: string[] = [];
    const emptySupplyItem = ',,,,,,,,';
    for (const ii of inventoryItems) {
      const itemPrefix = `${ii.name},${ii.colour ?? ''},${ii.sku},${ii.product_lines?.join('/')},${ii.category},${
        ii.type
      },${ii.uom},${ii.current_stock_level},${ii.reorder_limit}`;
      if (!ii.supply_items || ii.supply_items.length === 0) {
        result.push(`${itemPrefix},${emptySupplyItem}`);
      } else {
        for (const si of ii.supply_items) {
          result.push(
            `${itemPrefix},${suppliers.get(si.supplier_id ?? '') ?? 'MISSING NAME!!!'},${si.name},${si.sku},${
              si.reorder_unit
            },${si.reorder_qty_in_uom},${si.unit_price ?? 0},${si.package_size ?? 0},${si.package_size_label ?? ''}`
          );
        }
      }
    }
    return result;
  }

  handleSelectedItems(allItems: InventoryItem[]) {
    const selectedItems = [...this.state.selectedInventoryItems];
    const items: InventoryItem[] = allItems.filter(item => {
      return selectedItems.find(selectedItem => {
        return selectedItem.id === item.id;
      });
    });

    this.setChangeSelectedItems(items);
  }

  setChangeSelectedItems(items: InventoryItem[]) {
    this.setState({selectedInventoryItems: items});
  }

  async setChangeSelectedItem(item: InventoryItem) {
    const items = [...this.state.selectedInventoryItems];
    const existingItem = items.find(i => i.id === item.id);
    if (!existingItem) {
      items.push(item);
      await this.setState({selectedInventoryItems: items});
    }
  }

  detailBodyTemplate(rowData: InventoryItem) {
    return (
      <AppColumnMenuBodyTemplate
        isDynamicMenuItems={true}
        initMenuItems={() => this.initMenuItems()}
        rowItemIdentifier={rowData?.id ?? ''}
        selectedItems={this.state.selectedInventoryItems}
        handleChangeSelectedItems={() => this.setChangeSelectedItem(rowData)}
      >
        <NavLink to={'inventory-item/' + rowData.id}>{rowData.name}</NavLink>
      </AppColumnMenuBodyTemplate>
    );
  }

  productLinesTemplate(rowData: InventoryItem) {
    return `${rowData.product_lines?.join(', ')}`;
  }

  refsBodyTemplate(rowData: InventoryItem) {
    return (
      <div className="p-d-flex p-flex-row p-flex-wrap">
        {this.poReferenceTemplate(rowData)}
        {this.oosReferenceTemplate(rowData)}
      </div>
    );
  }

  poReferenceTemplate(rowData: InventoryItem) {
    const hiddenStages: PurchaseOrderStage[] = ['Delivered', 'Cancelled'];
    const visiblePurchaseOrders = rowData.active_pos?.filter(po => po && !hiddenStages.includes(po.stage)) ?? [];
    return (
      <PoReferenceComponent
        identifier={rowData.id ?? ''}
        purchaseOrders={visiblePurchaseOrders}
        suppliers={this.state.poSuppliers}
        handleChangePoSupplier={suppliers => this.setChangePoSuppliers(suppliers)}
        handlePoReferenceClick={po => {
          this.props.history.push(`/purchase-order/${po.id}`);
        }}
      />
    );
  }

  oosReferenceTemplate(rowData: InventoryItem) {
    const hiddenStage: OosStage = 'Available';
    const visibleOos = rowData.out_of_stocks?.filter(oos => oos && oos.stage !== hiddenStage) ?? [];
    return (
      <OosReferenceComponent
        identifier={rowData.id ?? ''}
        oosRecords={visibleOos}
        oosInventoryItems={this.state.oosInventoryItems}
        handleChangeOosInventoryItems={inventoryItems => this.setChangeOosInventoryItems(inventoryItems)}
        handleOosReferenceClick={oos => {
          this.props.history.push(`/oos-item/${oos.id}`);
        }}
      />
    );
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, values.stopTypingDetection);
  };

  render() {
    const nameFilter = (
      <InputText
        name="name"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
        onInput={toInputUppercase}
      />
    );

    const colourFilter = (
      <InputText
        name="colour"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
        onInput={toInputUppercase}
      />
    );

    const skuFilter = (
      <InputText
        name="sku"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
        onInput={toInputUppercase}
      />
    );

    const prodLinesFilter = (
      <MultiSelect
        optionLabel="label"
        optionValue="value"
        options={inventoryItemProductLines}
        value={this.state.filters.product_lines}
        name="product_lines"
        className="form-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
      />
    );

    const categoryFilter = (
      <MultiSelect
        optionLabel="label"
        optionValue="value"
        options={inventoryItemCategories}
        value={this.state.filters.category}
        name="category"
        className="form-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
      />
    );

    const typeFilter = (
      <MultiSelect
        optionLabel="label"
        optionValue="value"
        options={inventoryItemTypes}
        value={this.state.filters.type}
        name="type"
        className="form-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
      />
    );

    const uomFilter = (
      <MultiSelect
        optionLabel="label"
        optionValue="value"
        options={inventoryItemUoms}
        value={this.state.filters.uom}
        name="uom"
        className="form-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
      />
    );

    return (
      <div id="inventory_page_container" className="page-container">
        <TwoDataTable
          customEmptyMessage={'No inventory items found.'}
          addNewItemEvent={() => this.processShowInventoryItemEditDialog(this.state.currentInventoryItem)}
          selectedItems={this.state.selectedInventoryItems}
          pageSizeIdentifier={'inventory_page_container'}
          handleChangeSelectedItems={items => this.setChangeSelectedItems(items as InventoryItem[])}
          activeFilters={{}}
          value={this.state.inventoryItems}
          rows={this.state.pagination.pageSize}
          selectionMode="multiple"
          loading={this.state.loading}
          totalRecords={this.state.totalInventoryItems}
          first={this.state.pagination.offset}
          onPage={e => this.onPageChange(e as DataTablePageParams)}
          onSort={this.onSort}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          initMenuItems={this.initMenuItems}
        >
          <Column
            header="Name"
            field="name"
            body={this.detailBodyTemplate}
            filter
            filterElement={nameFilter}
            sortable
            style={{width: '300px'}}
            showFilterMenu={false}
          />
          <Column
            header="Colour"
            field="colour"
            filter
            filterElement={colourFilter}
            sortable
            style={{width: '150px'}}
            showFilterMenu={false}
          />
          <Column
            header="SKU"
            field="sku"
            filter
            filterElement={skuFilter}
            sortable
            style={{width: '300px'}}
            showFilterMenu={false}
          />
          <Column
            header="Product Line"
            field="product_lines"
            body={this.productLinesTemplate}
            style={{width: '150px'}}
            filter
            filterElement={prodLinesFilter}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="Category"
            field="category"
            style={{width: '100px'}}
            filter
            filterElement={categoryFilter}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="Type"
            field="type"
            style={{width: '50px'}}
            filter
            filterElement={typeFilter}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="UOM"
            field="uom"
            style={{width: '150px'}}
            filter
            filterElement={uomFilter}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="Current Stock"
            field="current_stock_level"
            style={{width: '150px'}}
            sortable
            showFilterMenu={false}
          />
          <Column header="Reserved" field="reserved_qty" style={{width: '150px'}} sortable showFilterMenu={false} />
          <Column
            header="Re-order Limit"
            field="reorder_limit"
            style={{width: '150px'}}
            sortable
            showFilterMenu={false}
          />
          <Column header="Refs" body={this.refsBodyTemplate} style={{width: '150px'}} showFilterMenu={false} />
        </TwoDataTable>

        <Toast ref={this.toast} />
        <InventoryItemEdit
          inventoryItem={this.state.currentInventoryItem}
          showDialog={this.state.showInventoryItemDialog}
          onHide={this.closeInventoryItem}
          toast={this.toast}
          history={this.props.history}
        />

        <InventoryPriceChangeDialog
          showDialog={this.state.showPriceChangeDialog}
          onHide={() => {
            this.setState({showPriceChangeDialog: false});
          }}
        />
        <TwoDialog
          className="inventory-item-dialog"
          headerTitle={'Update stock'}
          width={50}
          showDialog={this.state.showUpdateStockDialog}
          onHide={() => this.setState({showUpdateStockDialog: false})}
          onSave={this.saveStockItems}
          loading={false}
        >
          <InventoryItemStock
            updateItems={this.state.updateStockItems}
            handleInputChange={this.handleInventoryItemStockInputChange}
          />
        </TwoDialog>
        <PurchaseOrderAddDialog
          toast={this.toast}
          showPurchaseOrderDialog={this.state.showPoAddDialog}
          closeDialog={() => this.setState({showPoAddDialog: false})}
          inventoryItems={this.state.selectedSupplierInventoryItems}
        />
        <OosExistsDialog
          showDialog={this.state.showOosExistsDialog}
          onHide={() => this.setState({showOosExistsDialog: false})}
          onYes={this.showEditOosDialogAction}
          toast={this.toast}
        />
        <OosDialog
          showDialog={this.state.showEditOosDialog}
          oos={this.state.editOos}
          toast={this.toast}
          onHide={() => {
            this.setState({
              showEditOosDialog: false,
              editOos: undefined,
              inventoryItemOos: [],
            });
            this.loadData();
          }}
          isEdit={true}
        />
        <OosDialog
          showDialog={this.state.showNewOosDialog}
          toast={this.toast}
          oos={this.state.editOos}
          onHide={() => {
            this.setState({
              showNewOosDialog: false,
            });
            this.loadData();
          }}
          isNew={true}
        />
      </div>
    );
  }
}

export default withRouter(InventoryItemListComponent);
