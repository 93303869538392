import {DateTime} from 'luxon';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import React from 'react';
import {AppContext, ToastService, TwoDataTable} from 'two-app-ui';
import {Bom, Order, QueryParameter} from 'two-core';
import formats from '../../config/formats';
import BomService from '../../services/BomService';

interface Props {
  order: Order;
}

interface State {
  loading: boolean;
  boms: Bom[];
  total_boms: number;
}

class OrderBoms extends React.Component<Props, State> {
  static contextType = AppContext;
  bomService: BomService | null = null;
  toastService: ToastService | null = null;

  toast: React.RefObject<Toast>;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      boms: [],
      total_boms: 0,
    };

    this.toast = React.createRef();

    this.subtractedBodyTemplate = this.subtractedBodyTemplate.bind(this);
    this.reservedBodyTemplate = this.reservedBodyTemplate.bind(this);
  }

  componentDidMount() {
    this.bomService = this.context.bomService;
    this.toastService = this.context.toastService;

    this.loadData();
  }

  loadData() {
    this.setState({loading: true});
    const orderId = this.props.order.id ?? '';

    const bomFilters: string[] = [];

    bomFilters.push(
      JSON.stringify({
        field: 'order_id',
        value: orderId,
      })
    );

    const bomParams: QueryParameter = {
      filters: bomFilters,
      aggregate: true,
    };

    this.bomService
      ?.getBoms(bomParams)
      .then(boms => {
        this.setState({
          boms: boms ?? [],
          total_boms: boms?.length ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, boms records load failed, please try again.');
        this.setState({loading: false});
        console.error(error);
      });
  }

  subtractedBodyTemplate(rowData: Bom) {
    const formated_substracted_on = rowData.subtracted_at
      ? DateTime.fromISO(rowData.subtracted_at.toString()).toFormat(formats.date)
      : '';
    return <span>{formated_substracted_on}</span>;
  }

  reservedBodyTemplate(rowData: Bom) {
    const formated_substracted_on = rowData.reserved_at
      ? DateTime.fromISO(rowData.reserved_at.toString()).toFormat(formats.date)
      : '';
    return <span>{formated_substracted_on}</span>;
  }

  render() {
    return (
      <div className="p-d-flex p-p-2" style={{height: '100%'}}>
        <div id="boms_list_page" className="page-container">
          <TwoDataTable
            pageSizeIdentifier={'boms_list_page'}
            selectedItems={[]}
            heightToScroll="500px"
            activeFilters={{}}
            loading={this.state.loading}
            value={this.state.boms}
            totalRecords={this.state.total_boms}
            sortField="inventory_item_id"
            showPaging={false}
          >
            <Column header="Inventory Item Name" field="inventory_item.name" style={{flex: '1 1'}} />
            <Column header="Colour" field="inventory_item.colour" style={{flex: '1 1'}} />
            <Column header="Sku" field="inventory_item.sku" style={{flex: '1 1'}} />
            <Column header="Quantity" field="quantity" style={{flex: '1 1'}} />
            <Column header="Uom" field="inventory_item.uom" style={{flex: '1 1'}} />
            <Column header="Extra Info" field="extra_info" style={{flex: '1 1'}} />
            <Column header="Reserved" field="reserved_at" body={this.reservedBodyTemplate} style={{flex: '1 1'}} />
            <Column
              header="Subtracted"
              field="subtracted_at"
              body={this.subtractedBodyTemplate}
              style={{flex: '1 1'}}
            />
          </TwoDataTable>
          <Toast ref={this.toast} />
        </div>
      </div>
    );
  }
}

export default OrderBoms;
