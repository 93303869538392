import React from 'react';
import {ProgressSpinner} from 'primereact/progressspinner';
import {AppContext, MessageService, TwoMessage} from 'two-app-ui';
import {Subscription} from 'rxjs';
import {OrderGroup, QueryParameter, Factory} from 'two-core';
import OrdersService from '../../services/OrdersService';
import {Chart} from 'primereact/chart';
import {messages} from '../../config/messages';
import {DateTime} from 'luxon';
import formats from '../../config/formats';
import FactoriesService from '../../services/FactoriesService';

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const defaultChartData: ChartData = {
  labels: [],
  datasets: [
    {
      label: 'Repair',
      data: [],
      fill: false,
      borderColor: '#6d6e71',
      tension: 0.4,
    },
    {
      label: 'Component',
      data: [],
      fill: false,
      borderColor: '#bcbec0',
      tension: 0.4,
    },
    {
      label: 'Standard',
      data: [],
      fill: false,
      borderColor: '#ec6331',
      tension: 0.4,
    },
    {
      label: 'Capacity',
      data: [],
      fill: false,
      borderColor: '#000000',
      tension: 0.4,
    },
  ],
};

interface DateOrderGroup {
  date: Date;
  orderGroup: OrderGroup;
}

interface ChartDataSet {
  label: string;
  data: number[];
  fill: boolean;
  borderColor: string;
  tension: number;
}

interface ChartData {
  labels: string[];
  datasets: ChartDataSet[];
}

interface State {
  loading: boolean;
  days: OrderGroup[];
  chartData: ChartData;
  factory: Factory | undefined;
}

class ScheduleTrendsComponent extends React.Component<{}, State> {
  static contextType = AppContext;

  ordersService: OrdersService | null = null;
  factoriesService: FactoriesService | null = null;

  subscription: Subscription = new Subscription();

  constructor(props: {}) {
    super(props);
    this.state = {
      loading: false,
      factory: undefined,
      days: [],
      chartData: defaultChartData,
    };

    this.loadData = this.loadData.bind(this);
  }

  async componentDidMount() {
    this.ordersService = this.context.ordersService;
    this.factoriesService = this.context.factoriesService;

    this.subscription = MessageService.getMessage().subscribe(async message => {
      if (
        message === messages.scheduleFilterChanged ||
        message === messages.ordersUpdated ||
        message === messages.factoryUpdated
      ) {
        this.loadData();
      } else {
        const castedMessage = message as TwoMessage;
        if (castedMessage.name && castedMessage.name === 'top-selection-changed') {
          await localStorage.setItem('current factory', castedMessage.value as string);
          this.loadData();
        }
      }
    });

    this.loadData();
    MessageService.sendMessage(messages.heightChanged);
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  async loadFactory() {
    this.setState({loading: true});

    const currentFactoryId = localStorage.getItem('current factory') ?? '';

    return this.factoriesService
      ?.getFactory(currentFactoryId)
      .then(data => {
        const factory = data;
        this.setState({loading: false, factory: factory});
      })
      .catch(() => {
        this.setState({loading: false});
      });
  }

  async loadData() {
    await this.loadFactory();

    this.setState({loading: true});
    const filters: string[] = [];

    const productLine = localStorage.getItem('schedule product line') ?? 'Colourvue';

    const value = localStorage.getItem('filter by date') ?? 'ecd';
    const dateName = value === 'ecd' ? 'factory_order.ecd' : 'factory_order.scheduled_for';

    const fromDateValue = localStorage.getItem('from date');
    const fromDate = fromDateValue && fromDateValue !== '' ? new Date(fromDateValue) : null;
    const toDateValue = localStorage.getItem('to date');

    const toDate = toDateValue && toDateValue !== '' ? new Date(toDateValue) : null;

    const stringValue = localStorage.getItem('show done') ?? '';
    const showDoneOrders = stringValue ? JSON.parse(stringValue) : false;

    if (fromDate) {
      const from = DateTime.fromJSDate(fromDate).toISODate();
      filters.push(
        JSON.stringify({
          field: dateName,

          value: from,
          condition: '>=',
        })
      );
    }
    if (toDate) {
      const to = DateTime.fromJSDate(toDate).toISODate();
      filters.push(
        JSON.stringify({
          field: dateName,
          value: to,
          condition: '<=',
        })
      );
    }

    const stageExclusions = ['Cancelled'];

    if (!showDoneOrders) {
      stageExclusions.push('Done');
      stageExclusions.push('Post Fix Return');
    }

    filters.push(
      JSON.stringify({
        field: 'factory_order.production_stage',
        value: stageExclusions,
        condition: 'notIn',
      })
    );

    filters.push(
      JSON.stringify({
        field: 'factory_order.product_line',
        value: productLine,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      groupBy: dateName,
    };

    this.ordersService
      ?.getGroupped(params)
      .then(data => {
        const groupppedOrders = data.records as OrderGroup[];
        const updatedChartData = this.updateChartData(groupppedOrders, productLine);

        this.setState({
          chartData: updatedChartData,
          days: groupppedOrders,
          loading: false,
        });
      })
      .catch(error => {
        this.setState({loading: false});
        console.log(error);
      });
  }

  updateChartData(groupppedOrders: OrderGroup[], productLine: string) {
    const dateOrderGroups: DateOrderGroup[] = [];
    const dateTitles: string[] = [];
    const countsStandards: number[] = [];
    const countsRepairs: number[] = [];
    const countsComponent: number[] = [];
    const countsCapacity: number[] = [];

    const chartData = this.state.chartData;
    const factory = this.state.factory;

    const factoryCapacity =
      productLine === 'Colourvue'
        ? factory?.settings?.product_lines?.colourvue?.daily_capacity ?? 0
        : factory?.settings?.product_lines?.shadesol?.daily_capacity ?? 0;

    groupppedOrders
      .filter(g => g.group_key !== null)
      .map(group => {
        const dateString = group.group_key;
        const date = new Date(dateString);
        const dateOrderGroup: DateOrderGroup = {
          date: date,
          orderGroup: group,
        };
        dateOrderGroups.push(dateOrderGroup);
      });

    let minDate = new Date();
    minDate.setHours(0, 0, 0, 0);

    let maxDate = new Date(minDate);

    if (dateOrderGroups && dateOrderGroups.length > 0) {
      minDate = dateOrderGroups
        .map(g => g.date)
        .reduce((a, b) => {
          return a < b ? a : b;
        });

      maxDate = dateOrderGroups
        .map(g => g.date)
        .reduce((a, b) => {
          return a > b ? a : b;
        });
    } else {
      maxDate.setDate(1);
    }

    const allDates = this.getDates(minDate, maxDate);

    allDates.map(date => {
      const formattedDate = DateTime.fromJSDate(date).toFormat(formats.date);
      const dayName = days[date.getDay()];
      const dayTitle = `${formattedDate} (${dayName})`;
      dateTitles.push(dayTitle);

      const group = dateOrderGroups.find(g => g.date.setHours(0, 0, 0, 0) === date.setHours(0, 0, 0, 0))?.orderGroup;
      const standardOrders = group?.records.filter(r => r.type === 'Standard') ?? [];
      const sizeSumStandards = standardOrders.map(o => o.factory_order?.size ?? 0).reduce((a, b) => a + b, 0);
      countsStandards.push(sizeSumStandards ?? 0);

      const repairOrders = group?.records.filter(r => r.type === 'Repair') ?? [];
      const sizeSumRepairs = repairOrders.map(o => o.factory_order?.size).reduce((a, b) => a! + b!, 0);
      countsRepairs.push(sizeSumRepairs ?? 0);

      const componentOrders = group?.records.filter(r => r.type === 'Component') ?? [];
      const sizeSumComponents = componentOrders.map(o => o.factory_order?.size).reduce((a, b) => a! + b!, 0);
      countsComponent.push(sizeSumComponents ?? 0);

      countsCapacity.push(factoryCapacity);
    });

    const updatedChartData = {...chartData};
    updatedChartData.labels = dateTitles;

    const chartDataStandard = updatedChartData.datasets.filter((d: ChartDataSet) => d.label === 'Standard');

    const chartDataRepair = updatedChartData.datasets.filter((d: ChartDataSet) => d.label === 'Repair');

    const chartDataComponent = updatedChartData.datasets.filter((d: ChartDataSet) => d.label === 'Component');
    const chartDataCapacity = updatedChartData.datasets.filter((d: ChartDataSet) => d.label === 'Capacity');

    if (chartDataStandard[0]) {
      chartDataStandard[0].data = countsStandards;
    }

    if (chartDataRepair[0]) {
      chartDataRepair[0].data = countsRepairs;
    }

    if (chartDataComponent[0]) {
      chartDataComponent[0].data = countsComponent;
    }

    if (chartDataCapacity[0]) {
      chartDataCapacity[0].data = countsCapacity;
    }

    return updatedChartData;
  }

  getDates(startDate: Date, endDate: Date) {
    const dates = [];
    let currentDate = startDate;

    while (currentDate <= endDate) {
      dates.push(currentDate);
      const addCurentDate = new Date(currentDate);
      const updatedDate = this.addDays(addCurentDate, 1);
      currentDate = new Date(updatedDate);
    }
    return dates;
  }

  addDays(date: Date, days: number) {
    date.setDate(date.getDate() + days);
    return date;
  }

  render() {
    const {loading, chartData} = this.state;

    const data = loading ? defaultChartData : chartData;

    const getOptions = () => {
      const basicOptions = {
        maintainAspectRatio: false,
        aspectRatio: 1.5,
      };

      return {
        basicOptions,
      };
    };

    const {basicOptions} = getOptions();

    return (
      <div id={'schedule_trends_component'} className="schedule_part">
        {loading && (
          <>
            <div className="overlay">
              <ProgressSpinner />
            </div>
          </>
        )}
        <div className="p-grid p-mt-1 w-100">
          <Chart className={'w-100'} type="line" data={data} options={basicOptions} />
        </div>
      </div>
    );
  }
}

export default ScheduleTrendsComponent;
