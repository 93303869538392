import config from '../config/config';
import {ApiService, AuthService} from 'two-app-ui';
import {
  ApiListResponse,
  Bom,
  InventoryItem,
  InventoryOperationType,
  QueryParameter,
  Stock,
  SupplyItem,
  UpdateStockRequest,
} from 'two-core';

export interface InventoryOperation {
  inventory_item_id: string;
  quantity: number;
  operation_type: InventoryOperationType;
}

class InventoryService extends ApiService {
  endpoint: string;
  updateStockEndpoint: string;
  updateStockMultiEndpoint: string | null;
  updateSupplPosMultiEndpoint: string | null;
  inventoryMoveEndpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.inventoryItems;
    this.updateStockEndpoint = config().endpoints.inventoryItemsUpdateStock;
    this.updateStockMultiEndpoint = config().endpoints.inventoryItemsUpdateStockMulti;
    this.updateSupplPosMultiEndpoint = config().endpoints.inventoryItemsUpdateSupplyPoMulti;
    this.inventoryMoveEndpoint = config().endpoints.inventoryMove;
  }

  async getInventoryItems(params: QueryParameter): Promise<ApiListResponse> {
    return this.get(this.endpoint, params)
      .then(data => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async getInventoryItem(id: string): Promise<InventoryItem> {
    return this.get(this.endpoint + '/' + id)
      .then(data => {
        return Promise.resolve(data as unknown as InventoryItem);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async deleteInventoryItem(id: string): Promise<void> {
    return this.delete(this.endpoint + '/' + id)
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  }
  /*
  async updateStock(factoryId: string, data: Stock): Promise<any> {
    const user = this.getCurrentUser();
    const params: UpdateStockRequest = {
      factory_id: factoryId,
      updateRequest: [data],
      user: user.id,
    };

    return this.put(this.updateStockEndpoint, params)
      .then((data: any) => {
        return Promise.resolve(data);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
*/
  async updateStocks(factoryId: string, data: Stock[]): Promise<void> {
    if (this.updateStockMultiEndpoint !== null) {
      const user = this.getCurrentUser();
      const params: UpdateStockRequest = {
        factory_id: factoryId,
        updateRequest: data,
        user: user.id,
      };

      return this.patch(this.updateStockMultiEndpoint, params)
        .then(() => {
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    }
  }
  /*
  async updateSupplForPos(factoryId: string, data: any): Promise<any> {
    if (this.updateSupplPosMultiEndpoint !== null) {
      const params = {
        factory_id: factoryId,
        updates: data,
      };

      return this.put(this.updateSupplPosMultiEndpoint, params)
        .then((data: any) => {
          return Promise.resolve(data);
        })
        .catch(() => {
          return Promise.reject();
        });
    }
  }
*/
  async updateInventoryItem(id: string, data: InventoryItem): Promise<InventoryItem> {
    return this.patch(this.endpoint + '/' + id, data)
      .then(data => {
        return Promise.resolve(data as InventoryItem);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async createInventoryItem(data: InventoryItem): Promise<InventoryItem> {
    return this.post(this.endpoint, data)
      .then(data => {
        return Promise.resolve(data as InventoryItem);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async getSupplyItems(id: string, params: QueryParameter): Promise<SupplyItem[]> {
    return this.get(this.endpoint + '/' + id + '/supply-items', params)
      .then(data => {
        return Promise.resolve((data as ApiListResponse).records as SupplyItem[]);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async updateSupplyItem(id: string, supplyId: number, data: SupplyItem): Promise<SupplyItem> {
    return this.patch(this.endpoint + '/' + id + '/supply-items/' + supplyId, data)
      .then(data => {
        return Promise.resolve(data as SupplyItem);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async createSupplyItem(id: string, data: SupplyItem): Promise<SupplyItem> {
    return this.post(this.endpoint + '/' + id + '/supply-items', data)
      .then(data => {
        return Promise.resolve(data as SupplyItem);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async deleteSupplyItem(inventoryItemId: string, supplyItemId: number): Promise<void> {
    return this.delete(`${this.endpoint}/${inventoryItemId}/supply-items/${supplyItemId}`)
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async moveStock(boms: Bom[], operation: InventoryOperationType) {
    const inventoryRequestBatch = [];
    for (const bom of boms) {
      const inventoryRequest: InventoryOperation = {
        inventory_item_id: bom.inventory_item_id,
        quantity: bom.quantity,
        operation_type: operation,
      };
      inventoryRequestBatch.push(inventoryRequest);
    }
    return this.post(this.inventoryMoveEndpoint, {
      updateRequests: inventoryRequestBatch,
    });
  }
}

export default InventoryService;
