import {Calendar, CalendarChangeParams} from 'primereact/calendar';
import React from 'react';
import {MessageService, AppContext, ToastService, TwoDialog} from 'two-app-ui';
import PurchaseOrdersService from '../../services/PurchaseOrdersService';
import {PurchaseOrder, PurchaseOrderItem, PurchaseOrderItemPatch, PurchaseOrderPatch} from 'two-core';
import {Toast} from 'primereact/toast';
import {messages} from '../../config/messages';
import formats from '../../config/formats';
import {DateTime} from 'luxon';
import PurchaseOrderItems from './PurchaseOrderItems';
import './PurchaseOrderEditDialog.scss';

interface Props {
  purchaseOrder: PurchaseOrder;
  poItems: PurchaseOrderItem[];
  toast: React.RefObject<Toast>;
  showDialog: boolean;
  onHide: () => void;
}

interface State {
  loading: boolean;
  purchaseOrderPatch: PurchaseOrderPatch;
  formPoItems?: PurchaseOrderItem[];
}

class PurchaseOrderEditDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  purchaseOrdersService: PurchaseOrdersService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      purchaseOrderPatch: {},
    };

    this.onSave = this.onSave.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onEtaChange = this.onEtaChange.bind(this);
    this.onSentAtChange = this.onSentAtChange.bind(this);
    this.onDeliveredAtChange = this.onDeliveredAtChange.bind(this);
    this.setFormPurchaseOrders = this.setFormPurchaseOrders.bind(this);
    this.onShow = this.onShow.bind(this);
  }

  componentDidMount() {
    this.purchaseOrdersService = this.context.purchaseOrdersService;
    this.toastService = this.context.toastService;
  }

  async onSave() {
    this.setState({loading: true});
    const {purchaseOrderPatch, formPoItems} = this.state;
    const {purchaseOrder, poItems} = this.props;
    const promises = [];
    if (Object.keys(purchaseOrderPatch).length) {
      //save the PO
      promises.push(this.purchaseOrdersService?.updatePurchaseOrder(purchaseOrder.id!, purchaseOrderPatch));
    }

    const poItems2Create: PurchaseOrderItem[] = [];
    const poItems2Delete: PurchaseOrderItem[] = [];
    const poItems2Update: PurchaseOrderItem[] = [];
    for (const formPoItem of formPoItems ?? []) {
      if (formPoItem.id > 0) {
        const poItem = poItems.find(poItem => formPoItem.id === poItem.id)!;
        if (formPoItem.supply_item_id !== poItem.supply_item_id || formPoItem.quantity !== poItem.quantity) {
          poItems2Update.push(formPoItem);
        }
      } else {
        poItems2Create.push(formPoItem);
      }
    }
    for (const poItem of poItems ?? []) {
      const formPoItem = formPoItems?.find(formPoItem => formPoItem.id === poItem.id);
      if (!formPoItem) {
        poItems2Delete.push(poItem);
      }
    }
    promises.push(
      ...poItems2Create.map(poItem => this.purchaseOrdersService?.createPurchaseOrderItem(purchaseOrder.id!, poItem))
    );
    promises.push(
      ...poItems2Delete.map(poItem => this.purchaseOrdersService?.deletePurchaseOrderItem(purchaseOrder.id!, poItem.id))
    );
    promises.push(
      ...poItems2Update.map(poItem => {
        const poItemPatch: PurchaseOrderItemPatch = {
          supply_item_id: poItem.supply_item_id,
          quantity: poItem.quantity,
        };
        return this.purchaseOrdersService?.updatePurchaseOrderItem(purchaseOrder.id!, poItem.id, poItemPatch);
      })
    );

    Promise.all(promises)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, `Purchase Order ${purchaseOrder.id} updated successfully.`);
        this.onHide();
        MessageService.sendMessage(messages.purchaseOrderUpdated);
      })
      .catch(() => {
        this.toastService?.showError(
          this.props.toast,
          `Sorry, Purchase Order ${purchaseOrder.id} update failed, please try again.`
        );
      })
      .finally(() => {
        this.setState({loading: false});
      });
  }

  onHide() {
    this.props.onHide();
    this.setState({
      loading: false,
      purchaseOrderPatch: {},
    });
  }

  onShow() {
    const {poItems} = this.props;
    this.setState({
      formPoItems: poItems.map(poItem => ({...poItem})),
    });
  }

  onEtaChange(e: CalendarChangeParams) {
    this.setState({
      purchaseOrderPatch: {
        ...this.state.purchaseOrderPatch,
        eta: e.value as Date,
      },
    });
  }

  onSentAtChange(e: CalendarChangeParams) {
    this.setState({
      purchaseOrderPatch: {
        ...this.state.purchaseOrderPatch,
        sent_at: e.value as Date,
      },
    });
  }

  onDeliveredAtChange(e: CalendarChangeParams) {
    this.setState({
      purchaseOrderPatch: {
        ...this.state.purchaseOrderPatch,
        delivered_at: e.value as Date,
      },
    });
  }

  setFormPurchaseOrders(formPoItems: PurchaseOrderItem[]) {
    this.setState({formPoItems: formPoItems});
  }

  render() {
    const {purchaseOrder, poItems} = this.props;
    const {purchaseOrderPatch, formPoItems} = this.state;

    const sentAt = purchaseOrderPatch.sent_at ?? purchaseOrder.sent_at ? new Date(purchaseOrder.sent_at!) : undefined;
    const eta = purchaseOrderPatch.eta ?? purchaseOrder.eta ? new Date(purchaseOrder.eta!) : undefined;
    const dialogBody = (
      <>
        <div className="p-grid p-ai-center w-100 p-mb-2">
          <label htmlFor="vendor" className="p-col-1">
            vendor
          </label>
          <div className="p-col-3 p-p-0">
            <span className="p-col-3">{purchaseOrder.supplier?.company_name}</span>
          </div>
          <label htmlFor="stage" className="p-col-1">
            stage
          </label>
          <div className="p-col-3 p-p-0">
            <span className="p-fluid">{purchaseOrder.stage ?? 'Draft'}</span>
          </div>
        </div>
        <div className="p-grid p-ai-center w-100 p-mb-2">
          <label htmlFor="sent_at" className="p-col-1">
            order on
          </label>
          <div className="p-col-3 p-p-0">
            {purchaseOrder.stage === 'Ordered' ? (
              <Calendar
                className={'w-100'}
                value={sentAt}
                dateFormat={formats.calendarInputDate}
                name="sent_at"
                onChange={this.onSentAtChange}
              />
            ) : (
              <span className="p-fluid">
                {sentAt ? DateTime.fromJSDate(new Date(sentAt)).toFormat(formats.date) : ''}
              </span>
            )}
          </div>

          <label htmlFor="eta" className="p-col-1">
            eta
          </label>
          <div className="p-col-3 p-p-0">
            {purchaseOrder.stage === 'Ordered' ||
            purchaseOrder.stage === 'Eta Confirmed' ||
            purchaseOrder.stage === 'Delayed' ||
            purchaseOrder.stage === 'Partially Delivered' ? (
              <Calendar
                className={'w-100'}
                value={eta}
                dateFormat={formats.calendarInputDate}
                name="eta"
                onChange={this.onEtaChange}
              />
            ) : (
              <span className="p-fluid">{eta ? DateTime.fromJSDate(new Date(eta)).toFormat(formats.date) : ''}</span>
            )}
          </div>

          <label htmlFor="delivered_at" className="p-col-1">
            delivered
          </label>
          <div className="p-col-3 p-p-0">
            {purchaseOrder.stage === 'Delivered' ? (
              <Calendar
                className={'w-100'}
                value={purchaseOrder.delivered_at ? new Date(purchaseOrder.delivered_at) : undefined}
                dateFormat={formats.calendarInputDate}
                name="delivered_at"
                onChange={this.onDeliveredAtChange}
              />
            ) : (
              <span className="p-fluid">
                {purchaseOrder.delivered_at
                  ? DateTime.fromJSDate(new Date(purchaseOrder.delivered_at)).toFormat(formats.date)
                  : ''}
              </span>
            )}
          </div>
        </div>
        <div className="p-grid purchase-order-items-grid w-100">
          <div className="p-col-12 h-100">
            <PurchaseOrderItems
              purchaseOrder={purchaseOrder}
              toast={this.props.toast}
              poItems={formPoItems ?? poItems}
              setFormPoItems={this.setFormPurchaseOrders}
              editable={true}
              onNewPoCreated={this.onHide}
              itemsTableId="purchase_order_edit_dialog_items_id"
            />
          </div>
        </div>
      </>
    );

    return (
      <>
        <TwoDialog
          headerTitle={`Edit PO ${purchaseOrder.id ?? ''}`}
          showDialog={this.props.showDialog}
          visible={this.props.showDialog}
          style={{width: '70vw'}}
          breakpoints={{'1920px': '90vw'}}
          onHide={this.onHide}
          onSave={this.onSave}
          onShow={this.onShow}
          loading={this.state.loading}
          className={'purchase-order-edit-dialog'}
        >
          {dialogBody}
        </TwoDialog>
      </>
    );
  }
}

export default PurchaseOrderEditDialog;
