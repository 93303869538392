import React from 'react';
import {History} from 'history';
import {Card} from 'primereact/card';
import {AppContext} from 'two-app-ui';
import {InventoryItem} from 'two-core';
import OosReferenceComponent from '../Reference/OosReferenceComponent';
import OosService from '../../services/OosService';

interface Props {
  inventoryItem: InventoryItem;
  history: History;
}

class InventoryItemDetail extends React.Component<Props, {}> {
  static contextType = AppContext;
  oosService: OosService | null = null;

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.oosService = this.context.oosService;
  }

  render() {
    const inventoryItem = this.props.inventoryItem;

    return (
      <Card className={'p-d-flex order-detail-item'}>
        <div className="p-d-flex">
          <label htmlFor="product_lines" className="p-col-12 p-md-3">
            product line
          </label>
          <div className="p-col-12 p-md-9">
            <span>{inventoryItem.product_lines?.join(',') ?? ''}</span>
          </div>
        </div>
        <div className="p-d-flex">
          <label htmlFor="category" className="p-col-12 p-md-3">
            category
          </label>
          <div className="p-col-12 p-md-9">
            <span>{inventoryItem.category ?? ''}</span>
          </div>
        </div>
        <div className="p-d-flex">
          <label htmlFor="type" className="p-col-12 p-md-3">
            type
          </label>
          <div className="p-col-12 p-md-9">
            <span>{inventoryItem.type ?? ''}</span>
          </div>
        </div>
        <div className="p-d-flex">
          <label htmlFor="sku" className="p-col-12 p-md-3">
            sku
          </label>
          <div className="p-col-12 p-md-9">
            <span>{inventoryItem.sku ?? ''}</span>
          </div>
        </div>
        <div className="p-d-flex">
          <label htmlFor="name" className="p-col-12 p-md-3">
            colour
          </label>
          <div className="p-col-12 p-md-9">
            <span>{inventoryItem.colour ?? ''}</span>
          </div>
        </div>
        <div className="p-d-flex">
          <label htmlFor="name" className="p-col-12 p-md-3">
            uom
          </label>
          <div className="p-col-12 p-md-9">
            <span>{inventoryItem.uom ?? ''}</span>
          </div>
        </div>
        <div className="p-d-flex">
          <label htmlFor="name" className="p-col-12 p-md-3">
            reorder limit
          </label>
          <div className="p-col-12 p-md-9">
            <span>{inventoryItem.reorder_limit ?? ''}</span>
          </div>
        </div>
        <div className="p-d-flex">
          <label htmlFor="name" className="p-col-12 p-md-3">
            current stock
          </label>
          <div className="p-col-12 p-md-9">
            <span>{inventoryItem.current_stock_level ?? ''}</span>
          </div>
        </div>
        <div className="p-d-flex">
          <label htmlFor="name" className="p-col-12 p-md-3">
            reserved
          </label>
          <div className="p-col-12 p-md-9">
            <span>{inventoryItem.reserved_qty ?? ''}</span>
          </div>
        </div>
        {inventoryItem.out_of_stocks &&
        inventoryItem.out_of_stocks.length > 0 &&
        inventoryItem.out_of_stocks[0] !== null ? (
          <div className="p-d-flex">
            <label htmlFor="oos" className="p-col-12 p-md-3">
              oos
            </label>
            <div className="p-col-12 p-md-9">
              <div className="p-d-flex p-flex-row p-flex-wrap">
                <OosReferenceComponent
                  identifier={inventoryItem.id ?? ''}
                  oosRecords={inventoryItem.out_of_stocks}
                  oosInventoryItems={[this.props.inventoryItem]}
                  handleOosReferenceClick={oos => {
                    this.props.history.push(`/oos-item/${oos.id}`);
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </Card>
    );
  }
}

export default InventoryItemDetail;
