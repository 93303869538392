import React, {Fragment} from 'react';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import OrdersService from '../../../services/OrdersService';
import {DateTime} from 'luxon';
import {Subscription} from 'rxjs';
import {Toast} from 'primereact/toast';
import {
  AppColumnMenuBodyTemplate,
  AppMenuItem,
  AppMenuItemTemplate,
  MessageService,
  TwoDataTable,
  TwoMessage,
  ToastService,
  UsersService,
  TwoDialog,
  TleReferenceComponent,
} from 'two-app-ui';
import {MenuItem, MenuItemOptions} from 'primereact/menuitem';
import {AppContext} from 'two-app-ui';
import DateColumnFilter, {DateColumnFilterChangeEvent} from '../../DateColumnFilter/DateColumnFilter';
import {MultiSelect} from 'primereact/multiselect';
import {
  OtherMenuOptions,
  PrintMenuOptions,
  priorities,
  StageMenuOptions,
  stages,
} from '../../Order/Constants/constants';
import {formats} from '../../../config/formats';
import {
  Order,
  Supplier,
  QueryParameter,
  OrderType,
  User,
  MapOf,
  ProductionStageM2cAuShades,
  FactoryOrderPatch,
  TleContentStageTransition,
  TimeLineEvent,
  DropdownOption,
  PurchaseOrder,
  TleContentNote,
  OrderPatch,
  CutSheetReport,
} from 'two-core';
import values from '../../../config/values';
import '../../../scss/CustomTable.scss';
import PoReferenceComponent from '../../Reference/PoReferenceComponent';
import {
  faArrowCircleRight,
  faCheck,
  faEnvelope,
  faPen,
  faPrint,
  faStickyNote,
} from '@fortawesome/pro-regular-svg-icons';
import {library} from '@fortawesome/fontawesome-svg-core';
import {messages} from '../../../config/messages';
import {DropdownChangeParams} from 'primereact/dropdown';
import {InputSwitchChangeParams} from 'primereact/inputswitch';
import {toInputUppercase} from '../../Inventory/Constants/Utils';
import FactoriesService from '../../../services/FactoriesService';
import EditDateDialog from './EditDateDialog';
import OrderNoteDialog from '../../Order/OrderNoteDialog';
import BomService from '../../../services/BomService';
import FactoryOrderService from '../../../services/FactoryOrdersService';
import FactoryOrdersService from '../../../services/FactoryOrdersService';
import OrderStockDialog from '../../OrderStock/OrderStockDialog';
import {NavLink} from 'react-router-dom';
import PurchaseOrdersService from '../../../services/PurchaseOrdersService';
import TleService from '../../../services/TleService';
import ToOnHoldDialog from '../../Orders/ToOnHoldDialog';
import {Tooltip} from 'primereact/tooltip';
import DocumentsService from '../../../services/DocumentsService';
import CuttingSheets from '../../CuttingSheets/CuttingSheets';
import {Dialog} from 'primereact/dialog';
import ShipmentComponent from '../../Shipment/ShipmentComponent';
import {OrderStageComponent} from '../../Order/OrderStageComponent';
import {getCurrentUserId} from '../../../utils/UserUtil';
import {getNewProductionStagesPatch} from '../../../utils/FactoryOrderUtil';

library.add(faPen);

interface Props {
  type: OrderType;
  stageMenuOptions?: StageMenuOptions[];
}

interface State {
  loading: boolean;
  items: Order[];
  filteredItems: Order[];
  totalItems: number;
  selectedItems: Order[];
  activeFilters: {};
  dateName: string;
  filters: {
    order_code: string;
    size: number | undefined;
    reference: string;
    priority: string;
    type: string;
    customer_name: string;
    stage: string;
    approved_at: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  poSuppliers: Supplier[];
  showChangeEcdDialog: boolean;
  showReleaseDialog: boolean;
  error: boolean;
  users: User[];
  showNoteDialog: boolean;
  showHoldDialog: boolean;
  onHoldReason: string;
  printableData: CutSheetReport[] | undefined;
  prePowderOnly: boolean;
  showPurchaseOrderDialog: boolean;
  showShipmentDialog: boolean;
  showRemoveDialog: boolean;
}

class ScheduleGroupOrderListComponent extends React.Component<Props, State> {
  static contextType = AppContext;

  ordersService: OrdersService | null = null;
  factoriesService: FactoriesService | null = null;
  toastService: ToastService | null = null;
  usersService: UsersService | null = null;
  bomService: BomService | null = null;
  factoryOrderService: FactoryOrderService | null = null;
  factoryOrdersService: FactoryOrdersService | null = null;
  purchaseOrdersService: PurchaseOrdersService | null = null;
  tleService: TleService | null = null;
  documentsService: DocumentsService | null = null;

  subscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      items: [],
      filteredItems: [],
      totalItems: 0,
      loading: false,
      selectedItems: [],
      activeFilters: {},
      dateName: '',
      filters: {
        order_code: '',
        size: undefined,
        reference: '',
        priority: '',
        type: '',
        customer_name: '',
        stage: '',
        approved_at: {
          fromDate: null,
          toDate: null,
        },
      },
      pagination: {
        pageSize: 500,
        offset: 0,
      },
      poSuppliers: [],
      showChangeEcdDialog: false,
      showReleaseDialog: false,
      error: false,
      users: [],
      showNoteDialog: false,
      showHoldDialog: false,
      onHoldReason: '',
      printableData: undefined,
      prePowderOnly: false,
      showPurchaseOrderDialog: false,
      showShipmentDialog: false,
      showRemoveDialog: false,
    };

    this.toast = React.createRef();

    this.setChangeSelectedItems = this.setChangeSelectedItems.bind(this);
    this.codeBodyTemplate = this.codeBodyTemplate.bind(this);
    this.refsBodyTemplate = this.refsBodyTemplate.bind(this);
    this.poReferenceTemplate = this.poReferenceTemplate.bind(this);
    this.lastNoteBodyTemplate = this.lastNoteBodyTemplate.bind(this);
    this.ecdBodyTemplate = this.ecdBodyTemplate.bind(this);
    this.scheduledForBodyTemplate = this.scheduledForBodyTemplate.bind(this);
    this.stageBodyTemplate = this.stageBodyTemplate.bind(this);
    this.prioBodyTemplate = this.prioBodyTemplate.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.initMenuItems = this.initMenuItems.bind(this);
    this.setChangePoSuppliers = this.setChangePoSuppliers.bind(this);
    this.loadElementHeight = this.loadElementHeight.bind(this);
    this.getOrderListElements = this.getOrderListElements.bind(this);
    this.approvedAtBodyTemplate = this.approvedAtBodyTemplate.bind(this);
    this.handleOnHoldReasonChange = this.handleOnHoldReasonChange.bind(this);
    this.closeHoldDialog = this.closeHoldDialog.bind(this);
    this.stageChangeToOnHold = this.stageChangeToOnHold.bind(this);
    this.closeShipmentDialog = this.closeShipmentDialog.bind(this);
  }

  async componentDidMount() {
    this.ordersService = this.context.ordersService;
    this.factoriesService = this.context.factoriesService;
    this.toastService = this.context.toastService;
    this.usersService = this.context.usersService;
    this.bomService = this.context.bomService;
    this.factoryOrderService = this.context.factoryOrderService;
    this.factoryOrdersService = this.context.factoryOrdersService;
    this.purchaseOrdersService = this.context.purchaseOrdersService;
    this.tleService = this.context.tleService;
    this.documentsService = this.context.documentsService;

    this.subscription = MessageService.getMessage().subscribe(async message => {
      if (
        message === messages.ordersUpdated ||
        message === messages.scheduleFilterChanged ||
        message === messages.orderNoteCreated
      ) {
        this.loadData();
      } else if (message === messages.orderStageChangeReady) {
        this.checkStageChangeReady();
      } else {
        const castedMessage = message as TwoMessage;
        if (castedMessage.name && castedMessage.name === 'top-selection-changed') {
          await localStorage.setItem('current factory', castedMessage.value as string);
          this.loadData();
        }
      }
    });

    this.loadData();
    this.loadUsers();

    MessageService.sendMessage(messages.heightChanged);
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  async checkStageChangeReady() {
    this.setState({
      loading: true,
    });

    const orders = this.state.selectedItems;
    orders.map(async order => {
      let orderStage: ProductionStageM2cAuShades = 'Ready';
      const poFilters: string[] = [];
      poFilters.push(
        JSON.stringify({
          field: 'purchase_for_order.order_id',
          value: order.id,
        }),
        JSON.stringify({
          field: 'purchase_order.stage',
          value: 'Delivered',
          condition: '<>',
        })
      );
      const params: QueryParameter = {
        filters: poFilters,
        aggregate: true,
      };
      await this.purchaseOrdersService?.getPurchaseOrders(params).then(data => {
        const dataRecords = (data.records as PurchaseOrder[]) ?? [];
        if (dataRecords.length > 0) {
          orderStage = 'Waiting 4 Material';
        }
      });

      return this.handleStageChangeRequest([order], orderStage).then(() => {
        this.setState({
          loading: false,
        });
        this.loadData();
      });
    });
  }

  loadElementHeight(): string {
    let totalHeight = 0;
    const componentId = 'schedule_content_component';
    const element = document?.getElementById(componentId);

    if (element) {
      const elementHeight = element?.clientHeight;
      const accordionHeaders = 5 * 50;

      const elementPaginator = document?.getElementsByClassName('p-paginator')[0];

      const elementPaginatorDomRect = elementPaginator?.getBoundingClientRect();

      const elementTrendsHeight = document?.getElementsByClassName('trends-acc-content')[0] ? 225 : 0;

      const elementSettingsHeight = document?.getElementsByClassName('settings-acc-content')[0] ? 259 : 0;

      const otherElementsHeight =
        elementPaginatorDomRect?.height + accordionHeaders + elementTrendsHeight + elementSettingsHeight;

      const sizeIdentifiers = this.getOrderListElements();
      let sizeIdentifiersHeight = 0;

      if (sizeIdentifiers && sizeIdentifiers.length > 1) {
        const tableElementSize = 300;

        sizeIdentifiersHeight = (sizeIdentifiers.length - 1) * tableElementSize;
      }

      totalHeight = elementHeight - otherElementsHeight - sizeIdentifiersHeight;

      if (totalHeight <= 0 || totalHeight < 300) {
        totalHeight = 300;
      }
    }
    return totalHeight + 'px';
  }

  getOrderListElements() {
    const elements = [];
    const componentElement = document?.getElementById('schedule_orders_component_component');
    if (componentElement) {
      elements.push(componentElement);
    }

    const repairElement = document?.getElementById('schedule_orders_component_repair');
    if (repairElement) {
      elements.push(repairElement);
    }

    const standardElement = document?.getElementById('schedule_orders_component_standard');
    if (standardElement) {
      elements.push(standardElement);
    }

    return elements;
  }

  handleError(error: string): void {
    console.log(error);
    if (!this.state.error) {
      this.toastService?.showError(this.toast, error);

      this.setState({error: true});
    }
  }

  initMenuItems(): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];

    const selectedOrders = this.state.selectedItems;
    const selectedOrdersCount = selectedOrders.length;
    const withProductChainOnly = selectedOrders.every(o => o.factory_order?.factory_chain);
    const withoutProductChainOnly = selectedOrders.every(o => !o.factory_order?.factory_chain);
    const currentFactoryId = localStorage.getItem('current factory') ?? '';

    if (selectedOrdersCount && (withProductChainOnly || withoutProductChainOnly)) {
      const hasComponentType = selectedOrders.some(o => o.type === 'Component');
      const allOrdersFinalFactory = selectedOrders.every(
        o => o.factory_order?.factory_id === localStorage.getItem('current factory')
      );
      const currentFactorySelectedStages = Array.from(
        new Set(
          selectedOrders.map(o =>
            withProductChainOnly
              ? o.factory_order!.factory_chain![currentFactoryId]!.stage
              : o.factory_order!.production_stage
          )
        ).values()
      ) as ProductionStageM2cAuShades[];

      this.initPrintMenuItems(menuItems, selectedOrders, currentFactorySelectedStages, hasComponentType);
      this.initSeparatorMenuItem(menuItems);
      this.initStageMenuItems(
        menuItems,
        selectedOrders,
        currentFactorySelectedStages,
        hasComponentType,
        withProductChainOnly,
        allOrdersFinalFactory
      );
      if (allOrdersFinalFactory) {
        this.initSeparatorMenuItem(menuItems);
        this.initOtherMenuItems(menuItems, currentFactorySelectedStages);
      }
    }
    return menuItems;
  }

  initSeparatorMenuItem(menuItems: MenuItem[]) {
    menuItems.push({
      separator: true,
    });
  }

  initPrintMenuItems(
    menuItems: AppMenuItem[],
    selectedOrders: Order[],
    currentFactorySelectedStages: ProductionStageM2cAuShades[],
    hasComponentType: boolean
  ) {
    const printOptions = Object.values(PrintMenuOptions);
    if (printOptions?.length) {
      if (
        printOptions.includes(PrintMenuOptions.AluCutSheets) &&
        currentFactorySelectedStages.every(stage => ['Alu Needs Cutting', 'Alu Cutting'].includes(stage))
      ) {
        menuItems.push({
          label: PrintMenuOptions.AluCutSheets,
          faIcon: faPrint,
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.printCutSheetsForSelectedOrders(true);
          },
        });
      }

      if (
        printOptions.includes(PrintMenuOptions.AluPo) &&
        currentFactorySelectedStages.every(stage => stage === 'Alu Ordered') &&
        selectedOrders.length === 1
      ) {
        menuItems.push({
          label: PrintMenuOptions.AluPo,
          faIcon: faPrint,
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => this.setState({showPurchaseOrderDialog: true}),
          disabled: hasComponentType,
        });
      }

      if (
        printOptions.includes(PrintMenuOptions.AluPo) &&
        currentFactorySelectedStages.every(stage => stage === 'Alu Required') &&
        selectedOrders.length === 1
      ) {
        menuItems.push({
          label: PrintMenuOptions.AluPo,
          faIcon: faPrint,
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => this.setState({showPurchaseOrderDialog: true}),
          disabled: hasComponentType,
        });
      }

      if (printOptions.includes(PrintMenuOptions.CutSheets)) {
        menuItems.push({
          label: PrintMenuOptions.CutSheets,
          faIcon: faPrint,
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.printCutSheetsForSelectedOrders(false);
          },
        });
      }

      if (
        printOptions.includes(PrintMenuOptions.PowderPO) &&
        currentFactorySelectedStages.every(stage => stage === 'Powder Ordered') &&
        selectedOrders.length === 1
      ) {
        menuItems.push({
          label: PrintMenuOptions.PowderPO,
          faIcon: faPrint,
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => this.setState({showPurchaseOrderDialog: true}),
          disabled: hasComponentType,
        });
      }

      if (
        printOptions.includes(PrintMenuOptions.PowderPO) &&
        currentFactorySelectedStages.every(stage => stage === 'Powder Required') &&
        selectedOrders.length === 1
      ) {
        menuItems.push({
          label: PrintMenuOptions.PowderPO,
          faIcon: faPrint,
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => this.setState({showPurchaseOrderDialog: true}),
          disabled: hasComponentType,
        });
      }

      if (printOptions.includes(PrintMenuOptions.ShippingLabels)) {
        menuItems.push({
          label: PrintMenuOptions.ShippingLabels,
          faIcon: faPrint,
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.setState({showShipmentDialog: true});
          },
        });
      }
    }
  }

  initOtherMenuItems(menuItems: AppMenuItem[], currentFactorySelectedStages: ProductionStageM2cAuShades[]) {
    const otherOptions = Object.values(OtherMenuOptions);

    if (otherOptions?.length) {
      menuItems.push({
        label: 'Change ECD',
        faIcon: faPen,
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.setState({showChangeEcdDialog: true});
        },
      });

      // menuItems.push({
      //   label: 'Change Release Date',
      //   faIcon: faPen,
      //   template: (item: AppMenuItem, options: MenuItemOptions) => {
      //     return <AppMenuItemTemplate item={item} options={options} />;
      //   },
      //   command: () => {
      //     this.setState({showReleaseDialog: true});
      //   },
      // });

      if (otherOptions.includes(OtherMenuOptions.RecordNote)) {
        menuItems.push({
          label: OtherMenuOptions.RecordNote,
          faIcon: faStickyNote,
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.setState({showNoteDialog: true});
          },
        });
      }

      if (
        otherOptions.includes(OtherMenuOptions.CheckStock) &&
        currentFactorySelectedStages.every(stage => stage === 'New')
      ) {
        menuItems.push({
          label: OtherMenuOptions.CheckStock,
          faIcon: faCheck,
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.showOrderStockAvailibityCheck();
          },
        });
      }

      if (otherOptions.includes(OtherMenuOptions.RemoveFromPO)) {
        menuItems.push({
          label: OtherMenuOptions.RemoveFromPO,
          faIcon: ['far', 'times'],
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.showRemoveDialog();
          },
        });
      }

      if (
        otherOptions.includes(OtherMenuOptions.SendPickUpEmail) &&
        currentFactorySelectedStages.every(stage => stage === 'Waiting For Return')
      ) {
        menuItems.push({
          label: OtherMenuOptions.SendPickUpEmail,
          faIcon: faEnvelope,
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            //TODO: pick-up email command to be designed and implemented
          },
        });
      }
    }
  }

  async loadData() {
    this.setState({loading: true});
    const filters: string[] = [];

    const value = localStorage.getItem('filter by date') ?? 'ecd';
    const dateName = value === 'ecd' ? 'factory_order.ecd' : 'factory_order.scheduled_for';

    this.setState({
      dateName: dateName,
    });

    const productLine = localStorage.getItem('schedule product line') ?? 'Colourvue';

    const fromDateValue = localStorage.getItem('from date');
    const fromDate = fromDateValue && fromDateValue !== '' ? new Date(fromDateValue) : null;
    const toDateValue = localStorage.getItem('to date');

    const toDate = toDateValue && toDateValue !== '' ? new Date(toDateValue) : null;

    const stringValue = localStorage.getItem('show done') ?? '';
    const showDoneOrders = stringValue ? JSON.parse(stringValue) : false;

    filters.push(
      JSON.stringify({
        field: 'factory_order.product_line',
        value: productLine,
      })
    );

    filters.push(
      JSON.stringify({
        field: 'type',
        value: this.props.type,
      })
    );

    const stageExclusions = ['Cancelled'];

    filters.push(
      JSON.stringify({
        field: 'factory_order.production_stage',
        value: stageExclusions,
        condition: 'notIn',
      })
    );

    if (this.state.filters.order_code) {
      filters.push(
        JSON.stringify({
          field: 'id',
          value: this.state.filters.order_code,
          condition: 'like',
        })
      );
    }

    if (this.state.filters.reference) {
      filters.push(
        JSON.stringify({
          field: 'reference',
          value: this.state.filters.reference,
          condition: 'iLike',
        })
      );
    }

    if (this.state.filters.type && this.state.filters.type.length) {
      filters.push(
        JSON.stringify({
          field: 'type',
          value: this.state.filters.type,
          condition: 'in',
        })
      );
    }

    if (fromDate) {
      const from = DateTime.fromJSDate(fromDate).toISODate();
      filters.push(
        JSON.stringify({
          field: dateName,

          value: from,
          condition: '>=',
        })
      );
    }
    if (toDate) {
      const to = DateTime.fromJSDate(toDate).toISODate();
      filters.push(
        JSON.stringify({
          field: dateName,
          value: to,
          condition: '<=',
        })
      );
    }

    if (this.state.filters.stage?.length) {
      if (productLine === 'Curtains') {
        // only the curtains have the factory chain
        filters.push(
          JSON.stringify({
            field: `factory_order.factory_chain->'${localStorage.getItem('current factory')}'->>'stage'`,
            value: this.state.filters.stage,
            condition: 'in',
          })
        );
      } else {
        // chosen other product line or all product lines
        filters.push(
          JSON.stringify({
            orConditions: [
              {
                field: 'factory_order.production_stage',
                value: this.state.filters.stage,
                condition: 'in',
              },
              {
                field: `factory_order.factory_chain->'${localStorage.getItem('current factory')}'->>'stage'`,
                value: this.state.filters.stage,
                condition: 'in',
              },
            ],
          })
        );
      }
    }

    if (this.state.filters.priority && this.state.filters.priority.length) {
      filters.push(
        JSON.stringify({
          field: 'priority',
          value: this.state.filters.priority,
          condition: 'in',
        })
      );
    }

    if (this.state.filters.size) {
      filters.push(
        JSON.stringify({
          field: 'factory_order.size',
          value: this.state.filters.size,
        })
      );
    }

    if (this.state.filters.customer_name && this.state.filters.customer_name.length > 0) {
      filters.push(
        JSON.stringify({
          field: 'company.account_number',
          value: this.state.filters.customer_name,
          condition: 'like',
        })
      );
    }

    if (this.state.filters.approved_at.fromDate) {
      const fromDate = this.state.filters.approved_at.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'approved_at',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.approved_at.toDate) {
      const toDate = this.state.filters.approved_at.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'approved_at',
          value: toDate,
          condition: '<=',
        })
      );
    }

    this.setState({activeFilters: {...filters}});

    const sortBy = {
      field: dateName,
      direction: 'ASC',
    };

    const sortByStringyfied = JSON.stringify(sortBy);

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: [sortByStringyfied],
      aggregate: true,
    };

    this.ordersService
      ?.getOrders(params)
      .then((extendedData: unknown) => {
        const data = extendedData as {
          records: Order[];
          total_records: number;
          total_size: number;
        };
        const dataRecords = data.records ?? [];
        let filteredRecords: any[];

        if (showDoneOrders) {
          filteredRecords = dataRecords;
        } else {
          filteredRecords = dataRecords.filter(
            o => o.factory_order?.production_stage !== 'Done' && o.factory_order?.production_stage !== 'Post Fix Return'
          );
        }

        this.handleSelectedItems(dataRecords);
        this.setState({
          items: dataRecords,
          filteredItems: filteredRecords,
          totalItems: data.total_records ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.handleError('Records load failed');
        this.setState({loading: false});
        console.log(error);
      });
  }

  loadUsers() {
    const params: QueryParameter = {
      filters: [],
      aggregate: false,
    };

    this.usersService
      ?.getUsers(params)
      .then(data => {
        const users: User[] = (data?.records as User[]) ?? [];

        this.setState({
          users: users,
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  async onFilterChange(
    e:
      | React.ChangeEvent<HTMLInputElement>
      | DropdownChangeParams
      | InputSwitchChangeParams
      | DateColumnFilterChangeEvent
  ) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  handleSelectedItems(allItems: Order[]) {
    const selectedItems = [...this.state.selectedItems];
    const items: Order[] = allItems.filter(item => {
      return selectedItems.find(selectedItem => {
        return selectedItem.id === item.id;
      });
    });

    this.setChangeSelectedItems(items);
  }

  setChangeSelectedItems(items: Order[]) {
    this.setState({selectedItems: items});
  }

  setChangePoSuppliers(suppliers: Supplier[]) {
    this.setState({poSuppliers: suppliers});
  }

  async printCutSheetsForSelectedOrders(prePowderOnly: boolean) {
    const selectedOrders: string[] = [];

    for (const order of this.state.selectedItems) {
      if (order.id) {
        selectedOrders.push(order.id);
      }
    }

    const printableData = await this.documentsService?.getCuttingSheets(selectedOrders);

    await this.setState({
      printableData: printableData,
      prePowderOnly: prePowderOnly,
    });
    MessageService.sendMessage('print-cutting-sheets');
  }

  closeShipmentDialog() {
    this.setState({showShipmentDialog: false});
  }

  async showOrderStockAvailibityCheck() {
    MessageService.sendMessage(messages.orderStockCheck);
  }

  showRemoveDialog() {
    this.setState({showRemoveDialog: true});
  }

  hideRemoveDialog() {
    this.setState({showRemoveDialog: false});
  }

  codeBodyTemplate(rowData: Order) {
    return (
      <React.Fragment>
        <AppColumnMenuBodyTemplate
          rowItemIdentifier={rowData?.id?.toString() ?? ''}
          isDynamicMenuItems={true}
          initMenuItems={() => this.initMenuItems()}
          selectedItems={this.state.selectedItems}
          handleChangeSelectedItems={() => this.setChangeSelectedItem(rowData)}
        >
          <NavLink to={'/order/' + rowData.id}>{rowData.id}</NavLink>
        </AppColumnMenuBodyTemplate>
      </React.Fragment>
    );
  }

  async setChangeSelectedItem(item: Order) {
    const items = [...this.state.selectedItems];
    const existingItem = items.find(i => i.id === item.id);
    if (!existingItem) {
      items.push(item);
      this.setState({selectedItems: items});
    }
  }

  stageBodyTemplate(order: Order) {
    const currentFactoryId = localStorage.getItem('current factory') ?? '';
    return <OrderStageComponent order={order} currentFactoryId={currentFactoryId} />;
  }

  prioBodyTemplate(rowData: Order) {
    let priorityText = '';
    switch (Number(rowData.priority)) {
      case 1:
        priorityText = '!';
        break;
      case 2:
        priorityText = '!!';
        break;
      case 3:
        priorityText = '!!!';
        break;
    }
    return <span className={`p-p-2 stage-badge prio-${rowData.priority}`}>{priorityText}</span>;
  }

  lastNoteBodyTemplate(rowData: Order) {
    const tle = rowData.last_note;
    if (tle) {
      const user = this.state.users.find(u => u.id === tle.recorded_by);
      return <TleReferenceComponent identifier={rowData.id ?? ''} value={tle} user={user} />;
    } else {
      return <></>;
    }
  }

  refsBodyTemplate(rowData: Order) {
    return <div className="p-d-flex p-flex-row p-flex-wrap">{this.poReferenceTemplate(rowData)}</div>;
  }

  poReferenceTemplate(rowData: Order) {
    return (
      <PoReferenceComponent
        key={'PO-' + rowData.id}
        identifier={rowData.id ?? ''}
        purchaseOrders={rowData.purchase_orders ?? []}
        suppliers={this.state.poSuppliers}
        handleChangePoSupplier={suppliers => this.setChangePoSuppliers(suppliers)}
      />
    );
  }

  ecdBodyTemplate(rowData: Order) {
    const formated_ecd = rowData.factory_order?.ecd
      ? DateTime.fromISO(rowData.factory_order?.ecd.toString()).toFormat(formats.date)
      : '';
    return <span>{formated_ecd}</span>;
  }

  scheduledForBodyTemplate(rowData: Order) {
    const formated_scheduled_for = rowData.factory_order?.scheduled_for
      ? DateTime.fromISO(rowData.factory_order?.scheduled_for.toString()).toFormat(formats.date)
      : '';
    return <span>{formated_scheduled_for}</span>;
  }

  handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams | InputSwitchChangeParams
  ) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, values.stopTypingDetection);
  };

  async saveOrders(orders: Order[]) {
    if (orders && orders.length > 0) {
      const promises: Promise<void>[] = [];
      orders.forEach((order: Order) => {
        const promise = this.ordersService
          ?.updateOrder(order.id ?? '', order as OrderPatch)
          .then(() => {
            this.toastService?.showSuccess(this.toast, `Order ${order.id} updated successfully.`);
          })
          .catch(error => {
            this.handleError(`${order.id} update failed`);
            console.error('error: ' + error);
          });
        if (promise) {
          promises.push(promise);
        }
      });
      if (promises) {
        Promise.all(promises);
      }
    }
  }

  async replaceSummaryOfOrders(orders: Order[], replaceSummary: string) {
    const date = DateTime.fromJSDate(new Date()).toFormat(formats.date);
    const dateSummary = `&#10004; (${date})`;

    const updatedOrders = orders.map(o => {
      const oldSummary = o.summary ?? '';
      const newSummary = `${replaceSummary} ${dateSummary}`;
      o.summary = oldSummary.replace(replaceSummary, newSummary);
      return o;
    });

    this.saveOrders(updatedOrders);
  }

  async stageChangeWithSummaryReplaced(replaceSummary: string) {
    const orders = [...this.state.selectedItems];
    this.replaceSummaryOfOrders(orders, replaceSummary).then(() => this.stageChange('Ready'));
  }

  async stageChange(newStage: ProductionStageM2cAuShades, newSecondaryFactoriesStage?: ProductionStageM2cAuShades) {
    this.setState({
      loading: true,
    });
    const orders = this.state.selectedItems;
    this.handleStageChangeRequest(orders, newStage, newSecondaryFactoriesStage);
  }

  async handleStageChangeRequest(
    orders: Order[],
    newStage?: ProductionStageM2cAuShades,
    newSecondaryFactoriesStage?: ProductionStageM2cAuShades
  ) {
    let selectedAfterSave = this.state.selectedItems;

    const promises = orders.map(order => {
      const factoryOrderPatch: FactoryOrderPatch = getNewProductionStagesPatch(
        order.factory_order!,
        newStage,
        newSecondaryFactoriesStage
      );
      return this.updateFactoryOrder(factoryOrderPatch, order).then(updatedFactoryOrder => {
        if (updatedFactoryOrder) {
          this.toastService?.showSuccess(this.toast, `Stage Updated for ${updatedFactoryOrder.id} successfully.`);
          this.factoryOrderService?.doStockUpdate(
            order.factory_order!.production_stage,
            updatedFactoryOrder.production_stage,
            updatedFactoryOrder.id!
          );
          selectedAfterSave = selectedAfterSave.filter(order => {
            return order.id !== updatedFactoryOrder.id;
          });
        }
      });
    });

    Promise.all(promises)
      .then(() => {
        this.loadData();
        this.setState({selectedItems: selectedAfterSave});
      })
      .catch(error => {
        this.handleError('Order update failed');
        this.setState({
          loading: false,
        });
        console.error(error);
      });
  }

  async updateFactoryOrder(factoryOrderPatch: FactoryOrderPatch, order: Order) {
    return this.factoryOrdersService?.updateFactoryOrder(order.id ?? '', factoryOrderPatch);
  }
  initStageMenuItems(
    menuItems: AppMenuItem[],
    selectedOrders: Order[],
    currentFactorySelectedStages: ProductionStageM2cAuShades[],
    hasComponentType: boolean,
    isFactoryChainStage: boolean,
    allOrdersFinalFactory: boolean
  ) {
    const stageOptions = this.props.stageMenuOptions;

    if (stageOptions?.length) {
      const allOrdersRepair = selectedOrders.every(o => o.type === 'Repair');
      const allOrderNonRepair = selectedOrders.every(o => o.type !== 'Repair');
      const allSecondaryFactoriesFinished = selectedOrders.every(o => {
        const factoryChain = o.factory_order?.factory_chain;
        if (!factoryChain) {
          return false;
        }
        return !Object.entries(factoryChain).some(([factoryId, chainItem]) => {
          return factoryId !== o.factory_order?.factory_id && chainItem.stage !== 'Between Factories';
        });
      });
      const allSecondaryFactoriesReceived = selectedOrders.every(o => {
        const factoryChain = o.factory_order?.factory_chain;
        if (!factoryChain) {
          return false;
        }
        return !Object.entries(factoryChain).some(([factoryId, chainItem]) => {
          return (
            factoryId !== o.factory_order?.factory_id &&
            chainItem.stage !== 'Done' &&
            chainItem.stage !== 'Post Fix Return'
          );
        });
      });
      if (!currentFactorySelectedStages.includes('On Hold')) {
        if (
          stageOptions.includes(StageMenuOptions.AluCut) &&
          currentFactorySelectedStages.every(stage => stage === 'Alu Cutting')
        ) {
          menuItems.push({
            label: StageMenuOptions.AluCut,
            faIcon: faArrowCircleRight,
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
            command: () => {
              this.stageChangeWithSummaryReplaced('alu');
            },
            disabled: hasComponentType,
          });
        }

        if (
          stageOptions.includes(StageMenuOptions.AluCutRequested) &&
          currentFactorySelectedStages.every(stage => stage === 'Alu Needs Cutting')
        ) {
          menuItems.push({
            label: StageMenuOptions.AluCutRequested,
            faIcon: faArrowCircleRight,
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
            command: () => {
              this.stageChange('Alu Cutting');
            },
            disabled: hasComponentType,
          });
        }

        if (
          stageOptions.includes(StageMenuOptions.AluDelivered) &&
          currentFactorySelectedStages.every(stage => stage === 'Alu Ordered')
        ) {
          menuItems.push({
            label: StageMenuOptions.AluDelivered,
            faIcon: faArrowCircleRight,
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
            command: () => {
              this.stageChange('Alu Needs Cutting');
            },
            disabled: hasComponentType,
          });
        }

        if (
          stageOptions.includes(StageMenuOptions.AluOrdered) &&
          currentFactorySelectedStages.every(stage => stage === 'Alu Required')
        ) {
          menuItems.push({
            label: StageMenuOptions.AluOrdered,
            faIcon: faArrowCircleRight,
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
            command: () => {
              this.stageChange('Alu Ordered');
            },
            disabled: hasComponentType,
          });
        }

        if (
          stageOptions.includes(StageMenuOptions.CoatedAndDelivered) &&
          currentFactorySelectedStages.every(stage => stage === 'Powder Required')
        ) {
          menuItems.push({
            label: StageMenuOptions.CoatedAndDelivered,
            faIcon: faArrowCircleRight,
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
            command: () => {
              this.stageChangeWithSummaryReplaced('powder');
            },
            disabled: hasComponentType,
          });
        }

        if (
          stageOptions.includes(StageMenuOptions.Received) &&
          currentFactorySelectedStages.every(stage => stage === 'In Production' || stage === 'Sent To Floor') &&
          isFactoryChainStage &&
          allSecondaryFactoriesFinished
        ) {
          menuItems.push({
            label: StageMenuOptions.Received,
            faIcon: faArrowCircleRight,
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
            command: () => {
              this.onReceived();
            },
          });
        }

        if (
          stageOptions.includes(StageMenuOptions.Delivered) &&
          currentFactorySelectedStages.every(stage => stage === 'Waiting For Return')
        ) {
          menuItems.push({
            label: StageMenuOptions.Delivered,
            faIcon: faArrowCircleRight,
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
            command: () => {
              this.showAndContinueOrderStockAvailibityCheck();
            },
          });
        }

        if (
          allOrdersRepair &&
          stageOptions.includes(StageMenuOptions.WaitingForReturn) &&
          currentFactorySelectedStages.every(stage => stage === 'New')
        ) {
          menuItems.push({
            label: StageMenuOptions.WaitingForReturn,
            faIcon: faArrowCircleRight,
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
            command: () => {
              this.stageChange(StageMenuOptions.WaitingForReturn);
            },
          });
        }

        if (
          stageOptions.includes(StageMenuOptions.Delivered) &&
          currentFactorySelectedStages.every(stage => stage === 'Post Fix Return') &&
          allOrdersFinalFactory
        ) {
          menuItems.push({
            label: StageMenuOptions.Delivered,
            faIcon: faArrowCircleRight,
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
            command: () => {
              this.stageChange('Done', 'Done');
            },
          });
        }

        if (
          stageOptions.includes(StageMenuOptions.InProduction) &&
          currentFactorySelectedStages.every(stage => stage === 'Sent To Floor')
        ) {
          menuItems.push({
            label: StageMenuOptions.InProduction,
            faIcon: faArrowCircleRight,
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
            command: () => {
              this.stageChange('In Production');
            },
          });
        }

        if (
          stageOptions.includes(StageMenuOptions.NeedsAlu) &&
          currentFactorySelectedStages.every(stage => stage === 'Ready')
        ) {
          menuItems.push({
            label: StageMenuOptions.NeedsAlu,
            faIcon: faArrowCircleRight,
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
            command: () => {
              this.stageChange('Alu Required');
            },
            disabled: hasComponentType,
          });
        }

        if (
          stageOptions.includes(StageMenuOptions.NeedsPowder) &&
          currentFactorySelectedStages.every(stage => stage === 'Ready')
        ) {
          menuItems.push({
            label: StageMenuOptions.NeedsPowder,
            faIcon: faArrowCircleRight,
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
            command: () => {
              this.stageChange('Powder Required');
            },
            disabled: hasComponentType,
          });
        }

        if (
          stageOptions.includes(StageMenuOptions.PostFixReturn) &&
          (currentFactorySelectedStages.every(stage => stage === 'Waiting For Return') ||
            (currentFactorySelectedStages.every(stage => stage === 'New') && allOrdersRepair))
        ) {
          menuItems.push({
            label: StageMenuOptions.PostFixReturn,
            faIcon: faArrowCircleRight,
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
            command: () => {
              this.stageChangeWithPostFixReturnSetting();
            },
            disabled: hasComponentType,
          });
        }

        if (
          stageOptions.includes(StageMenuOptions.PowderOrdered) &&
          currentFactorySelectedStages.every(stage => stage === 'Powder Required')
        ) {
          menuItems.push({
            label: StageMenuOptions.PowderOrdered,
            faIcon: faArrowCircleRight,
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
            command: () => {
              this.stageChange('Powder Ordered');
            },
            disabled: hasComponentType,
          });
        }

        if (
          stageOptions.includes(StageMenuOptions.Printed) &&
          currentFactorySelectedStages.every(stage => stage === 'New') &&
          allOrderNonRepair
        ) {
          menuItems.push({
            label: StageMenuOptions.Printed,
            faIcon: faArrowCircleRight,
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
            command: () => {
              this.showAndContinueOrderStockAvailibityCheck();
              console.log('showAndContinueOrderStockAvailibityCheck');
            },
          });
        }

        if (
          stageOptions.includes(StageMenuOptions.MaterialAvailable) &&
          currentFactorySelectedStages.every(stage => stage === 'Waiting 4 Material')
        ) {
          menuItems.push({
            label: StageMenuOptions.MaterialAvailable,
            faIcon: faArrowCircleRight,
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
            command: () => {
              this.stageChange('Ready');
            },
          });
        }

        if (
          stageOptions.includes(StageMenuOptions.ProductionCompleted) &&
          currentFactorySelectedStages.every(stage => stage === 'Sent To Floor') &&
          (!isFactoryChainStage || !allOrdersFinalFactory || allSecondaryFactoriesReceived)
        ) {
          menuItems.push({
            label: StageMenuOptions.ProductionCompleted,
            faIcon: faArrowCircleRight,
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
            command: () => {
              this.onProductionComplete();
            },
          });
        }

        if (
          stageOptions.includes(StageMenuOptions.ProductionCompleted) &&
          currentFactorySelectedStages.every(stage => stage === 'In Production') &&
          (!isFactoryChainStage || !allOrdersFinalFactory || allSecondaryFactoriesReceived)
        ) {
          menuItems.push({
            label: StageMenuOptions.ProductionCompleted,
            faIcon: faArrowCircleRight,
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
            command: () => {
              this.onProductionComplete();
            },
          });
        }

        if (
          stageOptions.includes(StageMenuOptions.SendToFloor) &&
          currentFactorySelectedStages.every(stage => stage === 'Ready')
        ) {
          menuItems.push({
            label: StageMenuOptions.SendToFloor,
            faIcon: faArrowCircleRight,
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
            command: () => {
              this.stageChange('Sent To Floor');
            },
          });
        }

        if (
          stageOptions.includes(StageMenuOptions.OnHold) &&
          currentFactorySelectedStages.every(stage => !['Done', 'Cancelled'].includes(stage))
        ) {
          menuItems.push({
            label: StageMenuOptions.OnHold,
            faIcon: faArrowCircleRight,
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
            command: () => {
              this.setState({showHoldDialog: true});
            },
          });
        }
      }

      if (selectedOrders.length && currentFactorySelectedStages.every(stage => stage === 'On Hold')) {
        const previousState = this.state.selectedItems[0].factory_order
          ?.previos_production_stage as ProductionStageM2cAuShades;
        menuItems.push({
          label: `Return to ${previousState}`,
          faIcon: faArrowCircleRight,
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.stageChange(previousState);
          },
        });
      }
    }
  }

  async onProductionComplete() {
    const selectedOrders = this.state.selectedItems;
    const currentFactoryId = localStorage.getItem('current factory') ?? '';
    if (selectedOrders && selectedOrders.length > 0) {
      this.setState({
        loading: true,
      });

      const betweenFactoriesOrders: Order[] = [];
      const doneOrders: Order[] = [];
      const postFixReturnOrders: Order[] = [];
      for (const order of selectedOrders) {
        if (order.factory_order?.factory_id !== currentFactoryId) {
          betweenFactoriesOrders.push(order);
        } else if (order.factory_order?.post_fix_return) {
          postFixReturnOrders.push(order);
        } else {
          doneOrders.push(order);
        }
      }

      if (betweenFactoriesOrders.length) {
        await this.handleStageChangeRequest(betweenFactoriesOrders, 'Between Factories');
      }
      if (doneOrders.length) {
        await this.handleStageChangeRequest(doneOrders, 'Done');
      }
      if (postFixReturnOrders.length) {
        await this.handleStageChangeRequest(postFixReturnOrders, 'Post Fix Return');
      }
    }
  }

  async onReceived() {
    const selectedOrders = this.state.selectedItems;
    if (!selectedOrders.length) {
      return;
    }
    const doneOrders: Order[] = [];
    const postFixReturnOrders: Order[] = [];
    for (const order of selectedOrders) {
      if (order.factory_order?.post_fix_return) {
        postFixReturnOrders.push(order);
      } else {
        doneOrders.push(order);
      }
    }
    if (doneOrders.length) {
      await this.handleStageChangeRequest(doneOrders, undefined, 'Done');
    }
    if (postFixReturnOrders.length) {
      await this.handleStageChangeRequest(postFixReturnOrders, undefined, 'Post Fix Return');
    }
  }

  async stageChangeToOnHold() {
    const selectedOrders = this.state.selectedItems;
    if (selectedOrders && selectedOrders.length > 0) {
      this.setState({
        loading: true,
      });
      let selectedAfterSave = this.state.selectedItems;

      const promises = selectedOrders.map(order => {
        const factoryOrderPatch: FactoryOrderPatch = {
          production_stage: 'On Hold',
          previos_production_stage: order.factory_order?.production_stage,
        };

        factoryOrderPatch.tles_success = [
          {
            event_type: 'production_stage_transition',
            entity_id: order.id,
            entity_type: 'order',
            recorded_at: new Date(),
            recorded_by: getCurrentUserId(),
            content: {
              old_stage: order.factory_order?.production_stage,
              new_stage: 'On Hold',
              reason: this.state.onHoldReason,
            } as TleContentStageTransition,
          },
        ] as TimeLineEvent[];
        return this.updateFactoryOrder(factoryOrderPatch, order).then(updatedFactoryOrder => {
          if (updatedFactoryOrder) {
            this.toastService?.showSuccess(this.toast, `Stage Updated for ${updatedFactoryOrder.id} successfully.`);
            this.factoryOrderService?.doStockUpdate(
              order.factory_order!.production_stage,
              updatedFactoryOrder.production_stage,
              updatedFactoryOrder.id!
            );
            selectedAfterSave = selectedAfterSave.filter(order => {
              return order.id !== updatedFactoryOrder.id;
            });
          }
          const tle: TimeLineEvent = {
            event_type: 'note',
            entity_type: 'order',
            recorded_by: getCurrentUserId(),
            entity_id: order.id ?? '',
            content: {
              title: 'Order Held',
              text: this.state.onHoldReason,
            } as TleContentNote,
            recorded_at: new Date(),
          };
          this.tleService?.createTle(tle);
        });
      });

      Promise.all(promises)
        .then(() => {
          this.loadData();
          this.setState({
            selectedItems: selectedAfterSave,
            showHoldDialog: false,
          });
        })
        .catch(error => {
          this.handleError('Order update failed');
          this.setState({
            loading: false,
            showHoldDialog: false,
          });
          console.error(error);
        });
    }
  }

  closeHoldDialog() {
    this.setState({showHoldDialog: false});
  }

  handleOnHoldReasonChange(reason: string) {
    this.setState({onHoldReason: reason});
  }

  async showAndContinueOrderStockAvailibityCheck() {
    MessageService.sendMessage(messages.orderStockCheckAndContinue);
  }

  async stageChangeWithPostFixReturnSetting() {
    const orders = [...this.state.selectedItems];
    await this.setPostFixReturn(orders, true);
  }

  async setPostFixReturn(orders: Order[], postFixReturnValue: boolean) {
    const updatedOrders = orders.map(o => {
      if (o.factory_order) {
        o.factory_order.post_fix_return = postFixReturnValue;
      }
      return o;
    });

    this.saveOrders(updatedOrders).then(() => {
      this.stageChange('New');
    });
  }

  approvedAtBodyTemplate(rowData: Order) {
    const formated_approved_on = rowData.approved_at
      ? DateTime.fromISO(rowData.approved_at.toString()).toFormat(formats.date)
      : '';
    return <span>{formated_approved_on}</span>;
  }

  render() {
    const codeFilter = (
      <InputText
        name="order_code"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
        onInput={toInputUppercase}
      />
    );

    const referenceFilter = (
      <InputText
        name="reference"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const summaryFilter = (
      <InputText
        name="summary"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const selectedItemTemplate = (value: string) => {
      if (value) {
        return (
          <span className={`p-mr-1 stage-badge stage-badge-filter stage-${value.toLowerCase().replaceAll(' ', '-')}`}>
            {value}
          </span>
        );
      }

      return <></>;
    };

    const itemTemplate = (option: DropdownOption) => {
      return (
        <span className={`stage-badge stage-${(option.value as string).toLowerCase().replaceAll(' ', '-')}`}>
          {option.label}
        </span>
      );
    };

    const stageFilter = (
      <MultiSelect
        selectedItemTemplate={selectedItemTemplate}
        itemTemplate={itemTemplate}
        value={this.state.filters.stage}
        options={stages}
        name="stage"
        className="form-filter stage-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );

    const priorityFilter = (
      <MultiSelect
        value={this.state.filters.priority}
        options={priorities}
        name="priority"
        className="form-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );

    const sizeFilter = (
      <InputText
        name="size"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const customerFilter = (
      <InputText
        name="customer_name"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
        onInput={toInputUppercase}
      />
    );

    const headerTemplate = (data: Order) => {
      const dateName = this.state.dateName;
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

      const dateString = dateName === 'factory_order.ecd' ? data.factory_order?.ecd : data.factory_order?.scheduled_for;
      const date = dateString ? new Date(dateString) : undefined;
      const formattedDate = date ? DateTime.fromJSDate(date).toFormat(formats.date) : '';

      const dayName = date ? days[date.getDay()] : '';

      const items =
        dateName === 'factory_order.ecd'
          ? this.state.items.filter(o => o.factory_order?.ecd === data.factory_order?.ecd)
          : this.state.items.filter(o => o.factory_order?.scheduled_for === data.factory_order?.scheduled_for);

      const ordersSum = items.map(o => o.factory_order?.size ?? 0).reduce((a, b) => a + b, 0);

      const progress = items
        .map(o => (o.factory_order?.production_stage === 'Done' ? o.factory_order?.size ?? 0 : 0))
        .reduce((a, b) => a + b, 0);

      return (
        <div className="p-d-flex p-flex-row p-col-12 p-p-0">
          <span className="p-text-bold p-d-flex p-jc-left p-col-6 p-p-0">{`${formattedDate} (${dayName})`}</span>
          <span className="p-text-bold p-d-flex p-jc-left p-col-6 p-p-0">{`progress: ${progress}/${ordersSum}`}</span>
        </div>
      );
    };

    const approvedAtFilter = (
      <DateColumnFilter
        name="approved_at"
        value={this.state.filters.approved_at}
        onChange={e => this.onFilterChange(e)}
      />
    );

    return (
      <div id={'schedule_orders_component_' + this.props.type.toLocaleLowerCase()} className="page-container">
        <TwoDataTable
          heightToScroll={undefined}
          filterDisplay="row"
          groupRowsBy={this.state.dateName}
          rowGroupMode="subheader"
          sortMode="single"
          rowGroupHeaderTemplate={headerTemplate}
          sizeIdentifiers={undefined}
          scrollable
          selectionMode="multiple"
          selectedItems={this.state.selectedItems}
          showPaging={false}
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          handleChangeSelectedItems={items => this.setChangeSelectedItems(items as unknown as Order[])}
          loading={this.state.loading}
          value={this.state.filteredItems}
          totalRecords={this.state.totalItems}
          activeFilters={this.state.activeFilters}
          initMenuItems={this.initMenuItems}
        >
          <Column
            header="Order Code"
            field="order_code"
            body={this.codeBodyTemplate}
            filter
            filterElement={codeFilter}
            style={{width: '180px'}}
            showFilterMenu={false}
          />
          <Column
            header="Reference"
            field="reference"
            filter
            filterElement={referenceFilter}
            style={{width: '360px'}}
            showFilterMenu={false}
          />

          <Column
            header="Date"
            field="approved_at"
            body={this.approvedAtBodyTemplate}
            filter
            filterElement={approvedAtFilter}
            sortable
            style={{width: '80px'}}
            showFilterMenu={false}
          />

          <Column
            header="Stage"
            field="factory_order.production_stage"
            body={this.stageBodyTemplate}
            filter
            filterElement={stageFilter}
            sortable
            style={{width: '180px'}}
            showFilterMenu={false}
          />

          {this.state.dateName === 'factory_order.ecd' && (
            <Column
              header="ECD"
              field="ecd"
              body={this.ecdBodyTemplate}
              style={{width: '80px'}}
              showFilterMenu={false}
            />
          )}

          {this.state.dateName === 'factory_order.scheduled_for' && (
            <Column
              header="Release"
              field="scheduled_for"
              body={this.scheduledForBodyTemplate}
              style={{width: '120px'}}
              showFilterMenu={false}
            />
          )}
          <Column
            header="Prio"
            field="priority"
            body={this.prioBodyTemplate}
            filter
            filterElement={priorityFilter}
            style={{width: '80px'}}
            showFilterMenu={false}
          />
          <Column
            header="Size"
            field="factory_order.size"
            filter
            filterElement={sizeFilter}
            style={{width: '80px'}}
            showFilterMenu={false}
          />
          <Column
            header="Customer"
            field="owner_company.account_number"
            filter
            filterElement={customerFilter}
            style={{width: '150px'}}
            showFilterMenu={false}
          />

          <Column
            header="Summary"
            field="factory_order.summary"
            filter
            filterElement={summaryFilter}
            style={{width: '280px'}}
            showFilterMenu={false}
          />
          <Column header="Last Note" field="last_note" body={this.lastNoteBodyTemplate} style={{width: '280px'}} />
          <Column header="PO(s)" body={this.refsBodyTemplate} style={{width: '320px'}} showFilterMenu={false} />
        </TwoDataTable>
        <Toast ref={this.toast} />
        <TwoDialog
          className="purchaser-order-date-dialog"
          headerTitle={'On Hold'}
          showDialog={this.state.showHoldDialog}
          width={60}
          onHide={this.closeHoldDialog}
          onSave={this.stageChangeToOnHold}
          loading={this.state.loading}
        >
          <ToOnHoldDialog reason={this.state.onHoldReason} handleOnHoldReasonChange={this.handleOnHoldReasonChange} />
        </TwoDialog>
        {this.state.selectedItems.length > 0 && (
          <>
            <EditDateDialog
              showDialog={this.state.showChangeEcdDialog}
              toast={this.toast}
              onHide={() => this.setState({showChangeEcdDialog: false})}
              ordersRecords={this.state.selectedItems}
              dateName={'ecd'}
            />
            <EditDateDialog
              showDialog={this.state.showReleaseDialog}
              toast={this.toast}
              onHide={() => this.setState({showReleaseDialog: false})}
              ordersRecords={this.state.selectedItems}
              dateName={'scheduled_for'}
            />

            <OrderNoteDialog
              showDialog={this.state.showNoteDialog}
              toast={this.toast}
              onHide={() => this.setState({showNoteDialog: false})}
              selectedOrders={this.state.selectedItems}
            />
            <OrderStockDialog toast={this.toast} orders={this.state.selectedItems} />
            <CuttingSheets
              type={this.state.selectedItems[0] ? this.state.selectedItems[0].factory_order?.product_line : ''}
              data={this.state.printableData}
              prePowderOnly={this.state.prePowderOnly}
            />

            <Dialog
              header={
                this.state.items.length > 1
                  ? 'Shipping Labels for Multiple Orders'
                  : `Shipping Labels for ${this.state.items[0].id}`
              }
              visible={this.state.showShipmentDialog}
              style={{width: '80%'}}
              modal
              onHide={() => this.closeShipmentDialog()}
              className="shipment-label-dialog"
            >
              <ShipmentComponent orders={this.state.selectedItems} onHide={this.closeShipmentDialog} />
            </Dialog>
            {/*{this.props.purchaseOrder && (*/}
            {/*  <PurchaseOrderOrderRemoveDialog*/}
            {/*    purchaseOrder={this.props.purchaseOrder}*/}
            {/*    orders={this.state.selectedItems}*/}
            {/*    toast={this.toast}*/}
            {/*    showDialog={this.state.showRemoveDialog}*/}
            {/*    onHide={this.hideRemoveDialog}*/}
            {/*  />*/}
            {/*)}*/}
          </>
        )}
      </div>
    );
  }
}

export default ScheduleGroupOrderListComponent;
