import React from 'react';
import {ToggleButton, ToggleButtonChangeParams} from 'primereact/togglebutton';
import {ProgressSpinner} from 'primereact/progressspinner';

interface Props {
  title: string;
  active: number;
  late: number | undefined;
  loading: boolean;
}

interface State {
  checked: boolean;
}

class HeaderTemplate extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  setChecked(e: ToggleButtonChangeParams) {
    const value = e.value;
    this.setState({checked: value});
  }

  toggleButtonOnClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();
  }

  render() {
    const {loading} = this.props;
    return (
      <div className="header-template p-d-flex p-ai-center2">
        <span className="p-d-flex p-col-2 p-p-0 p-ai-center">{this.props.title}</span>
        <div className="p-d-flex p-col-4 p-p-0 p-ai-center">
          <label htmlFor="repair" className="p-d-flex p-mr-1">
            Active
          </label>

          {loading ? (
            <div className="p-d-flex p-flex-row p-ai-center">
              <ProgressSpinner className="active-progress-spinner" />
            </div>
          ) : (
            <span className="p-d-flex">{this.props.active}</span>
          )}
        </div>
        <div className="p-d-flex p-col-4 p-p-0 p-ai-center">
          <div onClick={e => this.toggleButtonOnClick(e)}>
            <ToggleButton
              checked={this.state.checked}
              onLabel=""
              offLabel=""
              onChange={e => this.setChecked(e)}
              onIcon="pi pi-filter"
              offIcon="pi pi-filter"
              className="p-mr-1"
            />
          </div>

          <label htmlFor="repair" className="p-d-flex p-mr-1">
            Late
          </label>
          <span className="p-d-flex">{this.props.late ?? '?'}</span>
        </div>
      </div>
    );
  }
}

export default HeaderTemplate;
