import {DateTime} from 'luxon';
import {Column} from 'primereact/column';
import {DataTablePageParams, DataTableSortOrderType, DataTableSortParams} from 'primereact/datatable';
import {InputText} from 'primereact/inputtext';
import {MenuItemOptions} from 'primereact/menuitem';
import {MultiSelect, MultiSelectChangeParams} from 'primereact/multiselect';
import {Toast} from 'primereact/toast';
import React from 'react';
import {
  AppColumnMenuBodyTemplate,
  AppContext,
  AppMenuItem,
  AppMenuItemTemplate,
  MessageService,
  TwoDataTable,
  ToastService,
  TwoMessage,
  UsersService,
  TleReferenceComponent,
} from 'two-app-ui';
import {DropdownOption, FactoryOrderPatch, Oos, OosPatch, Order, QueryParameter, User} from 'two-core';
import {productLines} from '../../config/factoryConstants';
import formats from '../../config/formats';
import values from '../../config/values';
import OosService from '../../services/OosService';
import OrdersService from '../../services/OrdersService';
import DateColumnFilter, {DateColumnFilterChangeEvent} from '../DateColumnFilter/DateColumnFilter';
import {stages, types, priorities} from '../Order/Constants/constants';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faCalendarExclamation, faCalendar} from '@fortawesome/pro-regular-svg-icons';
import OosAssignOrdersDialog from './OosAssignOrdersDialog';
import {Subscription} from 'rxjs';
import {messages} from '../../config/messages';
import FactoryOrdersService from '../../services/FactoryOrdersService';

library.add(faCalendarExclamation, faCalendar);

interface Props {
  oos: Oos;
  toast: React.RefObject<Toast>;
}

interface State {
  oos: Oos;
  loading: boolean;
  items: Order[];
  totalItems: number;
  selectedItems: Order[];
  activeFilters: {};
  filters: {
    order_code: string;
    distributor: string;
    reference: string;
    priority: string;
    type: string;
    stage: string;
    product_line: string;
    ecd: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  showAssignOrderDialog: boolean;
  users: User[];
}
class OosOrders extends React.Component<Props, State> {
  static contextType = AppContext;
  oosService: OosService | null = null;
  ordersService: OrdersService | null = null;
  factoryOrdersService: FactoryOrdersService | null = null;
  usersService: UsersService | null = null;
  toastService: ToastService | null = null;

  subscription: Subscription = new Subscription();
  typingTimer: NodeJS.Timeout | undefined = undefined;
  toast: React.RefObject<Toast>;

  constructor(props: Props) {
    super(props);
    this.state = {
      oos: {
        factory_id: '',
        inventory_item_id: '',
        stage: 'Available',
        updated_at: new Date(),
      },
      activeFilters: {},
      items: [],
      totalItems: 0,
      loading: false,
      selectedItems: [],
      filters: {
        order_code: '',
        distributor: '',
        reference: '',
        priority: '',
        type: '',
        stage: '',
        product_line: '',
        ecd: {
          fromDate: null,
          toDate: null,
        },
      },
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
      showAssignOrderDialog: false,
      users: [],
    };

    this.codeBodyTemplate = this.codeBodyTemplate.bind(this);
    this.ecdBodyTemplate = this.ecdBodyTemplate.bind(this);
    this.prioBodyTemplate = this.prioBodyTemplate.bind(this);
    this.stageBodyTemplate = this.stageBodyTemplate.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.setChangeSelectedItems = this.setChangeSelectedItems.bind(this);
    this.onSort = this.onSort.bind(this);
    this.unAssignOrder = this.unAssignOrder.bind(this);
    this.initMenuItems = this.initMenuItems.bind(this);
    this.initNewMenuItems = this.initNewMenuItems.bind(this);
    this.toast = React.createRef();
  }

  componentDidMount() {
    this.oosService = this.context.oosService;
    this.ordersService = this.context.ordersService;
    this.factoryOrdersService = this.context.factoryOrdersService;
    this.usersService = this.context.usersService;
    this.toastService = this.context.toastService;
    const oos = this.props.oos;

    this.subscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.oosOrdersReassigned) {
        this.reloadData();
      } else {
        const castedMessage = message as TwoMessage;
        if (castedMessage.name && castedMessage.name === 'top-selection-changed') {
          this.loadData(oos);
        }
      }
    });

    this.setOos(oos);
    this.loadData(oos);
    this.loadUsers();
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();

    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  setOos(oos: Oos) {
    this.setState({oos: oos});
  }

  reloadData() {
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: this.props.oos.id,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };
    this.oosService
      ?.getOos(params)
      .then(data => {
        const oos = (data.records as Oos[])[0];
        this.setOos(oos);
        this.loadData(oos);
      })
      .catch(error => {
        console.error(error);
      });
  }

  loadData(oos: Oos) {
    this.setState({loading: true});
    const filters: string[] = [];

    const ordersIds: string[] = oos.assigned_orders_ids?.filter(o => o !== null) ?? [];

    filters.push(
      JSON.stringify({
        field: 'id',
        value: ordersIds,
        condition: 'in',
      })
    );

    if (this.state.filters.order_code) {
      filters.push(
        JSON.stringify({
          field: 'id',
          value: this.state.filters.order_code,
          condition: 'like',
        })
      );
    }

    if (this.state.filters.distributor) {
      filters.push(
        JSON.stringify({
          field: 'distributor',
          value: this.state.filters.distributor,
          condition: 'like',
        })
      );
    }

    if (this.state.filters.reference) {
      filters.push(
        JSON.stringify({
          field: 'reference',
          value: this.state.filters.reference,
          condition: 'like',
        })
      );
    }

    if (this.state.filters.priority) {
      filters.push(
        JSON.stringify({
          field: 'priority',
          value: this.state.filters.priority,
          condition: 'in',
        })
      );
    }

    if (this.state.filters.ecd.fromDate) {
      const fromDate = this.state.filters.ecd.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'factory_order.ecd',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.ecd.toDate) {
      const toDate = this.state.filters.ecd.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'factory_order.ecd',
          value: toDate,
          condition: '<=',
        })
      );
    }

    this.setState({activeFilters: {...filters}});

    //some columns have different name in database and map for server
    const sortBy = {
      field: this.state.sortBy?.field,
      direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
    };
    switch (sortBy.field) {
      case 'order_code':
        sortBy.field = 'id';
        break;
      case 'owner_company.account_number':
        sortBy.field = 'company.account_number';
        break;
    }

    const sortByStringyfied = JSON.stringify(sortBy);

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: this.state.sortBy ? [sortByStringyfied] : [],
      aggregate: true,
    };

    this.ordersService
      ?.getOrders(params)
      .then(data => {
        const dataRecords = (data.records as Order[]) ?? [];

        this.handleSelectedItems(dataRecords);
        this.setState({
          items: dataRecords,
          totalItems: data.total_records ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
      });
  }

  loadUsers() {
    const params: QueryParameter = {
      filters: [],
      aggregate: false,
    };

    this.usersService
      ?.getUsers(params)
      .then(data => {
        const users: User[] = (data?.records as User[]) ?? [];

        this.setState({
          users: users,
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData(this.state.oos);
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData(this.state.oos);
  }

  handleSelectedItems(allItems: Order[]) {
    const selectedItems = [...this.state.selectedItems];
    const items: Order[] = allItems.filter(item => {
      return selectedItems.find(selectedItem => {
        return selectedItem.id === item.id;
      });
    });

    this.setChangeSelectedItems(items);
  }

  setChangeSelectedItems(items: Order[]) {
    this.setState({selectedItems: items});
  }

  async setChangeSelectedItem(item: Order) {
    const items = [...this.state.selectedItems];
    const existingItem = items.find(i => i.id === item.id);
    if (!existingItem) {
      items.push(item);
      await this.setState({selectedItems: items});
    }
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement> | MultiSelectChangeParams | DateColumnFilterChangeEvent) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData(this.state.oos);
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, values.stopTypingDetection);
  };

  initMenuItems(): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];

    const selectedItems = this.state.selectedItems;
    const selectedItemsCount = selectedItems.length;

    menuItems.push(...this.initNewMenuItems());

    if (selectedItemsCount > 0) {
      menuItems.push({
        label: 'Un-Assign Order',
        faIcon: faCalendarExclamation,
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => this.unAssignOrder(),
      });
    }
    return menuItems;
  }

  initNewMenuItems(): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];

    menuItems.push({
      label: 'Assign Order',
      faIcon: faCalendar,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.setState({showAssignOrderDialog: true});
      },
    });
    return menuItems;
  }

  async unAssignOrder() {
    const oos = this.state.oos;
    const allOrdersIds = oos.assigned_orders_ids?.filter(o => o !== null) ?? [];
    const removeOrders: Order[] = this.state.selectedItems;
    const removeOrdersIds: string[] = removeOrders.map((order: Order) => order.id ?? '');

    const ordersIds = allOrdersIds.filter(o => !removeOrdersIds.includes(o));
    const oosPatch: OosPatch = {
      stage: oos.stage,
      assigned_orders_ids: ordersIds,
    };

    return this.oosService
      ?.updateOos(oos.id?.toString() ?? '', oosPatch)
      .then(() => {
        this.moveOrdersAfterAvailableStage(removeOrders);
        this.props.toast.current?.show({
          contentClassName: '',
          severity: 'success',
          summary: 'Success',
          detail: 'Oos unassigned successfully.',
          life: 3000,
        });
        MessageService.sendMessage(messages.oosOrdersReassigned);
        this.setState({selectedItems: []});
      })
      .catch(error => {
        this.props.toast.current?.show({
          contentClassName: '',
          severity: 'error',
          summary: 'Error',
          detail: 'Sorry, oos unassign failed, please try again.',
          life: 3000,
        });
        console.error(error);
      });
  }

  moveOrdersAfterAvailableStage(orders: Order[]) {
    const updateOrders: Order[] = [];
    orders.map(order => {
      const pos = order.purchase_orders?.filter(po => po !== null && po.stage !== 'Delivered') ?? [];
      const oos = order.out_of_stocks?.filter(oos => oos !== null && oos.stage !== 'Available') ?? [];

      if (pos.length === 0 && oos.length === 0) {
        updateOrders.push(order);
      }
    });

    const newStage = 'Ready';
    const factoryOrderPatch: FactoryOrderPatch = {
      production_stage: newStage,
    };

    const promises = updateOrders.map(order => {
      return this.factoryOrdersService?.updateFactoryOrder(order.id ?? '', factoryOrderPatch);
    });

    Promise.all(promises)
      .then(() => {
        MessageService.sendMessage(messages.oosOrdersReassigned);
        this.props.toast.current?.show({
          contentClassName: '',
          severity: 'success',
          summary: 'Success',
          detail: `Order Stage to ${newStage} updated successfully.`,
          life: 3000,
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  codeBodyTemplate(rowData: Order) {
    return (
      <AppColumnMenuBodyTemplate
        rowItemIdentifier={rowData.id?.toString() ?? ''}
        isDynamicMenuItems={true}
        initMenuItems={() => this.initMenuItems()}
        selectedItems={this.state.selectedItems}
        handleChangeSelectedItems={() => this.setChangeSelectedItem(rowData)}
      >
        {rowData.id}
      </AppColumnMenuBodyTemplate>
    );
  }

  prioBodyTemplate(rowData: Order) {
    let priorityText = '';
    switch (Number(rowData.priority)) {
      case 1:
        priorityText = '!';
        break;
      case 2:
        priorityText = '!!';
        break;
      case 3:
        priorityText = '!!!';
        break;
    }
    return <span>{priorityText}</span>;
  }

  lastTleBodyTemplate(rowData: Order) {
    const lastTle = rowData.last_tle;
    if (lastTle) {
      const user = this.state.users.find(u => u.id === lastTle.recorded_by);
      return <TleReferenceComponent identifier={rowData.id ?? ''} value={lastTle} user={user} />;
    } else {
      return <></>;
    }
  }

  stageBodyTemplate(rowData: Order) {
    return (
      <span
        className={`stage-badge stage-${(rowData.factory_order?.production_stage ?? '')
          .toLowerCase()
          .replaceAll(' ', '-')}`}
      >
        {rowData.factory_order?.production_stage}
      </span>
    );
  }

  ecdBodyTemplate(rowData: Order) {
    const formated_ecd = rowData.factory_order?.ecd
      ? DateTime.fromISO(rowData.factory_order?.ecd.toString()).toFormat(formats.date)
      : '';
    return <span>{formated_ecd}</span>;
  }

  render() {
    const codeFilter = (
      <InputText
        name="order_code"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    const referenceFilter = (
      <InputText
        name="reference"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const selectedItemTemplate = (value: string) => {
      if (value) {
        return (
          <span className={`p-mr-1 stage-badge stage-badge-filter stage-${value.toLowerCase().replaceAll(' ', '-')}`}>
            {value}
          </span>
        );
      }

      return <></>;
    };

    const itemTemplate = (option: DropdownOption) => {
      return (
        <span className={`stage-badge stage-${option.value.toString().toLowerCase().replaceAll(' ', '-')}`}>
          {option.value}
        </span>
      );
    };

    const stageFilter = (
      <MultiSelect
        selectedItemTemplate={selectedItemTemplate}
        itemTemplate={itemTemplate}
        value={this.state.filters.stage}
        options={stages}
        name="stage"
        className="form-filter stage-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );

    const typeFilter = (
      <MultiSelect
        value={this.state.filters.type}
        options={types}
        name="type"
        className="form-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );

    const productLineFilter = (
      <MultiSelect
        value={this.state.filters.product_line}
        options={productLines}
        name="product_line"
        className="form-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );

    const priorityFilter = (
      <MultiSelect
        value={this.state.filters.priority}
        options={priorities}
        name="priority"
        className="form-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );

    const ecdFilter = (
      <DateColumnFilter name="ecd" value={this.state.filters.ecd} onChange={e => this.onFilterChange(e)} />
    );

    const sizeFilter = (
      <InputText
        name="size"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const summaryFilter = (
      <InputText
        name="summary"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const customerFilter = (
      <InputText
        name="customer_name"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    return (
      <div className="p-d-flex p-p-2" style={{height: '100%'}}>
        <div id="oos_orders_page_container" className="page-container" style={{width: '100%'}}>
          <TwoDataTable
            style={{height: '100%'}}
            pageSizeIdentifier={'oos_orders_page_container'}
            heightToScroll={undefined}
            selectionMode="multiple"
            selectedItems={this.state.selectedItems}
            rows={this.state.pagination.pageSize}
            first={this.state.pagination.offset}
            sortField={this.state.sortBy?.field}
            sortOrder={this.state.sortBy?.order}
            onPage={e => this.onPageChange(e as DataTablePageParams)}
            onSort={e => this.onSort(e)}
            handleChangeSelectedItems={items => this.setChangeSelectedItems(items as Order[])}
            loading={this.state.loading}
            value={this.state.items}
            totalRecords={this.state.totalItems}
            activeFilters={this.state.activeFilters}
            initMenuItems={this.initMenuItems}
          >
            <Column
              header="Order Code"
              field="order_code"
              body={this.codeBodyTemplate}
              filter
              filterElement={codeFilter}
              sortable
              style={{width: '180px'}}
              showFilterMenu={false}
            />
            <Column
              header="Reference"
              field="reference"
              filter
              filterElement={referenceFilter}
              sortable
              style={{width: '180px'}}
              showFilterMenu={false}
            />
            <Column
              header="Customer"
              field="owner_company.account_number"
              filter
              filterElement={customerFilter}
              sortable
              style={{width: '180px'}}
              showFilterMenu={false}
            />
            <Column
              header="Prod Line"
              field="factory_order.product_line"
              filter
              filterElement={productLineFilter}
              sortable
              style={{width: '180px'}}
              showFilterMenu={false}
            />
            <Column
              header="Type"
              field="type"
              filter
              filterElement={typeFilter}
              sortable
              style={{width: '180px'}}
              showFilterMenu={false}
            />
            <Column
              header="Stage"
              field="factory_order.production_stage"
              body={this.stageBodyTemplate}
              filter
              filterElement={stageFilter}
              sortable
              style={{width: '180px'}}
              showFilterMenu={false}
            />
            {/*progress*/}
            <Column
              header="ECD"
              field="factory_order.ecd"
              body={this.ecdBodyTemplate}
              filter
              filterElement={ecdFilter}
              sortable
              style={{width: '180px'}}
              showFilterMenu={false}
            />
            <Column
              header="Prio"
              field="priority"
              body={this.prioBodyTemplate}
              filter
              filterElement={priorityFilter}
              sortable
              style={{width: '180px'}}
              showFilterMenu={false}
            />
            <Column
              header="Size"
              field="factory_order.size"
              filter
              filterElement={sizeFilter}
              sortable
              style={{width: '180px'}}
              showFilterMenu={false}
            />
            <Column
              header="Summary"
              field="summary"
              filter
              filterElement={summaryFilter}
              sortable
              style={{width: '180px'}}
              showFilterMenu={false}
            />
          </TwoDataTable>
          <OosAssignOrdersDialog
            oos={this.state.oos}
            toast={this.props.toast}
            showDialog={this.state.showAssignOrderDialog}
            onHide={() => this.setState({showAssignOrderDialog: false})}
          />
        </div>
      </div>
    );
  }
}

export default OosOrders;
