import React from 'react';
import {Accordion, AccordionTab} from 'primereact/accordion';
import {Toast} from 'primereact/toast';
import HeaderTemplate from './HeaderTemplate';
import {
  Company,
  Factory,
  FactoryOrder,
  MapOf,
  Order,
  OrderGroup,
  OrderType,
  PurchaseForOrder,
  PurchaseOrder,
  QueryParameter,
  TimeLineEvent,
} from 'two-core';
import OrdersService from '../../services/OrdersService';
import {AppContext, MessageService, TwoMessage} from 'two-app-ui';
import {Subscription} from 'rxjs';
import {messages} from '../../config/messages';
import ScheduleTrendsComponent from './ScheduleTrendsComponent';
import ScheduleGroupOrderListComponent from './ScheduleOrders/ScheduleGroupOrderListComponent';
import {DateTime} from 'luxon';
import FactoriesService from '../../services/FactoriesService';
import * as XLSX from 'xlsx';
import formats from '../../config/formats';
import {StageMenuOptions} from '../Order/Constants/constants';

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

interface State {
  loading: boolean;
  loadingActiveComponents: boolean;
  loadingActiveStandars: boolean;
  loadingActiveRepairs: boolean;
  activeOrders: MapOf<number>;
  factory: Factory | undefined;
  filter: {
    productLine: string;
    filterByDate: string;
    fromDate: string;
    toDate: string;
    showDoneOrders: boolean;
  };
}

class ScheduleContentComponent extends React.Component<{}, State> {
  static contextType = AppContext;

  ordersService: OrdersService | null = null;
  factoriesService: FactoriesService | null = null;

  subscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;

  constructor(props = {}) {
    super(props);
    this.state = {
      loading: false,
      loadingActiveComponents: false,
      loadingActiveRepairs: false,
      loadingActiveStandars: false,
      activeOrders: {},
      factory: undefined,
      filter: {
        productLine: '',
        filterByDate: '',
        fromDate: '',
        toDate: '',
        showDoneOrders: false,
      },
    };

    this.toast = React.createRef();

    this.trendsHeaderTemplate = this.trendsHeaderTemplate.bind(this);
    this.setActiveOrders = this.setActiveOrders.bind(this);
    this.exportFile = this.exportFile.bind(this);
  }

  async componentDidMount() {
    this.ordersService = this.context.ordersService;
    this.factoriesService = this.context.factoriesService;

    this.subscription = MessageService.getMessage().subscribe(async message => {
      if (message === messages.scheduleFilterChanged) {
        this.loadData();
      } else {
        const castedMessage = message as TwoMessage;
        if (castedMessage.name && castedMessage.name === 'top-selection-changed') {
          await localStorage.setItem('current factory', castedMessage.value as string);
          this.loadData();
        } else if (message === messages.schedulerExportFile) {
          this.exportFile();
        }
      }
    });

    this.loadData();
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  async loadData() {
    await this.setFilterData();
    this.loadFactory();
    //this.loadActiveTotal('Repair');
    //this.loadActiveTotal('Standard');
    //this.loadActiveTotal('Component');
  }

  async loadFactory() {
    this.setState({loading: true});

    const currentFactoryId = localStorage.getItem('current factory') ?? '';

    return this.factoriesService
      ?.getFactory(currentFactoryId)
      .then(data => {
        const factory = data;
        this.setState({loading: false, factory: factory});
      })
      .catch(() => {
        this.setState({loading: false});
      });
  }

  async setFilterData() {
    const productLineValue = localStorage.getItem('schedule product line') ?? 'Colourvue';
    const filterByDateValue = localStorage.getItem('filter by date') ?? 'ecd';
    const fromDateValue = localStorage.getItem('from date') ?? '';
    const toDateValue = localStorage.getItem('to date') ?? '';
    const stringValue = localStorage.getItem('show done') ?? '';
    const showDoneOrders = stringValue ? JSON.parse(stringValue) : false;

    const filter = {
      productLine: productLineValue,
      filterByDate: filterByDateValue,
      fromDate: fromDateValue,
      toDate: toDateValue,
      showDoneOrders: showDoneOrders,
    };
    await this.setState({filter: filter});
  }

  async loadActiveTotal(type: OrderType): Promise<number | undefined> {
    this.setLoadingActiveTotal(type, true);
    const filters: string[] = [];

    const filter = this.state.filter;
    const productLine = filter.productLine;

    const value = filter.filterByDate;
    const dateName = value === 'ecd' ? 'factory_order.ecd' : 'factory_order.scheduled_for';

    const fromDateValue = filter.fromDate;
    const fromDate = fromDateValue && fromDateValue !== '' ? new Date(fromDateValue) : null;
    const toDateValue = filter.toDate;
    const toDate = toDateValue && toDateValue !== '' ? new Date(toDateValue) : null;

    if (fromDate) {
      const from = DateTime.fromJSDate(fromDate).toISODate();
      filters.push(
        JSON.stringify({
          field: dateName,

          value: from,
          condition: '>=',
        })
      );
    }
    if (toDate) {
      const to = DateTime.fromJSDate(toDate).toISODate();
      filters.push(
        JSON.stringify({
          field: dateName,
          value: to,
          condition: '<=',
        })
      );
    }

    filters.push(
      JSON.stringify({
        field: 'factory_order.product_line',
        value: productLine,
      })
    );

    const stageExclusions = ['Cancelled'];

    if (!filter.showDoneOrders) {
      stageExclusions.push('Done');
      stageExclusions.push('Post Fix Return');
    }

    filters.push(
      JSON.stringify({
        field: 'factory_order.production_stage',
        value: stageExclusions,
        condition: 'notIn',
      })
    );

    filters.push(
      JSON.stringify({
        field: 'type',
        value: type,
      })
    );

    const params: QueryParameter = {
      offset: 0,
      filters: filters,
      aggregate: true,
    };

    return this.ordersService
      ?.getOrders(params)
      .then((res: unknown) => {
        const data = res as {
          records: Order[];
          total_records: number;
          total_size: number;
        };
        let totalSum = 0;
        if (data.records) {
          data.records.forEach(order => {
            if (order.factory_order && order.factory_order.size) {
              totalSum += order.factory_order!.size;
            }
          });
        }
        this.setActiveOrders(type, totalSum);
        this.setLoadingActiveTotal(type, false);

        return Promise.resolve(totalSum);
      })
      .catch(e => {
        console.log(e);
        this.setLoadingActiveTotal(type, false);
        return Promise.resolve(0);
      });
  }

  setLoadingActiveTotal(orderType: OrderType, value: boolean) {
    switch (orderType) {
      case 'Component':
        this.setState({loadingActiveComponents: value});
        break;
      case 'Repair':
        this.setState({loadingActiveRepairs: value});
        break;
      case 'Standard':
        this.setState({loadingActiveStandars: value});
        break;
      default:
        this.setState({loading: value});
        break;
    }
  }

  setActiveOrders(type: OrderType, activeCount: number) {
    const activeOrders = this.state.activeOrders;
    const updatedActiveOrders = {...activeOrders};
    updatedActiveOrders[type] = activeCount;
    this.setState({activeOrders: updatedActiveOrders});
  }

  trendsHeaderTemplate(title: string) {
    return (
      <div className="p-d-flex p-ai-center2 header-template">
        <span className="p-d-flex p-col-2 p-p-0 p-ai-center">{title}</span>
      </div>
    );
  }

  async exportFile() {
    const filter = this.state.filter;
    const factory = this.state.factory;
    const activeOrders = this.state.activeOrders;

    const productLine = filter.productLine;
    const filterByDate = filter.filterByDate;
    const fromDateValue = filter.fromDate;
    const fromDate = fromDateValue && fromDateValue !== '' ? new Date(fromDateValue) : null;
    const toDateValue = filter.toDate;
    const toDate = toDateValue && toDateValue !== '' ? new Date(toDateValue) : null;

    const fromDateFormatted = fromDate ? DateTime.fromJSDate(fromDate).toFormat(formats.date) : '';
    const toDateFormatted = toDate ? DateTime.fromJSDate(toDate).toFormat(formats.date) : '';

    const dateName = filterByDate === 'ecd' ? 'ECD' : 'Release';

    const headerTitles = [
      'Order Code',
      'Reference',
      dateName,
      'Prio',
      'Size',
      'Customer',
      'Summary',
      'Last Action',
      'POs',
    ];

    const filterHeaderData = [
      'Product Line',
      filter.productLine,
      'Filter By',
      filter.filterByDate,
      'From',
      fromDateFormatted,
      'To',
      toDateFormatted,
    ];

    const repairValues = await this.loadDataByType('Repair', filterByDate, fromDate, toDate, productLine);
    const componentValues = await this.loadDataByType('Component', filterByDate, fromDate, toDate, productLine);
    const standardValues = await this.loadDataByType('Standard', filterByDate, fromDate, toDate, productLine);

    const repairsData = [
      filterHeaderData,
      [],
      ['Repairs', 'Active', activeOrders['Repair'] ?? 0, '', 'Late', ''],
      [],
      headerTitles,
      [],
      ...repairValues,
    ];

    const componentsData = [
      filterHeaderData,
      [],
      ['Components', 'Active', activeOrders['Component'] ?? 0, '', 'Late', ''],
      [],
      headerTitles,
      [],
      ...componentValues,
    ];

    const standardData = [
      filterHeaderData,
      [],
      ['Standard', 'Active', activeOrders['Standard'] ?? 0, '', 'Late', ''],
      [],
      headerTitles,
      [],
      ...standardValues,
    ];

    const factorySettings = factory?.settings;

    const leadTimeData = [
      ['Lead Times', 'Colourvue', 'Shadesol'],
      [
        'standard',
        factorySettings?.product_lines?.colourvue?.lead_time.standard ?? 0,
        factorySettings?.product_lines?.shadesol?.lead_time.standard ?? 0,
      ],
      [
        'standard + deco cover',
        factorySettings?.product_lines?.colourvue?.lead_time.extra ?? 0,
        factorySettings?.product_lines?.shadesol?.lead_time.extra ?? 0,
      ],
      [
        'repair',
        factorySettings?.product_lines?.colourvue?.lead_time.repair ?? 0,
        factorySettings?.product_lines?.shadesol?.lead_time.repair ?? 0,
      ],
      [
        'component',
        factorySettings?.product_lines?.colourvue?.lead_time.component ?? 0,
        factorySettings?.product_lines?.shadesol?.lead_time.component ?? 0,
      ],
      [
        'daily capacity',
        factorySettings?.product_lines?.colourvue?.daily_capacity ?? 0,
        factorySettings?.product_lines?.shadesol?.daily_capacity ?? 0,
      ],
    ];

    const workBook = XLSX.utils.book_new();

    const wsRepairs = XLSX.utils.aoa_to_sheet(repairsData);
    XLSX.utils.book_append_sheet(workBook, wsRepairs, 'Repairs');

    const wsComponents = XLSX.utils.aoa_to_sheet(componentsData);
    XLSX.utils.book_append_sheet(workBook, wsComponents, 'Components');

    const wsStandard = XLSX.utils.aoa_to_sheet(standardData);
    XLSX.utils.book_append_sheet(workBook, wsStandard, 'Standard');

    const wsLeadTimes = XLSX.utils.aoa_to_sheet(leadTimeData);
    XLSX.utils.book_append_sheet(workBook, wsLeadTimes, 'Lead Times');

    const currentFactory = factory?.key;
    const date = DateTime.fromJSDate(new Date()).toFormat(formats.dateTime);
    const fileName = `m2c_sched-${currentFactory}-${date}.xlsx`;
    /* generate XLSX file and send to client */
    XLSX.writeFile(workBook, fileName);
  }

  async loadDataByType(
    type: OrderType,
    filterByDate: string,
    fromDate: Date | null,
    toDate: Date | null,
    productLine: string
  ) {
    const sumPreIndex = 5;
    let startIndex = sumPreIndex;

    const exportOrderData: string | number | {}[][] = [];
    const filters: string[] = [];

    const dateName = filterByDate === 'ecd' ? 'factory_order.ecd' : 'factory_order.scheduled_for';

    if (fromDate) {
      const from = DateTime.fromJSDate(fromDate).toISODate();
      filters.push(
        JSON.stringify({
          field: dateName,
          value: from,
          condition: '>=',
        })
      );
    }
    if (toDate) {
      const to = DateTime.fromJSDate(toDate).toISODate();
      filters.push(
        JSON.stringify({
          field: dateName,
          value: to,
          condition: '<=',
        })
      );
    }

    filters.push(
      JSON.stringify({
        field: 'factory_order.production_stage',
        value: ['Done', 'Post Fix Return', 'Cancelled'],
        condition: 'notIn',
      })
    );

    filters.push(
      JSON.stringify({
        field: 'factory_order.product_line',
        value: productLine,
      })
    );

    filters.push(
      JSON.stringify({
        field: 'type',
        value: type,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      groupBy: dateName,
    };

    await this.ordersService
      ?.getGroupped(params)
      .then(data => {
        const groupppedOrders = data.records as OrderGroup[];
        groupppedOrders
          .filter(g => g.group_key !== null)
          .map(group => {
            const dateString = group.group_key;
            const date = new Date(dateString);
            const formattedDate = DateTime.fromJSDate(date).toFormat(formats.date);
            const dayName = days[date.getDay()];
            const dayTitle = `${formattedDate} (${dayName})`;
            const orders = group.records;

            startIndex = startIndex + 3;
            const orderCount = orders.length;

            const endIndex = startIndex + orderCount - 1;

            const data = [dayTitle, '', 'SUM:', {t: 'n', f: `SUM(E${startIndex}:E${endIndex})`}];

            startIndex = startIndex + orderCount - 1;
            exportOrderData.push(data);

            orders.map(order => {
              const orderData = [
                order.id ?? '',
                order.reference,
                formattedDate,
                this.prioText(order),
                order.size,
                this.customerLabel(order, group.owner_companies),
                this.summaryLabel(order, group.factory_orders),
                this.lastEventLabel(order, group.tles),
                this.posLabel(order, group.purchase_for_orders, group.purchase_orders),
              ];
              exportOrderData.push(orderData);
            });
            exportOrderData.push([]);
          });

        return exportOrderData;
      })
      .catch(error => {
        console.log(error);
      });
    return exportOrderData;
  }

  lastEventLabel(order: Order, events: TimeLineEvent[]) {
    const event = events.find(e => e !== null && e.id === order.last_event_id);
    let label = '';

    if (!event) {
      return label;
    }
    const date = event.recorded_at ? DateTime.fromISO(event.recorded_at.toString()).toFormat(formats.shortDate) : '';
    const eventType = event.event_type;
    switch (eventType) {
      case 'create':
        label = 'create';
        break;
      case 'delete':
        label = 'delete';
        break;
      case 'update':
        label = 'update';
        break;
      case 'stage_transition':
        label = 'stage transition';
        break;
      case 'freight_stage_transition':
        label = 'freight stage transition';
        break;
      case 'production_stage_transition':
        label = 'production stage transition';
        break;
      case 'Production Stage Transition':
        label = 'production stage transition';
        break;
      case 'Manual Update':
        label = 'update';
        break;
      case 'Hook Import':
        label = 'hook import';
        break;
      case 'hook update':
        label = 'hook update';
        break;
      case 'Note':
        label = 'note';
        break;
      case 'email':
        label = 'email';
        break;
      case 'assigned':
        label = 'assigned';
        break;
      case 'unassigned':
        label = 'unassigned';
        break;
      case 'note':
        label = 'note';
        break;
      case 'sms':
        label = 'sms';
        break;
      case 'call':
        label = 'call';
        break;
      case 'delivered':
        label = 'delivered';
        break;
      case 'webhook_in':
        label = 'webhook in';
        break;
      case 'webhook_out':
        label = 'webhook out';
        break;
      case 'api_in':
        label = 'api in';
        break;
      case 'api_out':
        label = 'api out';
        break;
      case 'eta_change':
        label = 'eta change';
        break;
      case 'back_in_stock':
        label = 'back in stock';
        break;
      case 'shipping_udpate':
        label = 'shipping update';
        break;
      case 'Shipping Update':
        label = 'shipping update';
        break;
      case 'unknown':
        label = 'unknown';
        break;
      default:
        label = '';
        break;
    }
    return label !== '' ? `${label} ${date}` : 'ERR';
  }

  customerLabel(order: Order, customers: Company[]) {
    const customer = customers.find(c => c !== null && c.id === order.owner);
    return customer?.account_number ?? '';
  }

  summaryLabel(order: Order, factoryOrders: FactoryOrder[]) {
    const factoryOrder = factoryOrders.find(fo => fo !== null && fo.id === order.id);
    return factoryOrder?.summary ?? '';
  }

  posLabel(order: Order, purchaseForOrders: PurchaseForOrder[], purchaseOrders: PurchaseOrder[]) {
    const orderPOs = (purchaseForOrders.filter(po => (po !== null && po.order_id === order.id) ?? '') ?? []).map(
      p => p.order_id ?? ''
    );
    const pos = purchaseOrders.filter(po => po !== null && orderPOs?.includes(order.id ?? '')) ?? [];
    const posTitles = pos.map(po => {
      const eta = po.eta;
      const etaDate = eta ? DateTime.fromISO(eta.toString()) : undefined;

      const etaValue = etaDate ? etaDate.toFormat(formats.shortDate) : 'No ETA';
      return `${po.id ?? ''} (${etaValue})`;
    });
    return posTitles.join(', ');
  }

  prioText(rowData: Order) {
    let priorityText = '';
    switch (Number(rowData.priority)) {
      case 1:
        priorityText = '!';
        break;
      case 2:
        priorityText = '!!';
        break;
      case 3:
        priorityText = '!!!';
        break;
    }
    return priorityText;
  }

  render() {
    const {activeOrders, loadingActiveComponents, loadingActiveRepairs, loadingActiveStandars} = this.state;

    return (
      <div id="schedule_content">
        <Accordion multiple activeIndex={[]}>
          <AccordionTab
            header={
              <HeaderTemplate
                title="Repairs"
                active={activeOrders['Repair'] ?? 0}
                late={undefined}
                loading={loadingActiveRepairs}
              />
            }
            contentClassName="components-acc-content"
          >
            <ScheduleGroupOrderListComponent type={'Repair'} stageMenuOptions={Object.values(StageMenuOptions)} />
          </AccordionTab>
          <AccordionTab
            header={
              <HeaderTemplate
                title="Components"
                active={activeOrders['Component'] ?? 0}
                late={undefined}
                loading={loadingActiveComponents}
              />
            }
            contentClassName="components-acc-content"
          >
            <ScheduleGroupOrderListComponent type={'Component'} />
          </AccordionTab>
          <AccordionTab
            header={
              <HeaderTemplate
                title="Standard"
                active={activeOrders['Standard'] ?? 0}
                late={undefined}
                loading={loadingActiveStandars}
              />
            }
            contentClassName="standards-acc-content"
          >
            <ScheduleGroupOrderListComponent
              type={'Standard'}
              stageMenuOptions={Object.values(StageMenuOptions).filter(v => v !== StageMenuOptions.PostFixReturn)}
            />
          </AccordionTab>
          <AccordionTab header={this.trendsHeaderTemplate('Trends')} contentClassName="trends-acc-content">
            <ScheduleTrendsComponent />
          </AccordionTab>
        </Accordion>
        <Toast ref={this.toast} />
      </div>
    );
  }
}

export default ScheduleContentComponent;
