import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, Order, OrderPatch, QueryParameter} from 'two-core';

class OrdersService extends ApiService {
  endpoint: string;
  inventoryMoveEndpoint: string;
  groupOrders: string;
  integrationOrders: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.orders ?? '';
    this.inventoryMoveEndpoint = config().endpoints.inventoryMove;
    this.groupOrders = config().endpoints.groupOrders;
    this.integrationOrders = config().endpoints.integrationOrders;
  }

  async getOrders(params: QueryParameter): Promise<ApiListResponse> {
    const currentFactoryId = localStorage.getItem('current factory');
    params.filters?.push(
      JSON.stringify({
        orConditions: [
          {
            field: 'factory_order.factory_id',
            value: currentFactoryId,
          },
          {
            field: `factory_order.factory_chain->>'${currentFactoryId}'`,
            condition: 'isNotNull',
          },
        ],
      })
    );

    return this.get(this.endpoint, params)
      .then((data: ApiListResponse | ApiResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  async getOrder(id: string): Promise<Order> {
    return this.get(this.endpoint + '/' + id)
      .then(data => {
        return Promise.resolve(new Order(data));
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  async getGroupped(params: QueryParameter): Promise<ApiListResponse> {
    params.filters?.push(
      JSON.stringify({
        orConditions: [
          {
            field: 'factory_order.factory_id',
            value: localStorage.getItem('current factory'),
          },
          {
            field: "factory_order.factory_chain -> 'TWO-QLD' ->> 'stage'",
            value: 'future',
          },
        ],
      })
    );

    return this.get(this.groupOrders, params)
      .then(data => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  async getOrderBoms(id: string): Promise<ApiListResponse> {
    return this.get(this.endpoint + '/' + id + '/bom')
      .then(data => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  async updateOrder(id: string, data: OrderPatch): Promise<Order> {
    return this.patch(this.endpoint + '/' + id, data)
      .then(data => {
        return Promise.resolve(data as Order);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  async reassignOos(id: string, oosRecordsIds: number[]): Promise<void> {
    return this.post(this.endpoint + '/' + id + '/oos', oosRecordsIds)
      .then(() => {
        return Promise.resolve();
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  async reImportOrder(id: string) {
    return this.get(this.integrationOrders + '/' + id + '/reimport')
      .then(res => {
        const stringResponse = res as unknown as string;
        if (stringResponse === 'Call received."ReImport failed"') {
          return Promise.reject();
        }
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default OrdersService;
