import React from 'react';
import {Fieldset} from 'primereact/fieldset';
import {Button} from 'primereact/button';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {Calendar, CalendarChangeParams} from 'primereact/calendar';
import './ScheduleComponent.scss';
import {productLines} from '../../config/factoryConstants';
import formats from '../../config/formats';
import {MessageService} from 'two-app-ui';
import {messages} from '../../config/messages';
import {InputSwitch, InputSwitchChangeParams} from 'primereact/inputswitch';

interface Props {
  selectedProductLine: string;
  selectedDate: string;
  from: Date;
  to: Date;
  showDoneOrders: boolean;
  handleChange: (e: DropdownChangeParams | CalendarChangeParams | InputSwitchChangeParams, name: string) => void;
}

const ScheduleTopComponent = ({selectedProductLine, selectedDate, from, to, showDoneOrders, handleChange}: Props) => {
  return (
    <Fieldset id="schedule_box" className="line-filter p-mb-3 p-p-1 w-100">
      <div className="p-d-flex p-flex-row p-flex-wrap">
        <div className="p-d-flex p-flex-wrap p-col-12 p-xs-12 p-sm-12 p-md-2 p-lg-2 p-p-1">
          <label className="p-d-flex p-col-12 p-md-3 p-lg-3 p-as-center p-p-1">Product Line</label>
          <div className="p-d-flex p-col-12 p-md-9 p-lg-9 p-p-0 p-as-center">
            <Dropdown
              name="productLine"
              className="w-100"
              value={selectedProductLine}
              options={productLines}
              onChange={(e: DropdownChangeParams) => handleChange(e, 'productLine')}
              optionLabel="label"
              optionValue="value"
              placeholder="Select option"
            />
          </div>
        </div>

        <div className="p-d-flex p-flex-wrap p-col-12 p-xs-12 p-sm-12 p-md-2 p-lg-2 p-p-1">
          <label className="p-d-flex p-col-12 p-md-2 p-lg-2 p-as-center p-p-1">Filter By</label>
          <div className="p-d-flex p-col-12 p-md-10 p-lg-10 p-p-0 p-as-center">
            <Dropdown
              name="filterByDate"
              className="w-100"
              value={selectedDate}
              options={['ecd', 'release date']}
              onChange={(e: DropdownChangeParams) => handleChange(e, 'filterByDate')}
              placeholder="Select option"
            />
          </div>
        </div>
        <div className="p-d-flex p-flex-wrap p-col-12 p-xs-12 p-sm-12 p-md-2 p-lg-2 p-p-1">
          <label className="p-d-flex p-col-12 p-md-2 p-lg-2 p-as-center p-p-1">From</label>
          <div className="p-d-flex p-col-12 p-md-10 p-lg-10 p-p-0 p-as-center">
            <Calendar
              value={from}
              dateFormat={formats.calendarInputDate}
              name="from"
              className="w-100"
              onChange={e => handleChange(e, 'from')}
            />
          </div>
        </div>
        <div className="p-d-flex p-flex-wrap p-col-12 p-xs-12 p-sm-12 p-md-2 p-lg-2 p-p-1">
          <label className="p-d-flex p-col-12 p-md-2 p-lg-2 p-as-center p-p-1">To</label>
          <div className="p-d-flex p-col-12 p-md-10 p-lg-10 p-p-0 p-as-center">
            <Calendar
              value={to}
              dateFormat={formats.calendarInputDate}
              name="to"
              className="w-100"
              onChange={e => handleChange(e, 'to')}
            />
          </div>
        </div>
        <div className="p-d-flex p-flex-wrap p-col-12 p-xs-12 p-sm-12 p-md-2 p-lg-2 p-p-1">
          <label className="p-d-flex p-col-8 p-as-center p-p-1">Show Completed Orders</label>
          <div className="p-col-4 p-p-0 p-as-center">
            <InputSwitch
              name="showDone"
              className={'p-as-center'}
              checked={showDoneOrders}
              onChange={e => handleChange(e, 'showDoneOrders')}
            />
          </div>
        </div>
        <div className="p-d-flex p-col-12 p-xs-12 p-sm-12 p-md-2 p-lg-2 p-p-1 p-jc-end">
          <Button
            label="Export"
            className={'p-mr-2'}
            onClick={() => {
              MessageService.sendMessage(messages.schedulerExportFile);
            }}
          />
        </div>
      </div>
    </Fieldset>
  );
};

export default ScheduleTopComponent;
